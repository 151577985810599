import React from 'react';
import { withRouter } from 'react-router';

import { IconButton, AppBar, Button, Typography, Toolbar } from '@material-ui/core';

import { ChevronLeft } from '@material-ui/icons';

class UjamaAppBar extends React.Component {
  onBack = () => {
    this.props.history.goBack();
  };

  render() {
    const { title, type, hideGoBack } = this.props;

    return (
      <AppBar position="static">
        <Toolbar>
          <div
            style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}
          >
            {this.renderLeft()}
            {this.renderMiddle()}
            {this.renderRight()}
          </div>
        </Toolbar>
      </AppBar>
    );
  }

  renderLeft() {
    const { type, hideGoBack } = this.props;

    if (!hideGoBack) {
      if (type === 'cancel') {
        return (
          <Button variant="contained" onClick={this.props.back ? this.props.back : this.onBack}>
            Cancel
          </Button>
        );
      } else {
        return (
          <IconButton
            style={{ width: 20 }}
            edge="start"
            onClick={this.props.back ? this.props.back : this.onBack}
          >
            <ChevronLeft />
          </IconButton>
        );
      }
    }
  }

  renderMiddle() {
    const { title, smallTitle } = this.props;

    return (
      <div
        style={{
          flex: 0.9,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <Typography variant={smallTitle || title.length > 24 ? 'h3' : 'h1'}>{title}</Typography>
      </div>
    );
  }

  renderRight() {
    const { rightActionText, rightAction } = this.props;

    if (rightAction) {
      return (
        <Button variant="contained" color="primary" onClick={rightAction}>
          {rightActionText ? rightActionText : 'Add'}
        </Button>
      );
    }
  }
}

const styles = {
  button: {
    display: 'flex',
    width: '88px',
    height: '37px',
  },
};

export default withRouter(UjamaAppBar);
