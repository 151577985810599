import React, { Component } from 'react';
import { toast } from 'react-toastify';
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import Highlighter from 'react-highlight-words';
import moment from 'moment';
import SearchHelper from '../../../api/helpers/search';
import SendBirdUser from '../../../api/sendbird/sendbird-user';
import SearchChatResult from '../chat/search-chat-result';
import NoAddress from '../../common/elements/no-address';
import { maybeTrimString } from '../../../api/helpers/formatterHelper';
import ROUTES from '../../../utils/consts';

const MARKETPLACE_DISTANCE_RADIUS = 100; // 60 mile radius

export default class MarketplaceSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchedMarketPlaces: [],
      channels: [],
      searchedSellers: [],
      filteredSearchResults: [],
      mergedResults: [],
      searchItemTypes: {
        marketPlace: 'marketPlace',
        market: 'market',
        seller: 'seller',
        channel: 'channel',
      },
    };
  }

  componentDidMount() {
    if (this.props.profile.homeCoords) {
      this.readMarketplace().then(() => this.filterMarketResults());
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.searchText !== this.props.searchText) {
      this.setState({ mergedResults: [] }, () => this.filterMarketResults());
    }
  }

  onClickMarketplace(marketplace) {
    const { isChapter, chapterId } = this.props;
    // TODO: Check the user has provided an address
    const { partnerMode, partnerList } = this.props;
    const location = {
      pathname: ROUTES.MARKETPLACE_VIEWER,
      state: {
        marketplace,
        partnerMode,
        partnerList,
        isChapter,
        chapterId,
      },
    };

    this.props.history.push(location);
  }

  onClickMarketLogo(market) {
    window.open(market.website);
  }

  // for marketPlaces and markets
  filterMarketResults = () => {
    const { searchText } = this.props;
    if (searchText && searchText.length > 2) {
      const regex = new RegExp(searchText.replace('[^a-zA-Z0-9 ]/g', ''), 'i');
      let searchedMarketPlaces = [];
      let mergedResults = [];
      const marketplaceList = this.props.marketplaceList;
      marketplaceList.forEach((marketplace) => {
        if (regex.test(marketplace.name) || regex.test(marketplace.description)) {
          const contentLength = Object.keys(marketplace).length;
          if (contentLength) {
            const marketplaceData = {
              type: this.state.searchItemTypes.marketPlace,
              data: marketplace,
            };
            searchedMarketPlaces.push(marketplaceData);
            mergedResults.push(marketplaceData);
          }
        }
      });
      const markets = this.props.markets;
      markets.forEach((market) => {
        if (regex.test(market.name) || regex.test(market.description)) {
          const contentLength = Object.keys(market).length;
          if (contentLength) {
            const marketData = {
              type: this.state.searchItemTypes.market,
              data: market,
            };
            searchedMarketPlaces.push(marketData);
            mergedResults.push(marketData);
          }
        }
      });
      const { filteredSearchResults, searchedSellers } = this.filterMessages();
      const res = [...mergedResults, ...filteredSearchResults, ...searchedSellers];
      this.setState({
        mergedResults: res,
        searchedMarketPlaces: searchedMarketPlaces,
        filteredSearchResults: filteredSearchResults,
        searchedSellers: searchedSellers,
      });
    } else {
      this.setState({ mergedResults: [] });
    }
  };

  //get sellers and channels
  readMarketplace() {
    return new Promise((resolve) => {
      const channels = [];
      const promises = [];
      const regex = new RegExp(this.props.searchText.replace('[^a-zA-Z0-9 ]/g', ''), 'i');

      const { isChapter, chapterId, partnerMode, partnerList, profile } = this.props;

      SearchHelper.FindParents(
        {
          marketplaceId: '*',
          filterByDistance: !!partnerMode,
          partnerList: partnerMode ? partnerList : undefined,
          chapterId: isChapter ? chapterId : undefined,
          coords: profile.homeCoords,
          userHomeAddress: profile.homeAddress,
          distanceRadius: MARKETPLACE_DISTANCE_RADIUS,
        },
        (sellers) => {
          if (sellers && sellers.length) {
            sellers.forEach((seller) => {
              if (seller && seller.isMarketplaceSeller && seller.marketplaceChannelURL) {
                promises.push(
                  new Promise((resolve) => {
                    SendBirdUser.GetChannel(seller.marketplaceChannelURL, (channel) => {
                      if (channel) {
                        SendBirdUser.SetChannelTime(channel);
                        channel.seller = seller;

                        // We must query to get the full member list
                        channel.fullMemberList = [];
                        SendBirdUser.GetMemberList('group_channels', channel.url)
                          .then((members) => {
                            channel.fullMemberList = members;
                          })
                          .catch((error) => {
                            console.warn('Get member list fail', error);
                          });

                        channels.push(channel);
                      } else {
                        console.warn('Channel not found!', seller.id, seller.marketplaceChannelURL);
                      }
                      resolve();
                    });
                  }),
                );
              }
            });
            this.setState(
              {
                channels: channels,
              },
              () => this.getChannelListMessages(channels),
            );
          } else {
            this.setState(
              {
                channels: channels,
              },
              () => this.getChannelListMessages(channels),
            );
          }
        },
      );
      resolve();
    });
  }

  //render channel data functions from here

  //get all messages in the listed channels
  getChannelListMessages = (channelList) => {
    return new Promise((resolve, reject) => {
      const promises = channelList.map((channel) => {
        return new Promise((resolve) => {
          const messageQuery = channel.createPreviousMessageListQuery();
          channel.messageList = [];
          this.getChannelMessages(channel, messageQuery).then(() => resolve());
        });
      });

      Promise.all(promises).then(() => resolve());
    });
  };

  getChannelMessages(channel, messageQuery) {
    return new Promise((resolve, reject) => {
      if (messageQuery.hasMore) {
        // TODO: Need to delay fetches so we don't trigger SendBird exception of too many requests
        setTimeout(() => {
          messageQuery.load(100, false, (response, error) => {
            if (error) {
              reject(error);
            } else {
              if (response && response.length) {
                channel.messageList = [...channel.messageList, ...response];
              }
              resolve();
              return this.getChannelMessages(channel, messageQuery);
            }
          });
        }, Math.random() * 200);
      } else {
        resolve();
      }
    });
  }

  isLatest(messageList, currentMessage) {
    let ret = true;

    if (currentMessage.customType) {
      for (let i = 0; i < messageList.length && ret; i++) {
        let message = messageList[i];

        if (
          message.customType === currentMessage.customType &&
          currentMessage.url === message.url &&
          currentMessage.messageId !== message.messageId
        ) {
          ret = currentMessage.createdAt > message.createdAt;
        }
      }
    }
    return ret;
  }

  filterMessages = () => {
    const { searchText } = this.props;
    let filteredSearchResults = [];
    let searchedSellers = [];
    if (searchText) {
      const regex = new RegExp(searchText.replace('[^a-zA-Z0-9 ]/g', ''), 'i');
      if (this.state.channels.length) {
        this.state.channels.forEach((channel) => {
          let currentSeller = channel.seller;
          currentSeller.coverUrl = channel.coverUrl || '';
          const sellerData = {
            type: this.state.searchItemTypes.seller,
            data: currentSeller,
          };
          const result = this.filterChannelMessages(channel);
          if (result.length) {
            filteredSearchResults.push(...result);
          }
          if (
            regex.test(currentSeller.marketplaceBusinessName) ||
            regex.test(currentSeller.marketplaceDescription)
          ) {
            searchedSellers.push(sellerData);
          }
        });
      }
    }
    return { filteredSearchResults, searchedSellers };
  };

  filterChannelMessages = (channel) => {
    const { searchText, profile } = this.props;
    const searchResults = [];

    if (searchText && channel.lastMessage && channel.messageList) {
      const regex = new RegExp(searchText.replace('[^a-zA-Z0-9 ]/g', ''), 'i');
      channel.messageList.forEach((message, index) => {
        const messageContent = message.isUserMessage() ? message.message : message.name;
        const senderString = message.isAdminMessage() ? 'Ujama' : message.sender.nickname;
        const isSenderStringMatched = regex.test(senderString);
        if (regex.test(messageContent) || isSenderStringMatched) {
          if (this.isLatest(channel.messageList, message)) {
            const messageDate = new Date(message.createdAt);
            searchResults.push({
              type: this.state.searchItemTypes.channel,
              data: {
                key: channel.url + message.messageId + '-' + index,
                messageId: message.messageId,
                messageContent: messageContent,
                senderString: senderString,
                customType: channel.customType,
                name: SendBirdUser.GetChatTitle(channel, profile.id),
                url: channel.url,
                coverUrl: channel.coverUrl || '',
                members: channel.fullMemberList,
                channelDate:
                  moment(messageDate).format('MM/DD/YYYY') +
                  ' ' +
                  moment(messageDate).format('HH:mm'),
                channelType: channel.channelType,
                isSenderStringMatched: isSenderStringMatched,
              },
            });
          }
        }
      });
    }
    return searchResults;
  };

  getChannel(channelURL) {
    let ret = null;
    ret = this.state.channels.find((channel) => channel.url === channelURL);
    return ret;
  }

  handleChannelNavigation = (channelURL, scrollToMessageId = null) => {
    const { profile, history } = this.props;
    const channel = this.getChannel(channelURL);

    if (channel) {
      SendBirdUser.BrowseGroupChannel(
        history,
        channel,
        profile.id,
        channel.seller.id,
        scrollToMessageId,
      );
    } else {
      this.showErrorMessage();
    }
  };

  showErrorMessage() {
    toast.error("Oops, we're having trouble accessing the Internet.");
  }

  render() {
    const { mergedResults } = this.state;
    const fragments = [];

    if (!this.props.profile.homeCoords) {
      return (
        <NoAddress
          message="Ujama needs your home address to search for sellers in your area"
          history={this.props.history}
        />
      );
    }

    if (mergedResults.length === 0) {
      return this.renderEmptyResults();
    }

    mergedResults.forEach((result, index) => {
      fragments.push(this.renderItem(result, index));
    });

    return <div>{fragments}</div>;
  }

  renderItem(item, index) {
    if (
      item.type === this.state.searchItemTypes.marketPlace ||
      item.type === this.state.searchItemTypes.market
    ) {
      return this.renderMarketPlace(item, index);
    }
    if (item.type === this.state.searchItemTypes.seller) {
      return this.renderSellerData(item, index);
    }
    if (item.type === this.state.searchItemTypes.channel) {
      return this.renderSearchedChannel(item, index);
    }
  }

  renderMarketPlace(marketPlaceData, index) {
    const marketPlace = marketPlaceData.data;
    const isMarket = 'location' in marketPlace;
    const description = maybeTrimString(marketPlace.description, this.props.searchText);

    return (
      <ListItem
        key={index}
        button
        divider
        onClick={() =>
          isMarket ? this.onClickMarketLogo(marketPlace) : this.onClickMarketplace(marketPlace)
        }
      >
        <ListItemAvatar>
          <Avatar src={marketPlace.image ? marketPlace.image : marketPlace.splash} />
        </ListItemAvatar>
        <ListItemText
          inset
          primary={
            <div style={{ width: '90%' }}>
              <Highlighter
                highlightStyle={styles.textHighlight}
                searchWords={[this.props.searchText]}
                textToHighlight={marketPlace.name}
              />
            </div>
          }
          secondary={
            <div style={{ width: '90%' }}>
              <Highlighter
                highlightStyle={styles.textHighlight}
                searchWords={[this.props.searchText]}
                textToHighlight={description}
              />
            </div>
          }
        />
        <ListItemSecondaryAction>
          <ChevronRight />
        </ListItemSecondaryAction>
      </ListItem>
    );
  }

  renderSellerData(sellerData, index) {
    const seller = sellerData.data;
    const description = maybeTrimString(seller.marketplaceDescription, this.props.searchText);

    return (
      <ListItem
        key={index}
        button
        divider
        onClick={() => this.handleChannelNavigation(seller.marketplaceChannelURL)}
      >
        <ListItemAvatar>
          <Avatar src={seller.coverUrl} />
        </ListItemAvatar>
        <ListItemText
          inset
          primary={
            <div style={{ width: '90%' }}>
              <Highlighter
                highlightStyle={styles.textHighlight}
                searchWords={[this.props.searchText]}
                textToHighlight={seller.marketplaceBusinessName}
              />
            </div>
          }
          secondary={
            <div style={{ width: '90%' }}>
              <Highlighter
                highlightStyle={styles.textHighlight}
                searchWords={[this.props.searchText]}
                textToHighlight={description}
              />
            </div>
          }
        />
        <ListItemSecondaryAction>
          <ChevronRight />
        </ListItemSecondaryAction>
      </ListItem>
    );
  }

  renderSearchedChannel(itemData, index) {
    const item = itemData.data;

    return (
      <SearchChatResult
        index={index}
        userID={this.props.profile.id}
        customType={item.customType}
        name={item.name}
        url={item.url}
        messageText={item.messageContent}
        senderNickName={item.senderString}
        coverUrl={item.coverUrl || ''}
        members={item.members}
        channelDate={item.channelDate}
        searchText={this.props.searchText}
        onClick={() => this.handleChannelNavigation(item.url, item.messageId)}
      />
    );
  }

  renderEmptyResults() {
    const message =
      this.props.searchText.length > 2 ? 'No matching results' : 'Type at least three characters';

    return (
      <div style={styles.centering}>
        <Typography variant="h2" color="textSecondary">
          {message}
        </Typography>
      </div>
    );
  }
}

const styles = {
  textHighlight: {
    color: '#68CDDC',
    fontWeight: '800',
  },
  centering: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 40,
  },
};
