import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
const GOOGLE_MAPS_API_URL = 'https://maps.googleapis.com/maps/api/';
const APIURL = process.env.REACT_APP_API_BASE_URL;
const APIKEY = process.env.REACT_APP_FIREBASE_API_KEY;

const NEARBY_DISTANCE = 100;

export function getCoordinates(address, callback) {
  if (!address || !address[0]) {
    callback(null);
  } else {
    // GeoCoder chokes on business names so remove them
    geocodeByAddress(address)
      .then((results) =>
        getLatLng(results[0]).then((latLng) => {
          const coords = {
            latitude: latLng.lat,
            longitude: latLng.lng,
          };
          callback(coords);
        }),
      )
      .catch((error) => console.error('Error', error));
  }
}

export function getNearbyEntities(keyword, entityType, fromCoords, callback) {
  const entityList = [];

  const url =
    GOOGLE_MAPS_API_URL +
    'place/nearbysearch/json?location=' +
    fromCoords.latitude +
    ',' +
    fromCoords.longitude +
    '&radius=50000' +
    '&type=' +
    entityType +
    '&keyword=' +
    encodeURIComponent(keyword) +
    '&key=' +
    APIKEY;

  fetch(APIURL + 'corsProxy?url=' + url, {
    mode: 'cors',
  })
    .then((response) => response.json())
    .then((responseJson) => {
      console.log('JSON REP', responseJson);
      responseJson.results.forEach((result) => {
        entityList.push({
          name: result.name,
          vicinity: result.vicinity,
          id: result.id,
          place_id: result.place_id,
        });
      });
      callback(entityList);
    })
    .catch((e) => {
      console.warn(e);
      callback(entityList);
    });
}

export function getPlaceDetails(place_id, callback) {
  const url = GOOGLE_MAPS_API_URL + 'place/details/json?placeid=' + place_id + '&key=' + APIKEY;

  fetch(APIURL + 'corsProxy?url=' + url)
    .then((response) => response.json())
    .then((responseJson) => {
      if (responseJson && responseJson.result) {
        callback(responseJson.result);
      }
    })
    .catch((e) => {
      console.warn(e);
      callback(null);
    });
}

export function parseAddressComponents(address_components) {
  const addressComponents = {};

  // Parse details
  for (var prop in address_components) {
    switch (address_components[prop].types[0]) {
      case 'street_number':
        addressComponents.streetNumber = address_components[prop].short_name;
        break;
      case 'route':
        addressComponents.streetName = address_components[prop].short_name;
        break;
      case 'neighborhood':
        addressComponents.neighborhood = address_components[prop].long_name;
        break;
      case 'locality': // North Hollywood or Los Angeles?
        addressComponents.city = address_components[prop].long_name;
        break;
      case 'administrative_area_level_1': //  Note some countries don't have states
        addressComponents.state = address_components[prop].short_name;
        break;
      case 'postal_code':
        addressComponents.zip = address_components[prop].long_name;
        break;
      case 'country':
        addressComponents.country = address_components[prop].short_name;
        break;
      default:
    }
  }

  return addressComponents;
}

export function getTravelTime(fromAddress, toAddress, callback) {
  // Don't even try if we don't have addresses
  if (!fromAddress || !fromAddress[0] || !toAddress || !toAddress[0]) {
    callback(null);
    return;
  }
  const mode = 'driving';
  const url =
    APIURL +
    'corsProxy?url=' +
    GOOGLE_MAPS_API_URL +
    'directions/json?origin=' +
    encodeURIComponent(fromAddress) +
    '&destination=' +
    encodeURIComponent(toAddress) +
    '&key=' +
    APIKEY +
    '&mode' +
    mode;
  fetch(url)
    .then((response) => response.json())
    .then((responseJson) => {
      if (responseJson.routes.length) {
        callback(responseJson.routes[0].legs[0].duration);
        return;
      } else {
        console.log('GET TRAVEL TIME BAD RESPONSE--->', responseJson, url);
        callback(null);
        return;
      }
    })
    .catch((e) => {
      console.warn(e);
      callback(null);
    });
}

export function calculateDistance(coords1, coords2) {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(coords2.latitude - coords1.latitude);
  var dLon = deg2rad(coords2.longitude - coords1.longitude);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(coords1.latitude)) *
      Math.cos(deg2rad(coords2.latitude)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d;
}

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

export function getCurrentLocation(callback) {
  if ('geolocation' in navigator) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        return callback({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      (error) => {
        console.error(error);
        return callback(null);
      },
      {
        enableHighAccuracy: true,
      },
    );
  } else {
    return callback(null);
  }
}

export function areCoordinatesNearby(coords1, coords2) {
  if (coords1 && coords2) {
    let distance = calculateDistanceInMeters(coords1, coords2);
    return distance < NEARBY_DISTANCE;
  } else {
    return false;
  }
}

export function calculateDistanceInMeters(coords1, coords2) {
  var R = 6371000; // Radius of the earth in meters
  var dLat = deg2rad(coords2.latitude - coords1.latitude);
  var dLon = deg2rad(coords2.longitude - coords1.longitude);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(coords1.latitude)) *
      Math.cos(deg2rad(coords2.latitude)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in meters
  return d;
}
