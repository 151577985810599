import FirebaseManager from '../firebase/firebase-manager';

export default class VersionCheck {
  static read(callback) {
    const firebaseManager = new FirebaseManager();

    firebaseManager.readOnce('appVersion', (snap) => {
      const versionObj = snap.exists() ? snap.val() : null;
      callback(versionObj);
    });
  }

  static getPasscode(callback) {
    VersionCheck.read((versionObj) => {
      var passcode = 'yougottobekiddingme12999';

      if (versionObj) {
        passcode = versionObj.passcode;
      }

      callback(passcode);
    });
  }

  static isUnavailable(callback) {
    VersionCheck.read((versionObj) => {
      var isUnavailable = true;

      if (versionObj) {
        isUnavailable = versionObj.unavailable;
      }

      callback(isUnavailable);
    });
  }

  static checkAddChatUserDelay() {
    const firebaseManager = new FirebaseManager();
    return new Promise((resolve) => {
      firebaseManager.readOnce('appVersion/serializeAddUsersToChat', (snap) => {
        const serializeAddUsersToChat = snap.exists() ? snap.val() : null;
        resolve(serializeAddUsersToChat);
      });
    });
  }

  static readAddChatUserDelayTime() {
    const firebaseManager = new FirebaseManager();
    return new Promise((resolve) => {
      firebaseManager.readOnce('appVersion/settimeoutDelay', (snap) => {
        const serializeAddUsersToChat = snap.exists() ? snap.val() : null;
        resolve(serializeAddUsersToChat);
      });
    });
  }
}
