class CalculationsHelper {
  static calculateDistance(coordinates1, coordinates2) {
    let R = 6371; // Radius of the earth in km

    let dLat = deg2rad(coordinates2.latitude - coordinates1.latitude);
    let dLon = deg2rad(coordinates2.longitude - coordinates1.longitude);

    let a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(coordinates1.latitude)) *
        Math.cos(deg2rad(coordinates2.latitude)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c;

    function deg2rad(deg) {
      return deg * (Math.PI / 180);
    }
  }

  static calculateTravelTime(address1, address2) {
    return new Promise((resolve, reject) => {
      const time = new Date();

      getTravelTime(address1, address2, time)
        .then((duration) => resolve(duration))
        .catch((error) => {
          console.warn('Error calculating travel time', error);
          reject(error);
        });
    });
  }
}

function getTravelTime(fromAddress, toAddress, arrivalTime) {
  return new Promise((resolve, reject) => {
    if (!fromAddress || !toAddress) {
      reject(
        new Error(
          `Invalid address parameters: fromAddress (${fromAddress}), toAddress (${toAddress})`,
        ),
      );
    }

    if (!google || !google.maps) {
      console.warn('Calculations: Google Maps API has not been initialized.');
      reject(new Error('Google Maps API not initialized'));
    }

    const service = new google.maps.DistanceMatrixService();
    const distanceData = {
      origins: [fromAddress],
      destinations: [toAddress],
      travelMode: google.maps.TravelMode.DRIVING,
    };

    service
      .getDistanceMatrix(distanceData)
      .then((response) => {
        const duration = response.rows[0].elements[0].duration;
        resolve(duration ? duration.text : null);
      })
      .catch((error) => {
        console.warn('General error with Google Maps API', error);
        reject(error);
      });
  });
}

export default CalculationsHelper;
