import FirebaseManager from '../firebase/firebase-manager';

export default class PartnerStorage {
  static Read(partnerID, callback) {
    const firebaseManager = new FirebaseManager();

    firebaseManager.readOnce('partnerData/' + partnerID, (snap) => {
      const partner = snap && snap.exists() ? snap.val() : null;
      if (partner) {
        partner.id = snap.key;
      }
      callback(partner);
    });
  }

  static Add(partner) {
    const firebaseManager = new FirebaseManager();
    const today = new Date();

    partner.created = today.toISOString();
    partner.createdBy = ''; // TODO - get current user

    return firebaseManager.saveData('partnerData/', partner);
  }

  static Update(partner, partnerID) {
    const today = new Date();
    const firebaseManager = new FirebaseManager();

    partner.lastEdit = today.toISOString();
    partner.lastEditBy = ''; // TODO - get current user

    return firebaseManager.update('partnerData/' + partnerID, partner);
  }

  static Delete(partnerID) {
    const firebaseManager = new FirebaseManager();
    firebaseManager.deleteValue('partnerData/' + partnerID);
  }

  static ReadAll(callback) {
    const firebaseManager = new FirebaseManager();
    const partnerList = [];

    firebaseManager.readOnce('partnerData', (snap) => {
      snap.forEach((child) => {
        const partner = child.val();
        if (partner) {
          partner.id = child.key;
          partnerList.push(partner);
        }
      });
      callback(partnerList);
    });
  }

  static AddAdministrators(partnerID, administratorList) {
    return new Promise((resolve, reject) => {
      const firebaseManager = new FirebaseManager();
      const now = new Date();
      const promises = [];

      administratorList.forEach((administrator) => {
        promises.push(
          new Promise((resolve, reject) => {
            firebaseManager
              .update('partnerData/' + partnerID + '/admins/' + administrator.id, {
                id: administrator.id,
                when: now.toISOString(),
              })
              .then(resolve())
              .catch((error) => reject(error));
          }),
        );
      });

      Promise.all(promises)
        .then(() => resolve())
        .catch((error) => reject(error));
    });
  }

  static RemoveAdministrators(partnerID, administratorList) {
    return new Promise((resolve, reject) => {
      const firebaseManager = new FirebaseManager();
      const promises = [];

      administratorList.forEach((administrator) => {
        promises.push(
          new Promise((resolve, reject) => {
            firebaseManager.deleteValue(
              'partnerData/' + partnerID + '/admins/' + administrator.id,
              () => {
                resolve();
              },
            );
          }),
        );
      });

      Promise.all(promises)
        .then(() => resolve())
        .catch((error) => reject(error));
    });
  }

  static AddTopic(partnerId, topic) {
    return new Promise((resolve, reject) => {
      const firebaseManager = new FirebaseManager();

      firebaseManager
        .update(`partnerData/${partnerId}/topics/${topic.id}`, topic)
        .then(resolve())
        .catch((error) => reject(error));
    });
  }

  static RemoveTopic(partnerId, topicId) {
    return new Promise((resolve, reject) => {
      const firebaseManager = new FirebaseManager();

      firebaseManager.deleteValue(`partnerData/${partnerId}/topics/${topicId}`, () => {
        resolve();
      });
    });
  }
}
