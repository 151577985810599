import ProfileStorage from '../storage/profile';
import AuthHelper from '../helpers/auth';
import FirebaseManager from '../firebase/firebase-manager';

export default class RolesStorage {
  static ReadRoles(profileID, callback) {
    const firebaseManager = new FirebaseManager();

    firebaseManager.readOnce('roles/' + profileID, (snap) => {
      let roles = snap.exists() ? snap.val() : null;
      if (callback) {
        return callback(roles);
      }
    });
  }

  static UpdateRoles(profileId, roles, callback) {
    const firebaseManager = new FirebaseManager();
    firebaseManager.update('roles/' + profileId, roles).then(() => {
      if (callback) {
        callback();
      }
    });
  }

  static ReadAllRoles(callback) {
    const firebaseManager = new FirebaseManager();
    let roles = [];

    firebaseManager.readOnce('roles/', (snap) => {
      snap.forEach((child) => {
        let role = child.val();
        if (role) {
          role.id = child.key;
          roles.push(role);
        }
      });
      if (callback) {
        return callback(roles);
      }
    });
  }

  static RequestAccess(type, itemId, callback) {
    AuthHelper.getProfileDetails().then((profile) => {
      const userId = profile.id;
      RolesStorage.findIndexList(type, itemId, userId, (found, listType, userType, roles) => {
        if (found) {
          callback({
            result: 'duplicate',
            type: listType,
          });
        } else {
          let checkKey = type === 'school' ? 'schools' : 'organizations';
          if (roles) {
            if (roles[checkKey]) {
              if (roles[checkKey].requested) {
                roles[checkKey].requested.push({ id: itemId });
              } else {
                roles[checkKey].requested = [{ id: itemId }];
              }
            } else {
              //corner case if a user is admin before requesting
              roles[checkKey] = { requested: [{ id: itemId }] };
            }
          } else {
            roles = { [checkKey]: { requested: [{ id: itemId }] } };
          }
          RolesStorage.UpdateRoles(userId, roles, () => {
            callback({
              result: 'requested',
              type: 'requested',
            });
          });
        }
      });
    });
  }

  static CheckAccess(type, itemId, callback) {
    AuthHelper.getProfileDetails().then((profile) => {
      const userId = profile.id;
      RolesStorage.findIndexList(type, itemId, userId, (found, listType, userType, roles) => {
        if (found) {
          callback({
            result: 'true',
            type: listType,
            userType: userType,
          });
        } else {
          callback({
            result: 'false',
            type: listType,
            userType: userType,
          });
        }
      });
    });
  }

  static findIndexList(type, itemId, userId, callback) {
    let index = -1;
    let listType = 'none';
    let userType = 'none';
    let found = false;
    RolesStorage.ReadRoles(userId, (rolesObj) => {
      RolesStorage.CheckSuperUser((adminRoles) => {
        if (adminRoles === 'ujama') {
          listType = 'granted';
          userType = 'admin';
          found = true;
        } else {
          const rolesList = rolesObj
            ? type === 'school'
              ? rolesObj.schools
              : rolesObj.organizations
            : null;
          if (rolesList) {
            if (rolesList.requested) {
              index = rolesList.requested.findIndex(
                (school) => school.id.toString() === itemId.toString(),
              );
              if (index !== -1) {
                listType = 'requested';
                found = true;
              }
            }
            if (rolesList.granted) {
              let index = rolesList.granted.findIndex(
                (school) => school.id.toString() === itemId.toString(),
              );
              if (index !== -1) {
                listType = 'granted';
                userType = rolesList.granted[index].type;
                found = true;
              }
            }
          }
        }
        callback(found, listType, userType, rolesObj);
      });
    });
  }

  static GetAllRoleRequest(callback) {
    let requests = [];
    RolesStorage.ReadAllRoles((snap) => {
      snap.forEach((role) => {
        if (
          (role.schools && role.schools.requested) ||
          (role.organizations && role.organizations.requested)
        ) {
          requests.push(role);
        }
      });
      callback(requests);
    });
  }

  static GetAllGrantedRoles(callback) {
    let grantedList = [];
    RolesStorage.ReadAllRoles((snap) => {
      snap.forEach((role) => {
        if (
          (role.schools && role.schools.granted) ||
          (role.organizations && role.organizations.granted)
        ) {
          grantedList.push(role);
        }
      });
      callback(grantedList);
    });
  }

  static moveRole(profileID, type, itemId, userLevel, callback) {
    RolesStorage.ReadRoles(profileID, (roles) => {
      let index = roles[type].requested.findIndex((item) => item.id === itemId);
      if (index !== -1) {
        roles[type].requested.splice(index, 1);
      }
      if (!roles[type] || !roles[type].granted) {
        roles[type].granted = [{ id: itemId, type: userLevel }];
      } else {
        roles[type].granted.push({ id: itemId, type: userLevel });
      }
      RolesStorage.UpdateRoles(profileID, roles, () => {
        callback();
      });
    });
  }

  static removeRequest(profileID, type, itemId, callback) {
    RolesStorage.ReadRoles(profileID, (roles) => {
      let index = roles[type].requested.findIndex((item) => item.id === itemId);
      if (index !== -1) {
        roles[type].requested.splice(index, 1);
      }
      RolesStorage.UpdateRoles(profileID, roles, () => {
        callback();
      });
    });
  }

  static revokeRole(profileID, type, itemId, callback) {
    RolesStorage.ReadRoles(profileID, (roles) => {
      let index = roles[type].granted.findIndex((item) => item.id === itemId);
      if (index !== -1) {
        roles[type].granted.splice(index, 1);
      }
      RolesStorage.UpdateRoles(profileID, roles, () => {
        callback();
      });
    });
  }

  static CheckSuperUser(callback) {
    console.log('CheckSuperUser');
    AuthHelper.getProfileDetails().then((profile) => {
      if (!profile) {
        return this.context.history.push('/login');
      }
      const userId = profile.id;
      RolesStorage.ReadRoles(userId, (adminRoles) => {
        if (adminRoles && adminRoles.userLevel) {
          callback(adminRoles.userLevel);
        } else {
          callback('none');
        }
      });
    });
  }

  static addNewSuperUser(newSuperUserId, userLevel, callback) {
    AuthHelper.getProfileDetails().then((profile) => {
      const userId = profile.id;
      RolesStorage.ReadRoles(userId, (adminRoles) => {
        if (adminRoles && adminRoles.userLevel === 'ujama') {
          ProfileStorage.Read(newSuperUserId, (newSuperUser) => {
            if (newSuperUser) {
              RolesStorage.ReadRoles(newSuperUserId, (roles) => {
                if (roles) {
                  roles.userLevel = userLevel;
                } else {
                  roles = { userLevel };
                }
                RolesStorage.UpdateRoles(newSuperUserId, roles, () => {
                  callback();
                });
              });
            } else {
              console.error('User does not exist');
            }
          });
        }
      });
    });
  }
}
