import React from 'react';
import { Link } from 'react-router-dom';
import ROUTES from '../../../utils/consts';
import UjamaLogo from '../../../assets/images/logo.svg';

const Logo = () => {
  return (
    <h1 className="logo">
      <Link to={`${ROUTES.DASHBOARD}`}>
        <img src={UjamaLogo} alt="Ujama" />
      </Link>
    </h1>
  );
};

export default Logo;
