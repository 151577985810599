import SendBird from 'sendbird';
import moment from 'moment';
import 'moment/min/locales';
import SendBirdStorage from '../storage/sendbird';
import ProfileStorage from '../storage/profile';
import { makePossessiveString } from '../helpers/formatterHelper';
import APIUtils from '../APIUtils';
import ProfileDefault from '../../assets/images/profile-default.png';
import ROUTES, { IS_DEV } from '../../utils/consts';

const MAX_CONNECTION_RETRIES = 10;
const WAIT_FOR_RECONNECT_TIME = 1000;

const unknownDriverProfileImageURL = 'http://ujama.co/images/blank-driver-profile.png';

class SendBirdUser {
  static sb = null;
  static appInfo = null;
  static channelHandler = null;
  static userID = null;

  static Initialize(callback = () => {}) {
    SendBirdStorage.ReadAppInfo((appInfo) => {
      //console.log("SENDBIRD APPINFO--->", appInfo);
      if (appInfo) {
        SendBirdUser.appInfo = appInfo;

        SendBirdUser.sb = new SendBird({
          appId: SendBirdUser.appInfo.applicationID,
        });
        if (SendBirdUser.sb) {
          SendBirdUser.channelHandler = new SendBirdUser.sb.ChannelHandler();

          SendBirdUser.sb.addChannelHandler('UJAMA', SendBirdUser.channelHandler);
          callback();
        }
      }
    });
  }

  static SetMessageReceivedHandler(handler) {
    var ret = false;

    if (SendBirdUser.sb && SendBirdUser.channelHandler) {
      SendBirdUser.channelHandler.onMessageReceived = handler;
      ret = true;
    }

    return ret;
  }

  static SetChannelChangedHandler(handler) {
    var ret = false;

    if (SendBirdUser.sb && SendBirdUser.channelHandler) {
      SendBirdUser.channelHandler.onChannelChanged = handler;
      ret = true;
    }

    return ret;
  }

  static Terminate() {
    console.log('called TERMINATE');
    const sb = SendBirdUser.sb;

    if (SendBirdUser.channelHandler) {
      sb.removeChannelHandler('UJAMA');
    }

    if (sb) {
      sb.disconnect(() => {
        console.log('SendBird disconnected');
      });
    }
  }

  static ConnectWithId(profileId, callback) {
    ProfileStorage.Read(profileId, (profile) => {
      SendBirdUser.Connect(profile, callback);
    });
  }

  static Connect(profile, callback) {
    const sb = SendBirdUser.sb;

    if (sb) {
      SendBirdUser.userID = profile.id;
      sb.connect(profile.id, (user, error) => {
        if (user) {
          ProfileStorage.ReadProfileImage(profile.id, (uri) => {
            const url = uri ? uri : unknownDriverProfileImageURL;

            sb.updateCurrentUserInfo(profile.name, url, (response, error) => {
              //console.log('SendBird Connect User', response, error);
            });
          });
        }
        if (callback) {
          callback(user);
        }
      });
    } else {
      console.warn('SendBird user not initialized.');
    }
  }

  static ConnectionIsOpen() {
    var ret = false;
    const sb = SendBirdUser.sb;

    if (sb) {
      ret = sb.getConnectionState() === 'OPEN';
    }

    return ret;
  }

  static AddUser(profile) {
    return fetch(`https://api-${SendBirdUser.appInfo.applicationID}.sendbird.com/v3/users`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Api-Token': SendBirdUser.appInfo.apiToken,
      },
      body: JSON.stringify({
        user_id: profile.id,
        nickname: profile.name,
        profile_url: '',
      }),
    });
  }

  static GetUser(userId) {
    return fetch(
      `https://api-${SendBirdUser.appInfo.applicationID}.sendbird.com/v3/users/${userId}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Api-Token': SendBirdUser.appInfo.apiToken,
        },
      },
    )
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => {
        return null;
      });
  }

  static ValidateUsers(profiles) {
    return new Promise((resolve, reject) => {
      let promises = profiles.map((profile) => {
        return new Promise((resolve) => {
          SendBirdUser.GetUser(profile.id).then((response) => {
            if (!response || response.error) {
              console.log('SendBird adding missing user--->', profile.id, profile.name);
              SendBirdUser.AddUser(profile).then(() => resolve());
            } else {
              resolve();
            }
          });
        });
      });
      Promise.all(promises).then(() => resolve());
    });
  }

  static serializeAll(tasks) {
    return tasks.reduce((promiseChain, currentTask) => {
      return promiseChain.then((chainResults) => {
        return currentTask.then((currentResult) => {
          return [...chainResults, currentResult];
        });
      });
    }, Promise.resolve([]));
  }

  static AddUserList(profiles) {
    return new Promise((resolve, reject) => {
      let promises = profiles.map((profile) => {
        return new Promise((resolve) => {
          SendBirdUser.GetUser(profile.id).then((response) => {
            if (!response || response.error) {
              console.log('SendBird adding missing user--->', profile.id);
              if (SendBirdUser.appInfo.serializeAddUsersToChat) {
                setTimeout(() => {
                  SendBirdUser.AddUser(profile).then(() => {
                    resolve();
                  });
                }, SendBirdUser.appInfo.setTimeoutDelay);
              } else {
                SendBirdUser.AddUser(profile).then(() => {
                  resolve();
                });
              }
            } else {
              resolve();
            }
          });
        });
      });
      if (SendBirdUser.appInfo.serializeAddUsersToChat) {
        SendBirdUser.serializeAll(promises).then(() => {
          resolve();
        });
      } else {
        Promise.all(promises).then(() => {
          resolve();
        });
      }
    });
  }

  static GetMemberList(channelType, channelUrl, membersIn = [], token = '') {
    return new Promise((resolve, reject) => {
      APIUtils.get(
        `https://api-${SendBirdUser.appInfo.applicationID}.sendbird.com/v3/${channelType}/${channelUrl}/members?limit=100&token=${token}`,
        SendBirdUser.appInfo.apiToken,
      )
        .then((response) => {
          const members = response.members ? membersIn.concat(response.members) : membersIn;
          if (response.next) {
            SendBirdUser.GetMemberList(channelType, channelUrl, members, response.next)
              .then((members) => resolve(members))
              .catch((error) => reject(error));
          } else {
            members.forEach((member) => {
              member.userId = member.user_id;
            });
            resolve(members);
          }
        })
        .catch((error) => {
          console.warn(error);
          reject(error);
        });
    });
  }

  static AddUserToChannel(channelType, channelUrl, userId, inviterId) {
    return new Promise((resolve, reject) => {
      const payload = {
        user_ids: [userId],
        inviter_id: inviterId,
      };
      APIUtils.post(
        `https://api-${SendBirdUser.appInfo.applicationID}.sendbird.com/v3/${channelType}/${channelUrl}/invite`,
        payload,
        SendBirdUser.appInfo.apiToken,
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static CreateDirectMessageChannel(profile1, profile2, callback) {
    const profiles = [profile1, profile2];
    SendBirdUser.ValidateUsers(profiles).then(() => {
      const fullName1 = profile1.name.split(' ');
      const fullName2 = profile2.name.split(' ');
      const channelName = fullName1[0] + ' and ' + fullName2[0];

      const userIds = [profile1.id, profile2.id];
      SendBirdUser.CreateGroupChannel(
        channelName,
        true,
        userIds,
        'Direct-Message',
        '',
        false,
        false,
        callback,
      );
    });
  }

  static CreateSelectedFriendsChannel(channelName, profiles, callback) {
    const userIds = [];
    //make this use only id since we are changing the id.
    profiles.forEach((profile) => {
      userIds.push(profile.id);
    });
    SendBirdUser.CreateGroupChannel(
      channelName,
      false,
      userIds,
      'Selected-Friends',
      '',
      false,
      false,
      callback,
    );
  }

  static CreateWebinarChannel(channelName, profile, callback) {
    const userIds = [profile.id];

    SendBirdUser.CreateGroupChannel(
      channelName,
      false,
      userIds,
      'Webinar',
      '',
      true,
      true,
      callback,
    );
  }

  static CreateMarketplaceChannel(channelName, profile, callback) {
    const userIds = [profile.id];

    SendBirdUser.CreateGroupChannel(
      channelName,
      false,
      userIds,
      'Marketplace',
      profile.id,
      true,
      true,
      callback,
    );
  }

  static CreateMeetupChannel(channelName, profile, meetupID, callback) {
    const userIds = [profile.id];

    SendBirdUser.CreateGroupChannel(
      channelName,
      false,
      userIds,
      'Meetup',
      meetupID,
      true,
      true,
      callback,
    );
  }

  static GetChannel(url, callback, numWaits = 0) {
    const sb = SendBirdUser.sb;

    if (sb) {
      if (sb.getConnectionState() !== sb.ConnectionState.OPEN) {
        if (numWaits > MAX_CONNECTION_RETRIES) {
          console.warn('Exceeded waits for SendBird to reconnect');
          callback(null);
        } else {
          console.log('SendBird -- GetChannel waiting for SendBird to reconnect');
          setTimeout(
            () => SendBirdUser.GetChannel(url, callback, ++numWaits),
            WAIT_FOR_RECONNECT_TIME,
          );
        }
      } else {
        const channelInterface = url.includes('_group_channel_') ? sb.GroupChannel : sb.OpenChannel;

        channelInterface.getChannel(url, (channel, error) => {
          if (error) {
            console.warn('Problem getting channel for ' + url, error);
            callback(null, error);
          } else {
            callback(channel);
          }
        });
      }
    } else {
      console.warn('Invalid SendBird handle');
      callback(null);
    }
  }

  static GetGroupChannelsBatch(urls, callback, listQueryIn = null, channelsIn = []) {
    const sb = SendBirdUser.sb;

    if (sb) {
      let listQuery = listQueryIn;

      if (!listQuery) {
        listQuery = sb.GroupChannel.createPublicGroupChannelListQuery();
        listQuery.channelUrlsFilter = urls;
        listQuery.limit = 100;
        listQuery.membershipFilter = 'all';
      }

      if (listQuery.hasNext && channelsIn.length < urls.length) {
        listQuery
          .next()
          .then((groupChannels) => {
            const channels = channelsIn.concat(groupChannels);
            SendBirdUser.GetGroupChannelsBatch(urls, callback, listQuery, channels);
          })
          .catch((error) => {
            console.warn('Error getting batch of channels', error);
            callback(null);
          });
      } else {
        callback(channelsIn);
      }
    } else {
      console.warn('Invalid SendBird handle');
      callback(null);
    }
  }

  // SendBirdUser.GetChannel does not work if the user is not already a member
  // Use this version instead but note that the channel object returned does not have function members!
  static PlatformGetChannel(url, callback, numWaits = 0) {
    const sb = SendBirdUser.sb;

    if (sb) {
      if (url.includes('_group_channel_')) {
        SendBirdUser.PlatformGetGroupChannel(url)
          .then((channel) => {
            callback(channel);
          })
          .catch((error) => {
            console.log('PLATFORM ERROR--->', error);
            callback(null, error);
          });
      } else {
        SendBirdUser.PlatformGetOpenChannel(url)
          .then((channel) => {
            callback(channel);
          })
          .catch((error) => {
            callback(null, error);
          });
      }
    } else {
      console.warn('Invalid SendBird handle');
      callback(null);
    }
  }

  static PlatformGetGroupChannel(url) {
    return new Promise((resolve, reject) => {
      const parameters = '?channel_urls=' + url;
      APIUtils.get(
        `https://api-${SendBirdUser.appInfo.applicationID}.sendbird.com/v3/group_channels/` +
          parameters,
        SendBirdUser.appInfo.apiToken,
      )
        .then((response) => resolve(response.channels[0]))
        .catch((error) => {
          console.warn('SendBird PlatformGetGroupChannel error', error);
          reject(error);
        });
    });
  }

  static PlatformGetOpenChannel(url) {
    return new Promise((resolve, reject) => {
      const parameters = '?url_contains=' + url;
      APIUtils.get(
        `https://api-${SendBirdUser.appInfo.applicationID}.sendbird.com/v3/open_channels/` +
          parameters,
        SendBirdUser.appInfo.apiToken,
      )
        .then((response) => resolve(response.channels[0]))
        .catch((error) => {
          // Only log this error because this is how we check if a user already exists
          console.warn('SendBird PlatformGetOpenChannel error', error);
          reject(error);
        });
    });
  }

  static IsGroupChannel(channel) {
    let ret = false;

    if (channel) {
      const url = channel.channel_url ? channel.channel_url : channel.url;
      if (url) {
        ret = url.includes('_group_channel_');
      }
    }

    return ret;
  }

  static GetTrustedPoolChannel(profile, callback) {
    if (profile.trustedPoolChannelUrl) {
      const sb = SendBirdUser.sb;

      if (sb) {
        sb.GroupChannel.getChannel(profile.trustedPoolChannelUrl, (channel, error) => {
          if (error) {
            console.log('Problem getting channel', error);
            callback(null);
          } else {
            // Invite the whole trusted pool to the channel in case new friends have been added to the pool since the channel was created
            SendBirdUser.InviteTrustedPoolToChannel(profile, channel, () => {
              console.log('Retrieved existing channel --->', channel);
              callback(channel);
            });
          }
        });
      } else {
        callback(null);
      }
    } else {
      // If a channel doesn't exist, create it and store the url
      SendBirdUser.CreateTrustedPoolChannel(profile, (channel) => {
        if (channel) {
          console.log('Created new channel --->', channel);
          profile.trustedPoolChannelUrl = channel.url;
          ProfileStorage.Update(profile.id, profile);
        }
        callback(channel);
      });
    }
  }

  static InviteTrustedPoolToChannel(profile, channel, callback) {
    ProfileStorage.ReadAllTrustedPoolProfiles(profile, (trustedPoolList) => {
      SendBirdUser.ValidateUsers(trustedPoolList).then(() => {
        const userIds = [];
        trustedPoolList.forEach((profile) => {
          userIds.push(profile.id);
        });
        channel.inviteWithUserIds(userIds, (response, error) => {
          if (error) {
            console.log('Error inviting users to existing channel--->', error);
          }
          callback();
        });
      });
    });
  }

  static CreateTrustedPoolChannel(profile, callback) {
    ProfileStorage.ReadAllTrustedPoolProfiles(profile, (trustedPoolList) => {
      trustedPoolList.push(profile);
      SendBirdUser.ValidateUsers(trustedPoolList).then(() => {
        const channelName = makePossessiveString(profile.name) + ' Village';

        const userIds = [];
        trustedPoolList.forEach((profile) => {
          userIds.push(profile.id);
        });

        SendBirdUser.CreateGroupChannel(
          channelName,
          true,
          userIds,
          'Trusted-Pool',
          '',
          false,
          false,
          callback,
        );
      });
    });
  }

  static CreateGroupChannel(
    channelName,
    distinct,
    userIds,
    customType,
    entityId,
    isPublic,
    isSuper,
    callback,
  ) {
    const sb = SendBirdUser.sb;

    const params = new sb.GroupChannelParams();
    params.isPublic = isPublic;
    params.isSuper = isSuper && !IS_DEV; // Can't make super groups in staging
    params.isEphemeral = false;
    params.isDistinct = distinct;
    params.addUserIds(userIds);
    params.name = channelName;
    params.customType = customType;
    params.data = '' + entityId;
    params.coverUrl = SendBirdUser.getDefaultCoverURL(customType);

    sb.GroupChannel.createChannel(params, (channel, error) => {
      if (error) {
        console.log('Create Group Channel Fail.', channelName, error);
        callback(null);
        return;
      }
      console.log('SendBird Created Channel---->', channel);
      callback(channel);
    });
  }

  static GetChannelsWithUnreadMessages(userID, callback) {
    SendBirdUser.GetSubscribedChannels(userID, (channelList) => {
      const unreadChannels = [];
      // We only want channels with unread messages
      channelList.forEach((channel) => {
        if (channel.unreadMessageCount) {
          unreadChannels.push(channel);
        }
      });
      callback(unreadChannels);
    });
  }

  static GetSubscribedChannels(userId, callback) {
    var channelList = [];
    const sb = SendBirdUser.sb;

    if (sb) {
      const channelListQuery = sb.GroupChannel.createMyGroupChannelListQuery();
      const userIds = [userId];
      channelListQuery.limit = 100;
      /*sendbird limited to 20 channels by default if you didn't specify a #*/
      /*UPPED TO 150 CHANNELS ON 10/22 CHECK THAT THIS DOESNT INCREASE LOAD TIMES  */
      channelListQuery.userIdsFilter = userIds;
      channelListQuery.queryType = 'AND';

      if (channelListQuery.hasNext) {
        channelListQuery.next((response, error) => {
          if (error) {
            //console.log('Error retrieving subscribed channels --->', error);
            callback(channelList);
          } else {
            //console.log('Subscribed channel list --->', response);
            channelList = response.sort((a, b) => {
              if (a.lastMessage && b.lastMessage) {
                if (a.lastMessage.createdAt > b.lastMessage.createdAt) {
                  return -1;
                }
                if (b.lastMessage.createdAt > a.lastMessage.createdAt) {
                  return 1;
                }
              } else if (a.lastMessage) {
                return -1;
              } else if (b.lastMessage) {
                return 1;
              }
              return 0;
            });
            callback(channelList);
          }
        });
      } else {
        callback(channelList);
      }
    } else {
      callback(channelList);
    }
  }

  static UpdateCoverImage(channel, file, callback) {
    const params = new SendBirdUser.sb.GroupChannelParams();
    params.coverImage = file;
    channel.updateChannel(params, (channel, error) => {
      if (error) {
        console.warn('Error updating cover image', error);
        if (callback) {
          callback(null);
        }
      } else {
        if (callback) {
          callback(channel.url);
        }
      }
    });
  }

  static SendAdminMessage(message, channelUrl, channelType = 'group_channels') {
    return fetch(
      `https://api-${SendBirdUser.appInfo.applicationID}.sendbird.com/v3/${channelType}/${channelUrl}/messages`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Api-Token': SendBirdUser.appInfo.apiToken,
        },
        body: JSON.stringify({
          message_type: 'ADMM',
          message: message,
          send_push: true,
          mention_type: 'channel',
        }),
      },
    );
  }

  static SendFileMessageOnBehalf(
    userId,
    message,
    dataText,
    url,
    customType,
    channelUrl,
    channelType = 'group_channels',
  ) {
    return new Promise((resolve, reject) => {
      const payload = {
        message_type: 'FILE',
        user_id: userId,
        file_name: message,
        data: dataText,
        url: url,
        custom_type: customType,
        send_push: true,
        mention_type: 'channel',
      };
      APIUtils.post(
        `https://api-${SendBirdUser.appInfo.applicationID}.sendbird.com/v3/${channelType}/${channelUrl}/messages`,
        payload,
        SendBirdUser.appInfo.apiToken,
      )
        .then((response) => resolve(response))
        .catch((error) => {
          console.warn('SendBird SendFileMessageOnBehalf error', error);
          resolve(null);
        });
    });
  }

  static SendPlainMessageOnBehalf(userId, message, channelUrl, channelType = 'group_channels') {
    return new Promise((resolve, reject) => {
      const payload = {
        message_type: 'MESG',
        user_id: userId,
        message: message,
        send_push: true,
        mention_type: 'channel',
      };
      APIUtils.post(
        `https://api-${SendBirdUser.appInfo.applicationID}.sendbird.com/v3/${channelType}/${channelUrl}/messages`,
        payload,
        SendBirdUser.appInfo.apiToken,
      )
        .then((response) => resolve(response))
        .catch((error) => {
          console.warn('SendBird SendPlainMessageOnBehalf error', error);
          resolve(null);
        });
    });
  }

  static GetChatTitle(channel, userID) {
    let ret = channel.name;

    if (channel.customType === 'Direct-Message') {
      for (let i = 0; i < channel.members.length; i++) {
        let member = channel.members[i];
        if (member.userId !== userID) {
          ret = member.nickname;
          break;
        }
      }
    }

    return ret;
  }

  static GetLastMessageText(channel) {
    let ret = '';

    if (channel.lastMessage) {
      if (channel.lastMessage.message) {
        ret = channel.lastMessage.message;
      } else if (channel.lastMessage.name) {
        ret = channel.lastMessage.name;
      } else if (channel.lastMessage.data) {
        ret = channel.lastMessage.data;
      }
    }

    return ret;
  }

  static GetLastMessageUser(channel) {
    let ret = null;

    if (channel.lastMessage) {
      ret = channel.lastMessage.sender;
    }

    return ret;
  }

  static ChannelAddMetaData(channel, metaData, callback) {
    channel.updateMetaData(metaData, true, (response, error) => {
      if (error) {
        console.warn('ChannelAddMetaData error', error, metaData);
      }
      if (callback) {
        callback(response, error);
      }
    });
  }

  static ChannelGetMetaData(channel, keys, callback) {
    if (keys) {
      channel.getMetaData(keys, (response, error) => {
        callback(response, error);
      });
    } else {
      channel.getAllMetaData((response, error) => {
        callback(response, error);
      });
    }
  }

  static ChannelGetAllMetaData(url, channelType) {
    return new Promise((resolve, reject) => {
      APIUtils.get(
        `https://api-${SendBirdUser.appInfo.applicationID}.sendbird.com/v3/${channelType}/${url}/metadata`,
        SendBirdUser.appInfo.apiToken,
      )
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  static GetChannelsWithMetaData(channelList, keys, callback) {
    const channels = [];
    const promises = [];

    channelList.forEach((channel) => {
      promises.push(
        new Promise((resolve) => {
          SendBirdUser.ChannelGetMetaData(channel, keys, (metaData, error) => {
            if (!error) {
              channel.metaData = metaData;
              channels.push(channel);
            }
            resolve();
          });
        }),
      );
    });

    Promise.all(promises).then(() => {
      callback(channels);
    });
  }

  static SetChannelTime(channel) {
    if (channel.lastMessage) {
      const lastMessageDate = new Date(channel.lastMessage.createdAt);
      channel.when =
        moment(lastMessageDate).format('MM/DD/YYYY') +
        ' ' +
        moment(lastMessageDate).format('HH:mm');
    } else {
      channel.when = '';
    }
  }

  static SortChannels(channelList) {
    return channelList.sort((a, b) => {
      const aDate = a.when ? new Date(a.when) : new Date(0);
      const bDate = b.when ? new Date(b.when) : new Date(0);

      if (aDate < bDate) {
        return 1;
      }
      if (bDate < aDate) {
        return -1;
      }
      return 0;
    });
  }

  static GetChatImageSource(channel, userID) {
    let ret = channel.coverUrl;

    if (channel.customType === 'Direct-Message') {
      for (let i = 0; i < channel.members.length; i++) {
        const member = channel.members[i];
        if (member.userId !== userID || channel.members.length === 1) {
          if (member.profileUrl) {
            ret = member.profileUrl;
          } else {
            ret = ProfileDefault;
          }
          break;
        }
      }
    }

    return ret;
  }

  static getDefaultCoverURL(customType) {
    let ret = 'https://ujama.co/images/blank-driver-profile.png';

    switch (customType) {
      case 'Selected-Friends':
        ret = 'https://ujama.co/chat-images/selected-friends.png';
        break;
      case 'Trusted-Pool':
        ret = 'https://ujama.co/chat-images/village.png';
        break;
      case 'Marketplace':
        ret = 'https://ujama.co/chat-images/marketplace.png';
        break;
      case 'Meetup':
        ret = 'https://ujama.co/chat-images/meetup.png';
        break;
      default:
    }
    return ret;
  }

  static NavigateToChannel(
    history,
    channelUrl,
    messageUrl = null,
    browseMode = false,
    scrollToMessageId = null,
  ) {
    SendBirdUser.GetChannel(channelUrl, (channel) => {
      if (channel) {
        const location = {
          pathname: ROUTES.CHAT,
          state: {
            channel,
            scrollToMessageUrl: messageUrl,
            browseMode: browseMode,
            scrollToMessageId: scrollToMessageId,
          },
        };

        history.push(location);
      }
    });
  }

  // Browsing means we temporarily make this user a member of the channel but revoke that membership when they are done browsing
  // unless they send a message
  static BrowseGroupChannel(
    history,
    channel,
    userId,
    inviterId,
    scrollToMessageId = null,
    scrollToMessageUrl = null,
  ) {
    if (channel) {
      SendBirdUser.GetMemberList('group_channels', channel.url)
        .then((members) => {
          if (members.find((member) => member.userId === userId)) {
            // If the user is already a member, then they are not browsing
            SendBirdUser.NavigateToChannel(
              history,
              channel.url,
              scrollToMessageUrl,
              false,
              scrollToMessageId,
            );
          } else {
            let inviterIdToUse = inviterId;

            // Check if the inviter is a member -- if not, make the group creator the inviter
            if (!members.find((member) => member.userId === inviterIdToUse)) {
              if (channel.created_by && channel.created_by.user_id) {
                inviterIdToUse = channel.created_by.user_id;
              } else if (channel.createdBy && channel.createdBy.userId) {
                inviterIdToUse = channel.createdBy.userId;
              } else {
                inviterIdToUse = null;
              }
            }

            if (inviterIdToUse) {
              SendBirdUser.AddUserToChannel('group_channels', channel.url, userId, inviterIdToUse)
                .then(() =>
                  SendBirdUser.NavigateToChannel(
                    history,
                    channel.url,
                    scrollToMessageUrl,
                    true,
                    scrollToMessageId,
                  ),
                )
                .catch((error) => {
                  console.warn(
                    'Error adding user to chat group',
                    error,
                    channel,
                    userId,
                    inviterId,
                  );
                });
            } else {
              console.warn(
                "Invalid invitation link! Inviter is not a member of this channel and can't find creator id",
                channel,
              );
            }
          }
        })
        .catch((error) => {
          console.warn('Error getting member list', error);
        });
    } else {
      console.warn('Invalid channel parameter');
    }
  }
}

export default SendBirdUser;
