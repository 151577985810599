import queryString from 'query-string';
import ROUTES from '../../utils/consts';

const defaultRoute = {
  pathname: '/',
  state: {},
};

class URLHelper {
  static parseParameters(parameters) {
    var routeObject = null;

    do {
      var id;

      if ((id = queryString.parse(parameters)._branch_match_id)) {
        routeObject = {
          pathname: ROUTES.BRANCHIO_ROUTER,
          state: {
            branch_match_id: id,
          },
        };
        break;
      }

      if ((id = queryString.parse(parameters).parent)) {
        routeObject = {
          pathname: `${ROUTES.PARENT}/${id}`,
        };
        break;
      }

      if ((id = queryString.parse(parameters).duplicateProfile)) {
        routeObject = {
          pathname: `${ROUTES.DUPLICATE_PROFILE}`,
          state: { duplicateID: id },
        };
        break;
      }

      if (queryString.parse(parameters).neighborhood) {
        routeObject = {
          pathname: `${ROUTES.FIND_PARENTS}`,
          state: { filterByDistance: true, title: 'Your Neighborhood Parents' },
        };
        break;
      }

      if (queryString.parse(parameters).messageSubscriptions) {
        routeObject = {
          pathname: `${ROUTES.MESSAGE_SUBSCRIPTIONS}`,
          state: {},
        };
        break;
      }
      // eslint-disable-next-line no-constant-condition
    } while (false); // do ONCE!

    return routeObject;
  }

  static save(parameters) {
    const routeObject = URLHelper.parseParameters(parameters);

    if (routeObject) {
      sessionStorage.setItem('URLHelper', JSON.stringify(routeObject));
    }
  }

  static getRoute() {
    var routeObject = defaultRoute;

    const data = sessionStorage.getItem('URLHelper');
    if (data) {
      routeObject = JSON.parse(data);
    }

    return routeObject;
  }

  static remove() {
    sessionStorage.removeItem('URLHelper');
  }
}

export default URLHelper;
