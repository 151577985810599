import { firebase } from '../firebase/firebase';
import ProfileHelper from '../helpers/profile';

class AuthHelper {
  static handleLogin(profileId) {
    return AuthHelper.refreshProfileDetails(profileId);
  }

  static refreshProfileDetails(profileId) {
    return new Promise((resolve, reject) => {
      ProfileHelper.getProfileDetails(profileId)
        .then((fullProfile) => {
          if (fullProfile) {
            try {
              localStorage.setItem('profile', JSON.stringify(fullProfile));
              console.log('Saved profile details to local storage!');
              resolve(true);
            } catch (error) {
              console.error('Failed to write profile details to local storage!', error);
              resolve(false);
            }
          } else {
            console.error('Failed to read profile', profileId);
            resolve(false);
          }
        })
        .catch((error) => {
          console.error('Failed to fetch profile details from firebase!', error);
          resolve(false);
        });
    });
  }

  static handleLogout() {
    if (localStorage.getItem('profile')) {
      console.info('Removing profile details from local storage!');
      localStorage.removeItem('profile');
    }
    firebase.auth().signOut();
  }

  static getProfileDetails() {
    return new Promise((resolve, reject) => {
      try {
        const stored = localStorage.getItem('profile');
        if (!stored) {
          console.info('No profile was found in local storage');
        }
        /*
        console.log(
          "Found the profile details to be parsed from local storage!",
          stored
        );
        */
        const profile = JSON.parse(stored);
        /*
        console.log(
          "Parsed the profile details from local storage successfully!",
          profile
        );
        */
        resolve(profile);
      } catch (err) {
        console.error('Unable to get profile details from local storage! ', err);
        reject(err);
        //window.location.href = '/create-profile';
      }
    });
  }

  static getGoogleClientId() {
    return process.env.REACT_APP_GOOGLE_CLIENT_ID;
  }
}

export default AuthHelper;
