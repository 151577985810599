import React from 'react';
import { toast } from 'react-toastify';
import {
  AppBar,
  Card,
  CardMedia,
  Badge,
  CircularProgress,
  Dialog,
  Button,
  IconButton,
  List,
  ListItem,
  Typography,
  Toolbar,
  Grid,
} from '@material-ui/core';
import { Check, ChevronRight, Search } from '@material-ui/icons';

import AuthHelper from '../../../api/helpers/auth';
import ProfileHelper from '../../../api/helpers/profile';
import ProfileStorage from '../../../api/storage/profile';
import SendBirdUser from '../../../api/sendbird/sendbird-user';
import RolesStorage from '../../../api/storage/roles';
import OrganizationStorage from '../../../api/storage/organization';
import SchoolStorage from '../../../api/storage/school';
import URLHelper from '../../../api/helpers/url-helper';
import StreamUser from '../../../api/stream/stream-user';
import PushNotification from '../../../api/pubnub/push-notification';
import { makeDeepLink } from '../../../api/helpers/branchio-helper';
import ShareModal from '../../common/share-modal';

import ROUTES from '../../../utils/consts';

import './dashboard.css';
import CardDefault from '../../../assets/images/card-default.svg';
import CardDefaultActivity from '../../../assets/images/card-default-activity.png';
import CardDefaultSchool from '../../../assets/images/card-default-school.png';
import CardDefaultPartner from '../../../assets/images/card-default-partner.png';
import CardNeighborhood from '../../../assets/images/card-default-neighborhood.png';
import MobileDownload from '../../../assets/images/download.png';
import { ReactComponent as Neighbors } from '../../../assets/images/neighbor-image.svg';
import { ReactComponent as GiftGuy } from '../../../assets/images/gift-guy.svg';
import { ReactComponent as Share } from '../../../assets/icons/share.svg';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.shareModal = React.createRef();

    this.state = {
      profile: null,
      invitationURL: '',
      schoolsData: {
        schools: [],
        loading: true,
      },
      activitiesData: {
        activities: [],
        loading: true,
      },
      adminData: {
        adminOrgs: [],
        adminSchools: [],
        userLevel: 'none',
        loading: true,
      },
      partnerData: {
        partners: [],
        loading: true,
      },
      feedNotifications: [],
      newPostCount: 0,
      hasHomeCoords: false,
      sendBirdUser: null,
      unreadMessageCount: 0,
      showInstallMobileBanner: false,
    };
  }

  componentDidMount() {
    const { history, doNotRoute } = this.props;
    this.readData();

    const route = URLHelper.getRoute();
    if (route) {
      URLHelper.remove(); // So we don't process these parameters again
      if (route !== '/' && !doNotRoute) {
        history.push(route);
      }
    } else {
      if (sessionStorage.getItem('ShowInstallMobileDialog')) {
        sessionStorage.removeItem('ShowInstallMobileDialog');
        this.setState({ showInstallMobileBanner: true });
      }
    }

    PushNotification.Initialize();
  }

  componentWillUnmount() {
    if (this.notificationFeedSubscription) {
      this.notificationFeedSubscription.cancel();
    }
  }

  populateAdminOrgs(orgs) {
    let promises = [];
    const checkArray = [];

    if (orgs && orgs.granted) {
      orgs.granted.foreach((entity) => {
        if (!checkArray.includes(entity.id)) {
          checkArray.push(entity.id);
          promises.push(
            new Promise((resolve, reject) => {
              OrganizationStorage.Read(entity.id, (org) => {
                resolve(org);
              });
            }),
          );
        }
      });
    }
    Promise.all(promises).then((orgs) => {
      let { adminData } = this.state;
      adminData.loading = false;
      adminData.adminOrgs = orgs;
      this.setState({ adminData });
    });
  }

  populateAdminSchools(schools) {
    let promises = [];
    const checkArray = [];

    if (schools && schools.granted) {
      schools.granted.forEach((entity) => {
        if (!checkArray.includes(entity.id)) {
          checkArray.push(entity.id);
          promises.push(
            new Promise((resolve, reject) => {
              SchoolStorage.Read(entity.id, (school) => {
                resolve(school);
              });
            }),
          );
        }
      });
    }
    Promise.all(promises).then((schools) => {
      let { adminData } = this.state;
      adminData.loading = false;
      adminData.adminSchools = schools;
      this.setState({ adminData });
    });
  }

  setUserLevelState(roles) {
    if (roles.userLevel) {
      let { adminData } = this.state;
      adminData.userLevel = roles.userLevel;
      adminData.loading = false;
      this.setState({ adminData });
    }
  }

  readData() {
    const { history } = this.props;
    AuthHelper.getProfileDetails()
      .then((profile) => {
        if (profile) {
          this.setState({ profile }, () => {
            localStorage.setItem('userId', profile.id);
            if (profile.homeCoords) {
              this.setState({ hasHomeCoords: true });
            }

            // TODO: This code should not be in this module!
            RolesStorage.ReadRoles(profile.id, (roles) => {
              //console.log(roles);
              if (roles) {
                this.populateAdminOrgs(roles.organizations);
                this.populateAdminSchools(roles.schools);
                this.setUserLevelState(roles);
              }
            });

            const promises = [];

            promises.push(
              new Promise((resolve) => {
                ProfileHelper.getPartners(profile.id).then((partners) => {
                  this.setState(
                    {
                      partnerData: {
                        partners: partners,
                        loading: false,
                      },
                    },
                    () => resolve(),
                  );
                });
              }),
            );

            promises.push(
              new Promise((resolve) => {
                ProfileHelper.getKidsSchools(profile.id).then((schools) => {
                  this.setState(
                    {
                      schoolsData: {
                        schools: schools || [],
                        loading: false,
                      },
                    },
                    () => resolve(),
                  );
                });
              }),
            );

            promises.push(
              new Promise((resolve) => {
                ProfileHelper.getKidsActivities(profile.id).then((activities) => {
                  this.setState(
                    {
                      activitiesData: {
                        activities: activities || [],
                        loading: false,
                      },
                    },
                    () => resolve(),
                  );
                });
              }),
            );

            promises.push(
              new Promise((resolve, reject) => {
                if (!StreamUser) {
                  console.error('No StreamUser');
                  reject('No StreamUser');
                }

                StreamUser.SubscribeToNotificationFeedChanges(profile, this.notificationFeedUpdated)
                  .then((subscription) => {
                    this.notificationFeedSubscription = subscription;
                    resolve();
                  })
                  .catch((error) => {
                    console.warn(error);
                    resolve();
                  });
              }),
            );

            ProfileStorage.ReadProfileImage(profile.id, (pic) => {
              if (!StreamUser) {
                console.error('No StreamUser');
                return;
              }
              StreamUser.GetToken(profile, pic)
                .then(() => {
                  Promise.all(promises).then(() => {
                    this.getNotificationFeed().then(() => this.updateNewPostCounts());
                  });
                })
                .catch((error) => {
                  console.warn('Error retrieving Stream token!', error);
                });
            });

            SendBirdUser.Connect(profile, (sendBirdUser) => {
              SendBirdUser.GetChannelsWithUnreadMessages(profile.id, (channelList) => {
                this.setState({
                  sendBirdUser: sendBirdUser,
                  unreadMessageCount: channelList.length,
                });
              });

              SendBirdUser.SetMessageReceivedHandler((channel, message) => {
                SendBirdUser.GetChannelsWithUnreadMessages(profile.id, (channelList) => {
                  this.setState({
                    unreadMessageCount: channelList.length,
                  });
                });
              });
            });

            // Save for analytics
            window.dataLayer = [
              {
                userId: profile.id,
                name: profile.name,
                phone: profile.phone,
                email: profile.email,
              },
            ];
          });
        } else {
          history.push('/login');
        }
      })
      .catch((error) => {
        toast.error(
          'Sorry! Unable to get profile details at the moment! Please try again or contact our support team!',
        );
        history.push('/login');
      });
  }

  notificationFeedUpdated = (data) => {
    if (!data || (data.new && data.new.length) || (data.deleted && data.deleted.length)) {
      this.getNotificationFeed().then(() => this.updateNewPostCounts());
    }
  };

  getNotificationFeed() {
    return new Promise((resolve, reject) => {
      const { profile } = this.state;

      StreamUser.GetNotificationFeed(profile.id)
        .then((feedNotifications) => {
          this.setState({ feedNotifications }, () => resolve());
        })
        .catch((error) => {
          console.warn('Could not get notification feed', error);
          resolve();
        });
    });
  }

  updateNewPostCounts() {
    const { partnerData, schoolsData, activitiesData } = this.state;
    let total = 0;

    total += this.countNewPostForEntities('partners', partnerData.partners);
    total += this.countNewPostForEntities('schools', schoolsData.schools);
    total += this.countNewPostForEntities('activities', activitiesData.activities);

    this.setState({
      newPostCount: total,
      partnerData: partnerData,
      schoolsData: schoolsData,
      activitiesData: activitiesData,
    });
  }

  countNewPostForEntities(entityType, entities) {
    let total = 0;

    entities.forEach((entity) => {
      entity.newPostCount = this.countNewPostsForEntity(
        entityType,
        entity.id ? entity.id : entity.orgId,
      );
      total += entity.newPostCount;
    });

    return total;
  }

  countNewPostsForEntity(feedGroup, feedId) {
    const { feedNotifications } = this.state;
    let count = 0;

    feedNotifications.forEach((result) => {
      if (result.activities && !result.is_read) {
        result.activities.forEach((activity) => {
          if (activity.verb === 'post' && activity.origin.includes(`${feedGroup}:${feedId}`)) {
            count++;
          }
        });
      }
    });

    return count;
  }

  chatWithTrustedPool() {
    const { history } = this.props;
    SendBirdUser.GetTrustedPoolChannel(this.state.profile, (channel) => {
      if (channel) {
        let location = {
          pathname: `${ROUTES.CHAT}`,
          state: {
            userId: this.state.profile.id,
            channelUrl: channel.url,
            channelType: channel.channelType,
            title: 'Chat with Your Village',
          },
        };
        history.push(location);
      } else {
        toast.error('Error', "Sorry, we couldn't open the chat. Please try later.");
      }
    });
  }

  launchMessageCenter() {
    this.props.history.push({
      pathname: `${ROUTES.MESSAGE_CENTER}`,
    });
  }

  onFindParentsInNeighborhood() {
    let location = {
      pathname: `${ROUTES.FIND_PARENTS}`,
      state: { filterByDistance: true, title: 'Your Neighbors' },
    };
    this.props.history.push(location);
  }

  onViewAllCommunities() {
    const { partnerData, schoolsData, activitiesData } = this.state;

    let location = {
      pathname: `${ROUTES.SHOW_ALL_COMMUNTIES}`,
      state: {
        partners: partnerData.partners,
        schools: schoolsData.schools,
        activities: activitiesData.activities,
      },
    };
    this.props.history.push(location);
  }

  onViewCommunity(entityType, entity, pathname, partnerInUserProfile) {
    let location = {
      pathname,
      state: {
        [entityType]: entity,
        partnerInUserProfile,
      },
    };
    this.props.history.push(location);
  }

  onShareUjamaJoy = () => {
    this.generateInvitationLink()
      .then(() => {
        this.shareModal.current.shareLink(this.state.invitationURL, 'Invitation to join Ujama');
      })
      .catch((error) => {
        toast.error(
          'Sorry, we were unable to generate the invitation link. Please try again later.\n\n' +
            error,
        );
      });
  };

  generateInvitationLink() {
    return new Promise((resolve, reject) => {
      const { profile } = this.state;

      makeDeepLink(profile.id, 'main', {})
        .then((url) => {
          console.log('URL--->', url);
          this.setState({ invitationURL: url }, () => resolve());
        })
        .catch((error) => {
          console.warn('Unable to get branch link', error);
          reject(error);
        });
    });
  }

  render() {
    return (
      <div className="dashboard">
        {this.renderInstallMobileDialog()}
        {this.renderScreen()}
        <ShareModal ref={this.shareModal} />
        <div id="sb_widget"></div>
      </div>
    );
  }

  renderInstallMobileDialog() {
    const actions = [
      <Button
        color="primary"
        key="install_mobile_button_1"
        onClick={() => {
          this.setState({ showInstallMobileBanner: false });
          window.open('https://app.ujama.co/mobile');
        }}
      >
        Get the app
      </Button>,
      <Button
        key="install_mobile_button_2"
        color="secondary"
        onClick={() => this.setState({ showInstallMobileBanner: false })}
      >
        Later
      </Button>,
    ];

    return (
      <Dialog
        title="Congratulations for Signing Up!"
        actions={actions}
        modal={true}
        open={this.state.showInstallMobileBanner}
      >
        <ListItem>
          <img alt="Download the mobile app" src={MobileDownload} style={{ width: 150 }} />
          <div
            style={{
              marginLeft: 120,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <span>
              {
                'Now unlock all the benefits of Ujama by installing the mobile app to coordinate rides and manage your family schedule.'
              }
            </span>
          </div>
        </ListItem>
      </Dialog>
    );
  }

  renderScreen() {
    const { partnerData, schoolsData, activitiesData } = this.state;

    if (partnerData.loading || schoolsData.loading || activitiesData.loading) {
      return <CircularProgress />;
    }
    return (
      <section className="screen-holder">
        {this.renderCommunities()}
        {this.renderThingsToDo()}
        {this.renderNearYou()}
      </section>
    );
  }

  renderUnreadMessages() {
    const { unreadMessageCount } = this.state;

    if (unreadMessageCount) {
      var messageText = 'You have ' + unreadMessageCount + ' new ';
      messageText += unreadMessageCount === 1 ? 'message ' : 'messages ';
      messageText += 'in the Message Center';
      return (
        <div>
          <ListItem
            className="clickable"
            style={styles.listLeft}
            onClick={this.launchMessageCenter.bind(this)}
          >
            <Typography variant="h4">{messageText}</Typography>
            <ChevronRight />
          </ListItem>
        </div>
      );
    }
  }

  renderCommunities() {
    const fragments = [];

    fragments.push(this.renderPartners());
    fragments.push(this.renderSchoolsForCommunity());
    fragments.push(this.renderActivitiesForCommunity());
    fragments.push(this.renderNeighborhood());

    return (
      <section>
        <AppBar position="static" elevation={0}>
          <Toolbar>
            <Typography variant="h1">Your Communities</Typography>

            <section>
              <IconButton
                variant="contained"
                color="secondary"
                onClick={() => this.onViewAllCommunities()}
              >
                Show all
              </IconButton>
            </section>
          </Toolbar>
        </AppBar>
        <List className="show-scrollbar-mac" style={styles.horizontalList}>
          {fragments}
        </List>
      </section>
    );
  }

  pickDefaultImage(entityType) {
    switch (entityType) {
      case 'activity':
        return CardDefaultActivity;

      case 'school':
        return CardDefaultSchool;

      case 'partner':
        return CardDefaultPartner;

      default:
        return CardDefault;
    }
  }

  renderEntities(entityType, entities, pathName) {
    const { profile } = this.state;
    const userPartnerList = profile.partnerList;

    const fragments = [];

    if (entities.length) {
      entities.forEach((entity) => {
        const {
          carouselImage,
          id,
          chapters,
          orgId,
          carouselDescription,
          name,
          newPostCount,
        } = entity;

        let localChaperName = '';
        let partnerInUserProfile = '';

        if (entityType === 'partner') {
          partnerInUserProfile = userPartnerList[id];
          if (partnerInUserProfile === 'pending') {
            return;
          }
          const isChapter =
            typeof partnerInUserProfile === 'object' &&
            !!partnerInUserProfile.currentSubscribedChapterId &&
            !!chapters;

          if (isChapter) {
            const chapterId = partnerInUserProfile.currentSubscribedChapterId;
            localChaperName = chapters[chapterId]?.name;
          }
        }

        const chapterNameToDisplay = localChaperName ? ` - ${localChaperName}` : '';

        const cardImage = carouselImage || this.pickDefaultImage(entityType);
        fragments.push(
          <ListItem
            key={`communityProfile:${id || orgId}`}
            className="responsive-card"
            onClick={() => {
              this.onViewCommunity(entityType, entity, pathName, partnerInUserProfile);
            }}
          >
            <Card>
              <CardMedia
                image={cardImage}
                component="img"
                className={cardImage === this.pickDefaultImage(entityType) ? 'default' : ''}
                title={carouselDescription || name}
              />
              <Typography variant="h3">{`${name}${chapterNameToDisplay}`}</Typography>
              {newPostCount > 0 && (
                <Typography>{`${newPostCount} new post${newPostCount > 1 ? 's' : ''}`}</Typography>
              )}
            </Card>
          </ListItem>,
        );
      });
    }

    return fragments;
  }

  renderPartners() {
    const partners = this.state.partnerData.partners;

    return this.renderEntities('partner', partners, ROUTES.PARTNER_LANDING);
  }

  renderSchoolsForCommunity() {
    const schools = this.state.schoolsData.schools;

    return this.renderEntities('school', schools, ROUTES.SCHOOL_LANDING);
  }

  renderActivitiesForCommunity() {
    const activities = this.state.activitiesData.activities;

    return this.renderEntities('activity', activities, ROUTES.ACTIVITY_LANDING);
  }

  renderNeighborhood() {
    return [
      <ListItem
        key={`neighborhoodProfile`}
        className="responsive-card"
        onClick={() => {
          this.onViewCommunity('', {}, ROUTES.NEIGHBORHOOD_LANDING);
        }}
      >
        <Card style={styles.card}>
          <CardMedia image={CardNeighborhood} component="img" title="Your Neighborhood" />
          <Typography variant="h3">Your Neighborhood</Typography>
        </Card>
      </ListItem>,
    ];
  }

  renderThingsToDo() {}

  renderNearYou() {
    return (
      <div>
        <AppBar position="static" elevation={0}>
          <Toolbar>
            <Typography variant="h1">Near You</Typography>
          </Toolbar>
        </AppBar>
        <Grid container style={{ display: 'flex' }}>
          <Grid item xs={12} md={6}>
            <Card className="bordered rounded padded centered">
              <Neighbors style={{ height: 100 }} />
              <Typography variant="h3">Meet Your Neighbors</Typography>
              <Typography>View your directory of Ujama users who live near you</Typography>
              <IconButton
                style={styles.nearYouButton}
                color="primary"
                onClick={() => this.onFindParentsInNeighborhood()}
              >
                <Search /> View
              </IconButton>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card className="bordered rounded padded centered">
              <GiftGuy style={styles.nearYouImage} />
              <Typography variant="h3">Share the Ujama Joy</Typography>
              <Typography>Invite friends to join you on Ujama</Typography>
              <IconButton
                style={styles.nearYouButton}
                color="primary"
                onClick={() => this.onShareUjamaJoy()}
              >
                <Share /> Share
              </IconButton>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }

  renderBadge(entity) {
    if (entity.verified) {
      return (
        <Badge
          badgeContent={
            <IconButton tooltip="Verified Parent">
              <Check />
            </IconButton>
          }
          style={{ top: 15, right: 25, position: 'absolute' }}
          badgeStyle={{ backgroundColor: 'transparent' }}
        />
      );
    }
  }
}

const styles = {
  listLeft: {
    textAlign: 'left',
  },
  horizontalList: {
    width: '98%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    overflow: 'auto',
    scrollBehavior: 'smooth',
  },
  messageItem: {
    textAlign: 'center',
    width: '100%',
    padding: '15px',
  },
  profileLabel: {
    width: '100%',
    textAlign: 'center',
    color: '#6d6d72',
    fontSize: '12px',
    display: 'block',
    marginTop: 5,
  },
  imageLabel: {
    width: '100%',
    textAlign: 'center',
    color: 'white',
    fontSize: '12px',
  },
  cardContent: {
    marginTop: '-40px',
  },
  floatingNav: {
    position: 'absolute',
    top: 64,
    right: 0,
  },
  nearYouImage: {
    height: 100,
  },
  nearYouButton: {
    marginTop: 10,
    height: 40,
  },
};

export default Dashboard;
