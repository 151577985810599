import React, { useRef } from 'react';
import { AppBar, Toolbar, Grid, useMediaQuery } from '@material-ui/core';

import Logo from './common/logo/logo';
import SidebarDownload from './common/sidebar/sidebar-download';
import NavBarTabs from './common/navigation/tabs';
import NavBarRight from './common/navigation/right';
import Footer from './common/footer';
import '../ujama.css';

export const Layout = (props) => {
  const isMobile = !useMediaQuery('(min-width:600px)');
  const topAnchor = useRef(null);
  const onTabChanged = (tab) => {
    if (topAnchor.current) {
      topAnchor.current.scrollIntoView({ behavior: 'auto' });
    }
  };
  return (
    <div className={`screen-content ${isMobile ? 'mobile' : ''}`}>
      {/* anchor to start scroll at top*/}
      <div id="top-anchor" ref={topAnchor}></div>
      <AppBar position="sticky" id="main-appbar">
        <Toolbar style={styles.wrap}>
          <Logo />
          {
            // in desktop, show tab navigation in top bar
            !isMobile && (
              <section>
                <NavBarTabs onTabChanged={onTabChanged} />
              </section>
            )
          }
          <NavBarRight />
        </Toolbar>
      </AppBar>

      <Grid container id="main-layout">
        <Grid item md={2}></Grid>
        <Grid item xs={12} md={8}>
          <div id="main-content">{props.children}</div>
        </Grid>
        <Grid item xs={12} md={2} id="main-sidebar">
          <Grid item center="xs">
            <SidebarDownload />
          </Grid>
        </Grid>
      </Grid>
      {
        // in mobile, show tab navigation at the bottom
        isMobile && (
          <AppBar position="fixed" id="main-appbar" className="mobile">
            <Toolbar style={styles.wrap}>
              <NavBarTabs onTabChanged={onTabChanged} isMobile={true} />
            </Toolbar>
          </AppBar>
        )
      }
      <Footer isMobile={isMobile} />
    </div>
  );
};

const styles = {
  wrap: {
    maxWidth: '90%',
    margin: '0 5%',
  },
};

export default Layout;
