import FirebaseManager from '../firebase/firebase-manager';

export default class SendBirdStorage {
  static ReadAppInfo(callback) {
    const firebaseManager = new FirebaseManager();
    firebaseManager.readOnce('sendBird/', (snap) => {
      if (snap) {
        callback(snap.val());
      }
    });
  }
}
