import { useState, useEffect } from 'react';
import SendBirdUser from './sendbird-user';

// wait for sendbird initialization before rendering any children
const SendBirdWrapper = (props) => {
  const [init, setInit] = useState(false);

  useEffect(() => {
    SendBirdUser.Initialize(() => {
      setInit(true);

      return () => {
        SendBirdUser.Terminate();
      };
    });
  }, []);

  return init ? props.children : null;
};

export default SendBirdWrapper;
