import FirebaseManager from '../firebase/firebase-manager';
import ProfileStorage from './profile';

export default class KarmaPointsStorage {
  static pointSettings = {
    joining: 20,
    inviting: 5,
    ride: 5,
    sitting: 5,
    playdate: 1,
    minimumForRequest: 5,
  };

  static Read(userID, callback) {
    const firebaseManager = new FirebaseManager();

    firebaseManager.readOnce('profileKarmaPoints/' + userID, (snap) => {
      let karmaPoints = snap.exists() ? snap.val() : null;
      if (callback) {
        callback(karmaPoints);
      }
    });
  }

  static ReadBalance(userID, callback) {
    const firebaseManager = new FirebaseManager();

    firebaseManager.readOnce('profileKarmaPoints/' + userID, (snap) => {
      var balance = 0;
      let karmaPoints = snap.exists() ? snap.val() : null;
      if (karmaPoints && karmaPoints.balance) {
        balance = karmaPoints.balance;
      }
      if (callback) {
        callback(balance);
      }
    });
  }

  static ReadHistory(userID, callback) {
    const firebaseManager = new FirebaseManager();
    const historyList = [];

    firebaseManager.readOnce('profileKarmaPoints/' + userID + '/history', (snap) => {
      snap.forEach((child) => {
        historyList.push(child.val());
      });

      if (callback) {
        callback(historyList);
      }
    });
  }

  static ReadPointsSettings(callback) {
    const firebaseManager = new FirebaseManager();

    firebaseManager.readOnce('karmaPoints/pointSettings/', (snap) => {
      if (snap && snap.exists()) {
        KarmaPointsStorage.pointSettings = snap.val();
        if (callback) {
          callback(KarmaPointsStorage.pointSettings);
        }
      } else {
        console.warn('Unable to read Upendo Points Point Settings.');
        if (callback) {
          callback(null);
        }
      }
    });
  }

  static ReadAwardTiers(callback) {
    const firebaseManager = new FirebaseManager();

    firebaseManager.readOnce('karmaPoints/awardTiers', (snap) => {
      callback(snap.val());
    });
  }

  static updateCampaigns(userID) {
    const firebaseManager = new FirebaseManager();

    firebaseManager.create('profileKarmaPoints/' + userID + '/campaigns/holidayEmail2019', true);
  }

  static holidayEmail2019(userID) {
    const firebaseManager = new FirebaseManager();
    const today = new Date();
    firebaseManager.readOnce('profileKarmaPoints/' + userID + '/campaigns', (snap) => {
      let karmaPoints = snap.exists() ? snap.val() : null;
      if (
        karmaPoints === null ||
        karmaPoints === undefined ||
        karmaPoints.holidayEmail2019 === false
      ) {
        console.log('NOT CLAIMED');
        this.AddEvent(userID, {
          date: today.toLocaleDateString(),
          event: 'You were granted bonus points by Ujama ',
          points: 15,
        });
        this.updateCampaigns(userID);
      } else if (karmaPoints !== null || karmaPoints !== undefined) {
        let KPval = karmaPoints.holidayEmail2019;
        if (KPval === true) {
          console.log('ALREADY CLAIMED');
        }
      }
    });
  }

  static AddEvent(userID, eventData) {
    return new Promise((resolve, reject) => {
      const firebaseManager = new FirebaseManager();

      KarmaPointsStorage.ReadBalance(userID, (balance) => {
        const newBalance = balance + eventData.points;

        ProfileStorage.Update(userID, { karmaPoints: { balance: newBalance } });
        firebaseManager
          .update('profileKarmaPoints/' + userID, { balance: newBalance })
          .then(() =>
            firebaseManager.saveData('profileKarmaPoints/' + userID + '/history', eventData),
          )
          .then(() => resolve());
      });
    });
  }

  static CalculatePointsForJoining() {
    return KarmaPointsStorage.pointSettings.joining;
  }

  static CalculatePointsForInviting() {
    return KarmaPointsStorage.pointSettings.inviting;
  }

  static CalculatePointsForRide(ride) {
    // TODO: Calculate points based on distance (time?) and number of kids driven
    return KarmaPointsStorage.pointSettings.ride;
  }

  static CalculatePointsForSitting(activity) {
    return KarmaPointsStorage.pointSettings.sitting;
  }

  static PointsForPlaydate() {
    return KarmaPointsStorage.pointSettings.playdate;
  }

  static MinimumPointsForRequest() {
    return KarmaPointsStorage.pointSettings.minimumForRequest;
  }

  static HasEnoughBalance(userID, points, callback) {
    KarmaPointsStorage.ReadBalance(userID, (balance) => {
      callback(balance >= points);
    });
  }

  static ReadStripePublicKey(env, callback) {
    const firebaseManager = new FirebaseManager();

    firebaseManager.readOnce('karmaPoints/tokens/' + env + '/publishable_key', (snap) => {
      const publicKey = snap && snap.exists() ? snap.val() : null;
      callback(publicKey);
    });
  }

  static ReadStripeClientID(env, callback) {
    const firebaseManager = new FirebaseManager();

    firebaseManager.readOnce('karmaPoints/tokens/' + env + '/client_id', (snap) => {
      const clientID = snap && snap.exists() ? snap.val() : null;
      callback(clientID);
    });
  }

  static ReadStripeMaxCollect(env, callback) {
    const firebaseManager = new FirebaseManager();

    firebaseManager.readOnce('karmaPoints/tokens/' + env + '/maxCollect', (snap) => {
      const maxCollect = snap && snap.exists() ? snap.val() : null;
      callback(maxCollect);
    });
  }

  static ReadStripeMaxCharge(env, callback) {
    const firebaseManager = new FirebaseManager();

    firebaseManager.readOnce('karmaPoints/tokens/' + env + '/maxCharge', (snap) => {
      const maxCharge = snap && snap.exists() ? snap.val() : null;
      callback(maxCharge);
    });
  }

  static ReadStripeFeeTable(callback) {
    const firebaseManager = new FirebaseManager();

    firebaseManager.readOnce('karmaPoints/feeTable', (snap) => {
      const feeTable = snap && snap.exists() ? snap.val() : null;
      callback(feeTable);
    });
  }
}
