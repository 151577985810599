import { useEffect } from 'react';
import { firebase } from '../api/firebase/firebase';
const AuthGuard = (props) => {
  //console.log(localStorage.profile);
  const { currentUser, setCurrentUser } = props;
  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      //console.log("onAuthStateChanged", currentUser, user);
      const badUser = user && currentUser && user.uid !== currentUser.uid;
      if (!user || badUser) {
        if (
          window.location.pathname !== '/logout' &&
          window.location.pathname !== '/create-profile'
        ) {
          const reason = badUser ? 'baduser' : 'noauth';
          console.warn('Session Expired...', reason);
          window.location.href = '/logout';
        }
      }

      setCurrentUser(user);
    });
  }, [currentUser, setCurrentUser]);

  return currentUser ? props.children : null;
};

export default AuthGuard;
