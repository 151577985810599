import { createMemoryHistory } from 'history';
import { toast } from 'react-toastify';

/*
**** WARNING ****

There are pending refactors in most of the application flows involving the usage of callback
functions sent through state, which break while using the BrowserRouter or BrowserHistory
having non-serializable data for the browser's history (i.e.: when doing history.push(),
history.replace(), history.goBack() and others.

**We are defering this big refactor until a stable version of Ujama v4 is released.**

NOTE:
The best approach would probably be to move all the state management into a new Redux
implementation instead and removing all usage of callback functions in the state, and all
this  customization should probably be removed and switch back to using BrowserRouter or at
least the BrowserHistory and let React handle client-side routing and browser interaction.
*/
import { isSerializable } from './utils/history';

const history = createMemoryHistory({
  initialEntries: [document.location],
  initialIndex: 0,
});

// Ok, now we can use this MemoryHistory, with regular Router and still allows passing
// functions in the state just like react-router v3.0.1 did.
// But doing this comes with a downside, we need to manually connect our React history
// with the browser buttons.

// We use these global variables used to keep track of history changes
window.__historyEntries = [history.location.key];
window.__prevKey = history.location.key;
window.__historySync = false;
window.__historySyncBrowser = false;

// Turn debug mode on/off
const DEBUG_CUSTOM_HISTORY = process.env.REACT_APP_DEBUG_HISTORY === 'true';
const debug = DEBUG_CUSTOM_HISTORY ? console.log.bind(window.console) : () => {};
const showErrorAndFallBackToHomepage = () => {
  toast.warn(
    'Using browser navigation is not fully supported and may break the application flow. Redirecting...',
  );

  setTimeout(() => {
    window.location.href = '/';
  }, 5000);
};

// We first listen on every change of location:
history.listen((location, action) => {
  const { state = {}, pathname, key } = location;
  debug('------------------------------------- REACT history listen', action);
  debug(key, window.__prevKey, window.__historyEntries);

  // Always save last key reference
  const lastKey = window.__prevKey;
  window.__prevKey = key;

  // Flag used just to keep the browser and the React in sync and prevent double execution
  if (window.__historySync) {
    debug('Blocked for React Router sync');
    window.__historySync = false;
    return;
  }

  // If a goBack is called after a refresh, redirect home
  if (action === 'POP' && history.entries.length <= 1) {
    showErrorAndFallBackToHomepage();
  }

  // - Check if current state object is serializable by the browser
  if (!isSerializable(state)) {
    localStorage.setItem('__historyStateIsNonSerializable', 'yes');
    debug('NONSERIALIZABLE state object provided');
    console.warn('NONSERIALIZABLE state object provided', state);
    if (process.env.REACT_APP_DEBUG_HISTORY === 'true') {
      toast.warn('Navigation: NONSERIALIZABLE state object provided');
    }
  } else {
    localStorage.setItem('__historyStateIsNonSerializable', 'no');
  }
  const validBrowserState = isSerializable(state)
    ? state
    : {
        // NOTE ABOUT REDUCED UX: this should reset the state in some flows when the user refreshes or
        // uses the back and forward buttons when navigation is still using non-serializable state.
        __stateNonSerializable: true,
      };

  // Inject an incremental key to each of the browser's history entries to identify navigation
  // direction when using back and forward buttons.
  validBrowserState.__navKey = key;
  debug(pathname, validBrowserState);

  // Put Browser history in sync when user navigates the app
  if (!window.__historyEntries.includes(key)) {
    debug('new');
    if (action === 'PUSH') {
      window.__historyEntries.push(key);
    }
    window.history[action === 'PUSH' ? 'pushState' : 'replaceState'](
      validBrowserState,
      '',
      pathname,
    );
  } else {
    if (window.__historyEntries.indexOf(key) >= window.__historyEntries.indexOf(lastKey)) {
      debug('goForward');
      window.__historySyncBrowser = true;
      window.history.go(1);
    } else {
      debug('goBack');
      window.__historySyncBrowser = true;
      window.history.go(-1);
    }
  }
});

// Put React Router history in sync when user clicks the browser buttons
window.onpopstate = (event) => {
  debug('-------------------- BROWSER onpopstate');
  if (window.__historySyncBrowser) {
    debug('--- Blocked for Browser sync');
    window.__historySyncBrowser = false;
    return;
  }
  //const currentLocation = window.location;
  //const state = currentLocation.state ? currentLocation.state : {};
  const { state } = event;
  debug(window.location.pathname, state);
  if (!state) {
    debug('no state');
    window.__historySync = true;
    history.goBack();
    return;
  }
  const { __navKey: key } = event.state || {};
  if (!key) {
    debug('no key');
    return;
  }

  debug(key, window.__prevKey, window.__historyEntries);
  if (!window.__historyEntries.includes(key)) {
    debug('--- notfound');
    window.__historySync = true;
    history.goBack();
  } else if (
    window.__historyEntries.indexOf(key) >= window.__historyEntries.indexOf(window.__prevKey)
  ) {
    debug('--- goForward');
    window.__historySync = true;
    history.goForward();
  } else {
    debug('--- goBack');
    window.__historySync = true;
    history.goBack();
  }
};

// refresh warn when refreshing on a screen with non-serializable state
if (window.performance) {
  if (
    localStorage.getItem('__historyStateIsNonSerializable') === 'yes' &&
    window.performance.navigation.type === 1
  ) {
    showErrorAndFallBackToHomepage();
  }
}
export default history;
