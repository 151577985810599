import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { InputAdornment, TextField } from '@material-ui/core';

import { Close } from '@material-ui/icons';

class CommonSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasFocus: false,
    };
  }

  render() {
    const { handleSearchFocus, handleSearchClosed, onChangeText, searchText } = this.props;
    const { hasFocus } = this.state;

    return (
      <div>
        <TextField
          style={{ width: '80%', marginLeft: 10 }}
          type="text"
          value={searchText}
          label="Search..."
          onChange={(event) => onChangeText(event.target.value)}
          onFocus={() => {
            this.setState({ hasFocus: true });
            handleSearchFocus();
          }}
          InputProps={{
            endAdornment: hasFocus && (
              <InputAdornment
                position="end"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  this.setState({ hasFocus: false });
                  handleSearchClosed();
                }}
              >
                <Close />
              </InputAdornment>
            ),
          }}
        />
      </div>
    );
  }
}

export default CommonSearch;

CommonSearch.propTypes = {
  searchText: PropTypes.string.isRequired,
  onChangeText: PropTypes.func.isRequired,
  handleSearchFocus: PropTypes.func,
};

const styles = {
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingHorizontal: 12,
    flexDirection: 'row',
    width: '100%',
  },
};
