import React from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import {
  AppBar,
  Avatar,
  Card,
  CardHeader,
  Divider,
  IconButton,
  List,
  ListItem,
  Tabs,
  Tab,
  CardMedia,
  CardContent,
  Typography,
  Toolbar,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import ROUTES from '../../../utils/consts';

import { ReactComponent as NewChat } from '../../../assets/icons/new-chat.svg';
import ProfileDefault from '../../../assets/images/profile-default.png';
import { TabPanel } from '../../common/material-wrap/tab-panel';
import AuthHelper from '../../../api/helpers/auth';
import ProfileHelper from '../../../api/helpers/profile';
import SendBirdUser from '../../../api/sendbird/sendbird-user';
import CommonSearch from '../../common/elements/common-search';
import StateMachine from '../../../api/helpers/state-machine';
import HappyParent from '../../../assets/images/happy-parent.jpg';
import HappyFamily from '../../../assets/images/happy-family.jpg';

import ChatSearch from './chat-search';

const chatDispatch = {
  Direct: {
    emptyMessage: 'You have no direct (1-on-1) messages',
    detailedMessage:
      "Ujama makes it easy to communicate with other parents in your neighborhood and at your kids' schools and after-school activities.",
    actionMessage: 'Start a Direct New Chat',
    splash: HappyParent,
    action: () =>
      MessageCenter.NewChat({
        doNotDisplaySchoolBoards: true,
        doNotDisplayActivityBoards: true,
        doNotDisplayGroups: true,
      }),
  },

  Group: {
    emptyMessage: 'You have no group messages',
    detailedMessage:
      "Ujama makes it easy to communicate with groups of parents in your neighborhood and at your kids' schools and after-school activities.",
    actionMessage: 'Start a New Group Chat',
    splash: HappyFamily,
    action: () =>
      MessageCenter.NewChat({
        doNotDisplaySchoolBoards: true,
        doNotDisplayActivityBoards: true,
        doNotDisplayDirect: true,
      }),
  },
};

class MessageCenter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: null,
      pic: ProfileDefault,
      schoolsData: {
        schools: [],
        isLoading: true,
      },
      activitiesData: {
        activities: [],
        isLoading: true,
      },
      channels: [],
      directMessages: [],
      groupMessages: [],
      schoolBoards: [],
      activityBoards: [],
      unreadDirectMessages: 0,
      unreadGroupMessages: 0,
      isLoading: true,
      tab: 0,
      searchText: '',
      searchFocused: false,
    };
  }

  componentDidMount() {
    const savedState = StateMachine.read(this);
    if (savedState) {
      this.setState({ tab: savedState.tab });
    }

    this.readData(this.props);
  }

  componentWillUnmount() {
    // When a user comes back to a landing page, we want to return to the tab we left
    StateMachine.save(this);
  }

  readData() {
    const { history } = this.props;
    AuthHelper.getProfileDetails()
      .then((profile) => {
        if (profile) {
          this.setState({
            pic: (profile && profile.pic) || ProfileDefault,
          });
          SendBirdUser.Connect(profile, () => {
            this.setState({
              profile: profile,
            });

            this.getChatChannels(profile.id);
            SendBirdUser.SetMessageReceivedHandler((channel, message) => {
              SendBirdUser.GetChannelsWithUnreadMessages(profile.id, (channelList) => {
                this.getChatChannels(profile.id);
              });
            });

            ProfileHelper.getKidsSchools(profile.id).then((schools) => {
              this.setState({
                schoolsData: {
                  schools: schools || [],
                  isLoading: false,
                },
              });
            });

            ProfileHelper.getKidsActivities(profile.id).then((activities) => {
              this.setState({
                activitiesData: {
                  activities: activities || [],
                  isLoading: false,
                },
              });
            });
          });
        } else {
          history.push('/login');
        }
      })
      .catch((error) => {
        console.error(error);
        toast.warn(
          'Sorry! Unable to get profile details at the moment! Please try again or contact our support team!',
        );
        history.push('/login');
      });
  }

  getChatChannels(userId) {
    return new Promise((resolve, reject) => {
      try {
        SendBirdUser.GetSubscribedChannels(userId, (channels) => {
          const directMessages = [];
          const groupMessages = [];
          let unreadDirectMessages = 0;
          let unreadGroupMessages = 0;
          channels.forEach((channel) => {
            if (channel.lastMessage) {
              const lastMessageDate = new Date(channel.lastMessage.createdAt);
              channel.when = moment(lastMessageDate).fromNow();
            } else {
              channel.when = '';
            }
            if (channel.customType === 'Direct-Message') {
              directMessages.push(channel);
              if (channel.unreadMessageCount) {
                unreadDirectMessages++;
              }
            } else if (
              channel.customType === 'Selected-Friends' ||
              channel.customType === 'Trusted-Pool' ||
              channel.customType === 'Marketplace' ||
              channel.customType === 'Meetup' ||
              channel.customType === 'Webinar'
            ) {
              groupMessages.push(channel);
              if (channel.unreadMessageCount) {
                unreadGroupMessages++;
              }
            } else if (channel.customType === 'Organization' || channel.customType === 'School') {
              console.warn("We're no longer using group channels for schools or organizations");
              groupMessages.push(channel);
              if (channel.unreadMessageCount) {
                unreadGroupMessages++;
              }
            } else {
              console.warn('Channel with unknown custom type', channel);
            }
          });
          this.setState({
            isLoading: false,
            channels: channels,
            directMessages: directMessages,
            groupMessages: groupMessages,
            unreadDirectMessages: unreadDirectMessages,
            unreadGroupMessages: unreadGroupMessages,
          });

          resolve();
        });
      } catch (e) {
        console.error(e);
        reject(e);
      }
    });
  }

  getUnreadChatChannels(channels) {
    if (channels.length) {
      const unreadChannelList = SendBirdUser.GetChannelsWithUnreadMessages(channels);
      this.setNumberOfNewMessages(unreadChannelList.length);
      return unreadChannelList;
    } else {
      return channels;
    }
  }

  launchMessageBoard(channel) {
    const location = {
      pathname: ROUTES.CHAT,
      state: {
        channelUrl: channel.url,
      },
    };

    this.props.history.push(location);
  }

  handleSearchFocus = () => {
    this.setState({ searchFocused: true });
  };

  handleSearchClosed = () => {
    this.setState({ searchFocused: false, searchText: '' });
  };

  render() {
    const { history } = this.props;
    return (
      <div className="messages">
        <AppBar position="sticky" style={{ top: 64 }}>
          <Toolbar>
            <Typography variant="h1">Messages</Typography>
            <section>
              <IconButton
                onClick={() => {
                  history.push(ROUTES.NEW_CHAT);
                }}
                color="secondary"
              >
                New chat
                <NewChat style={{ marginLeft: 10 }} />
              </IconButton>
            </section>
          </Toolbar>
        </AppBar>
        {this.renderSearch()}
        {this.renderTabs()}
        {this.renderSearchResults()}
      </div>
    );
  }

  renderSearch() {
    const { searchText, searchFocused } = this.state;

    return (
      <CommonSearch
        searchText={searchText}
        searchFocused={searchFocused}
        onChangeText={(searchText) => this.setState({ searchText })}
        handleSearchFocus={this.handleSearchFocus}
        handleSearchClosed={this.handleSearchClosed}
      />
    );
  }

  renderSearchResults() {
    const { searchFocused, searchText, profile } = this.state;

    if (searchFocused) {
      return (
        <div style={{ marginTop: 10 }}>
          <ChatSearch
            searchText={searchText}
            profile={profile}
            handleSearchClosed={this.handleSearchClosed}
            history={this.props.history}
          />
        </div>
      );
    }
  }

  renderTabs() {
    const { searchFocused } = this.state;

    if (!searchFocused) {
      const directHeading =
        'Direct' +
        (this.state.unreadDirectMessages ? '\n(' + this.state.unreadDirectMessages + ' new)' : '');
      const groupHeading =
        'Group' +
        (this.state.unreadGroupMessages ? '\n(' + this.state.unreadGroupMessages + ' new)' : '');

      return (
        <div>
          <Tabs
            value={this.state.tab}
            onChange={(e) => {
              this.setState({
                tab: parseInt(e.currentTarget.getAttribute('index'), 10),
              });
            }}
          >
            <Tab
              component="a"
              onClick={(event) => {
                event.preventDefault();
              }}
              style={styles.tab}
              label="Direct"
              icon={this.state.unreadDirectMessages ? <span className="unreadBubble"></span> : null}
              index={0}
            />
            <Tab
              component="a"
              onClick={(event) => {
                event.preventDefault();
              }}
              style={styles.tab}
              label="Groups"
              icon={this.state.unreadGroupMessages ? <span className="unreadBubble"></span> : null}
              index={1}
            />
          </Tabs>
          {this.state.isLoading ? (
            <CircularProgress />
          ) : (
            <>
              <TabPanel value={this.state.tab} index={0}>
                {this.renderMessages(directHeading)}
              </TabPanel>
              <TabPanel value={this.state.tab} index={1}>
                {this.renderGroups(groupHeading)}
              </TabPanel>
            </>
          )}
        </div>
      );
    }
  }

  renderGroups(heading) {
    return (
      <div>
        <Card>
          <List style={{ textAlign: 'left' }} className="clickable-list-items">
            {this.renderGroupMessageList(heading)}
          </List>
        </Card>
      </div>
    );
  }

  renderGroupMessageList(heading) {
    const messages = this.state.groupMessages;

    if (messages.length === 0) {
      return <div>{this.renderNoMessages(heading)}</div>;
    } else if (messages.length !== 0) {
      const fragments = [];
      this.state.channels.forEach((channel, index) => {
        if (channel.lastMessage) {
          const messageText =
            channel.lastMessage.messageType === 'user' ? channel.lastMessage.message : '';
          if (
            channel.channelType === 'group' &&
            (channel.customType === 'Selected-Friends' || channel.customType === 'Trusted-Pool')
          ) {
            fragments.push(
              <div key={`chat_channel_${index}`}>
                <ListItem
                  style={channel.unreadMessageCount ? styles.listItemUnread : styles.listItem}
                  onClick={() => this.launchMessageBoard(channel)}
                >
                  <Grid container>
                    <Grid item xs={2} lg={1} style={{ alignItems: 'flex-end' }}>
                      <Avatar src={channel.coverUrl} />
                    </Grid>
                    <Grid item xs={8} lg={9}>
                      <Typography style={{ fontSize: 20 }}>{channel.name}</Typography>
                      <Typography
                        color="textSecondary"
                        style={{
                          marginTop: 7,
                        }}
                      >
                        {messageText.substring(0, 100)}
                        {messageText.length > 100 ? '...' : null}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: 'right' }}>
                      <Typography variant="caption" color="textSecondary">
                        {channel.when}
                        <br />
                      </Typography>
                      <Typography>
                        {channel.unreadMessageCount ? (
                          <span className="unreadMessageCount">{channel.unreadMessageCount}</span>
                        ) : null}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItem>
                <Divider />
              </div>,
            );
          }
        }
      });

      return fragments;
    }
  }

  renderMessages(heading) {
    return (
      <div>
        <Card>
          <List style={{ textAlign: 'left' }} className="clickable-list-items">
            {this.renderMessageList(heading)}
          </List>
        </Card>
      </div>
    );
  }

  renderMessageList(heading) {
    const { profile, channels, directMessages } = this.state;

    if (directMessages.length === 0 && channels.customType !== 'Direct-Message') {
      return <div>{this.renderNoMessages(heading)}</div>;
    } else {
      const fragments = [];

      directMessages.forEach((channel, index) => {
        const lastMessageText = SendBirdUser.GetLastMessageText(channel);

        fragments.push(
          <div key={`chat_channel_${index}`}>
            <ListItem
              style={channel.unreadMessageCount ? styles.listItemUnread : styles.listItem}
              onClick={() => this.launchMessageBoard(channel)}
            >
              <Grid container>
                <Grid item xs={2} lg={1} style={{ alignItems: 'flex-end' }}>
                  <Avatar src={SendBirdUser.GetChatImageSource(channel, profile.id)} />
                </Grid>
                <Grid item xs={8} lg={9}>
                  <Typography style={{ fontSize: 20 }}>
                    {SendBirdUser.GetChatTitle(channel, profile.id)}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    style={{
                      marginTop: 7,
                    }}
                  >
                    {lastMessageText.substring(0, 100)}
                    {lastMessageText.length > 100 ? '...' : null}
                  </Typography>
                </Grid>
                <Grid item xs={2} style={{ textAlign: 'right' }}>
                  <Typography variant="caption" color="textSecondary">
                    {channel.when}
                    <br />
                  </Typography>
                  <Typography>
                    {channel.unreadMessageCount ? (
                      <span className="unreadMessageCount">{channel.unreadMessageCount}</span>
                    ) : null}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
            <Divider />
          </div>,
        );
      });

      return fragments;
    }
  }

  renderNoMessages(heading) {
    return (
      <div style={styles.root}>
        <Card style={{ alignContent: 'center', padding: 10 }}>
          <CardHeader style={{ alignContent: 'center' }}>
            <p style={{ textAlign: 'center' }}>{chatDispatch[heading].emptyMessage}</p>
          </CardHeader>
          <CardMedia style={{ height: 500, objectFit: 'cover' }}>
            <img
              alt="No Messages"
              style={{ height: 500, objectFit: 'cover' }}
              src={chatDispatch[heading].splash}
            ></img>
          </CardMedia>
          <CardContent style={{ justifyContent: 'center' }}>
            <p style={{ textAlign: 'center' }}>{chatDispatch[heading].detailedMessage}</p>
          </CardContent>
        </Card>
      </div>
    );
  }
}

const styles = {
  root: {
    display: 'block',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    justifyContent: 'center',
  },
  media: {
    objectFit: 'cover',
  },
  cardHeader: {
    textAlign: 'left',
    fontWeight: 100,
  },
  listRight: {
    color: '#d3d3d3',
    width: '100%',
    textAlign: 'right',
    margin: '0px',
    top: '12px',
    paddingRight: '10px',
    verticalAlign: 'super',
  },
  listLeft: {
    textAlign: 'left',
  },
  listCenter: {
    textAlign: 'center',
  },
  horizontalList: {
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
    overflowX: 'scroll',
    overflowY: 'hidden',
  },
  messageItem: {
    textAlign: 'center',
    width: '100%',
    padding: '15px',
  },
  profileLabel: {
    width: '100%',
    textAlign: 'center',
    color: '#6d6d72',
    fontSize: '12px',
  },
  imageLabel: {
    width: '100%',
    textAlign: 'center',
    color: 'white',
    fontSize: '12px',
  },
  msgContainer: {
    height: 200,
    width: 200,
    margin: 0,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  listItem: {
    cursor: 'pointer',
    padding: '24px 14px',
  },
  listItemUnread: {
    cursor: 'pointer',
    padding: '24px 14px',
    background: 'rgba(0, 0, 0, 0.035)',
  },
  tab: {
    marginTop: 24,
    padding: '14px 64px',
  },
};

export default MessageCenter;
