import axios from 'axios';
import { FETCH_TIMEOUT } from '../utils/consts';
import ProfileHelper from './helpers/profile';
const ACTIVATE_API_LOGGING = process.env.REACT_APP_DEBUG_API;

export default class APIUtils {
  static get(url, token, authorization) {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
    if (token) {
      headers['Api-Token'] = token;
    }
    if (authorization) {
      headers['Authorization'] = authorization;
    }
    return axios
      .get(url, {
        headers: headers,
      })
      .then((response) => {
        if (response.status === 500) {
          console.warn('Get fetch failed', response);
          throw new Error('Get fetch failed (1)');
        } else {
          return response.data;
        }
      })
      .catch((error) => {
        console.warn('Get fetch failed (2)', error);
        return null;
      });
  }

  static post(url, data, token = null, authorization = null, contentType = 'application/json') {
    return APIUtils.action('post', url, data, token, authorization, contentType);
  }

  static put(url, data, token = null, authorization = null, contentType = 'application/json') {
    return APIUtils.action('put', url, data, token, authorization, contentType);
  }

  static action(
    method,
    url,
    data,
    token = null,
    authorization = null,
    contentType = 'application/json',
  ) {
    let headers = {
      'Content-Type': contentType,
    };
    if (token) {
      headers['Api-Token'] = token;
    }
    if (authorization) {
      headers['Authorization'] = authorization;
    }
    return new Promise((resolve, reject) => {
      axios({
        method: method,
        url: url,
        headers: headers,
        data: data,
      })
        .then((response) => {
          if (response.status === 500) {
            console.warn('Post error', response);
            reject(new Error('Post failed (1)'));
          } else {
            resolve(response.data);
          }
        })
        .catch((error) => {
          console.warn('Post error', error);
          reject(error);
        });
    });
  }

  static getCloud(url) {
    return new Promise((resolve, reject) => {
      ProfileHelper.GetFirebaseIdToken((idToken) => {
        APIUtils.get(url, null, 'Bearer ' + idToken)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    });
  }

  static postCloud(url, data) {
    return new Promise((resolve, reject) => {
      ProfileHelper.GetFirebaseIdToken((idToken) => {
        APIUtils.post(url, data, null, 'Bearer ' + idToken)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    });
  }

  static getCloudWithTimeOut(url, timeoutAmount = FETCH_TIMEOUT) {
    if (ACTIVATE_API_LOGGING) console.log('fetching data from:', url);
    return new Promise((resolve, reject) => {
      ProfileHelper.GetFirebaseIdToken((idToken) => {
        if (ACTIVATE_API_LOGGING) console.log('/// firebase idToken ///', idToken);
        let didTimeOut = false;
        const timeout = setTimeout(() => {
          didTimeOut = true;
          reject(new Error('Request timed out'));
        }, timeoutAmount);

        axios
          .get(url, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${idToken}`,
            },
          })
          .then((response) => {
            // Clear the timeout as cleanup
            clearTimeout(timeout);
            if (response.status === 500) {
              reject('Request had internal error');
            }
            if (!didTimeOut) {
              // console.log('data response', response.data);
              resolve(response.data);
            }
          })
          .catch((error) => {
            clearTimeout(timeout);
            console.warn('getCloudWithTimeOut fetch failed! ', JSON.stringify(error));
            if (didTimeOut) {
              return;
            } else {
              reject(error);
            }
          });
      });
    });
  }

  static postCloudWithTimeOut(url, body, timeoutAmount = FETCH_TIMEOUT) {
    if (ACTIVATE_API_LOGGING) console.log('posting data to:', url, '\n Body:', body);
    return new Promise((resolve, reject) => {
      ProfileHelper.GetFirebaseIdToken((idToken) => {
        let didTimeOut = false;
        const timeout = setTimeout(() => {
          didTimeOut = true;
          reject(new Error('Request timed out'));
        }, timeoutAmount);

        axios
          .post(url, body, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${idToken}`,
            },
          })
          .then((response) => {
            // Clear the timeout as cleanup
            clearTimeout(timeout);
            if (response.status === 500) {
              reject('Request had internal error');
            }
            if (!didTimeOut) {
              // console.log('post data response', response.data);
              resolve(response.data);
            }
          })
          .catch((error) => {
            clearTimeout(timeout);
            console.warn('postCloudWithTimeOut failed! ', JSON.stringify(error));
            if (didTimeOut) {
              return;
            } else {
              reject(error);
            }
          });
      });
    });
  }
}
