import React from 'react';
import { toast } from 'react-toastify';
import { AppBar, Avatar, CircularProgress, Grid, Slider, Typography } from '@material-ui/core';
import AuthHelper from '../../../api/helpers/auth';
import MarketplaceStorage from '../../../api/storage/marketplace';
import PartnerStorage from '../../../api/storage/partner';
import APIUtils from '../../../api/APIUtils';
import CommonSearch from '../../common/elements/common-search';
import ROUTES, { FETCH_TIMEOUT } from '../../../utils/consts';
import MarketplaceSearch from './marketplace-search';

const MARKET_DISTANCE_RADIUS = 100; // 60 mile radius

export default class Marketplace extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      profile: {},
      marketplaceList: [],
      markets: [],
      partnerMode: props.partnerMode ? 1 : 0,
      partnerList: props.partnerList || null,
      hidePartnerModeSwitch: props.hidePartnerModeSwitch,
      affiliationText: '',
      searchText: '',
      searchFocused: false,
      showWaiting: false,
    };
  }

  componentDidMount() {
    AuthHelper.getProfileDetails().then((profile) => {
      this.setState({ profile }, () => this.readData());
    });
  }

  readData() {
    return new Promise((resolve, reject) => {
      const { profile, partnerList } = this.state;
      const promises = [];

      this.setState({ showWaiting: true });

      if (!partnerList) {
        this.readPartners(profile);
      }

      promises.push(
        new Promise((resolve) => {
          MarketplaceStorage.ReadList((marketplaceList) => {
            this.setState({ marketplaceList });
            resolve();
          });
        }),
      );

      Promise.all(promises).then(() => {
        this.setState({ showWaiting: false });
        resolve();
      });
    });
  }

  readPartners(profile) {
    const partnerList = [];
    const promises = [];

    for (let key in profile.partnerList) {
      promises.push(
        new Promise((resolve) => {
          PartnerStorage.Read(key, (partner) => {
            if (partner && partner.enabled && partner.hasParentList) {
              partnerList.push(partner);
            }
            resolve();
          });
        }),
      );
    }

    Promise.all(promises).then(() => {
      this.setState({
        partnerList: partnerList,
        affiliationText: partnerList.length === 1 ? partnerList[0].name : 'your organizations',
      });
    });
  }

  // Not used until we support farmers markets again
  findMarkets() {
    return new Promise((resolve, reject) => {
      const { profile } = this.state;
      const markets = [];

      if (profile.homeCoords) {
        const url =
          ROUTES.URL_FIND_MARKETS +
          `?lat=${this.state.profile.homeCoords.latitude}&lng=${this.state.profile.homeCoords.longitude}&distanceRadius=${MARKET_DISTANCE_RADIUS}`;

        APIUtils.getCloudWithTimeOut(url, FETCH_TIMEOUT)
          .then((markets) => resolve(markets))
          .catch((error) => {
            toast.error(
              'Sorry, there was a problem retrieving markets near you. Please try again.\n\nError: ' +
                error.message,
            );
            resolve([]);
          });
      } else {
        resolve([]);
      }
    });
  }

  // Not used until we support farmers markets again
  findMyMarkets() {
    return new Promise((resolve, reject) => {
      const url = ROUTES.URL_FIND_MARKETS + `?vendor=${this.userId}`;

      APIUtils.getCloudWithTimeOut(url, FETCH_TIMEOUT)
        .then((myMarkets) => resolve(myMarkets))
        .catch((error) => {
          toast.error(
            'Sorry, there was a problem retrieving your markets. Please try again.\n\nError: ' +
              error.message,
          );
          resolve([]);
        });
    });
  }

  onClickMarketplace = (marketplace) => {
    const { isChapter, chapterId } = this.props;
    const { partnerMode, partnerList } = this.state;
    const location = {
      pathname: ROUTES.MARKETPLACE_VIEWER,
      state: {
        marketplace,
        partnerMode,
        partnerList,
        isChapter,
        chapterId,
      },
    };

    this.props.history.push(location);
  };

  handleSearchFocus = () => {
    this.setState({ searchFocused: true });
  };

  handleSearchClosed = () => {
    this.setState({ searchFocused: false, searchText: '' });
  };

  render() {
    const { embedded } = this.props;

    return (
      <div className="marketplace" style={{ paddingLeft: 20, paddingRight: 20 }}>
        {!embedded && (
          <AppBar position="static" elevation={0}>
            <Typography variant="h1">Marketplace</Typography>
          </AppBar>
        )}

        {this.renderSearch()}
        {this.renderSearchResults()}
        {this.renderSlider()}
        {this.renderMarketplaces()}
        {this.renderWaiting()}
      </div>
    );
  }

  renderSearch() {
    const { searchText, searchFocused } = this.state;

    return (
      <CommonSearch
        searchText={searchText}
        searchFocused={searchFocused}
        onChangeText={(searchText) => this.setState({ searchText })}
        handleSearchFocus={this.handleSearchFocus}
        handleSearchClosed={this.handleSearchClosed}
      />
    );
  }

  renderSearchResults() {
    const {
      searchFocused,
      searchText,
      profile,
      marketplaceList,
      markets,
      partnerMode,
      partnerList,
    } = this.state;
    const { isChapter, chapterId, history } = this.props;

    if (searchFocused) {
      return (
        <div style={{ marginTop: 10 }}>
          <MarketplaceSearch
            searchText={searchText}
            profile={profile}
            marketplaceList={marketplaceList}
            markets={markets}
            partnerMode={partnerMode}
            partnerList={partnerList}
            handleSearchClosed={this.handleSearchClosed}
            history={history}
            isChapter={isChapter}
            chapterId={chapterId}
          />
        </div>
      );
    }
  }

  renderSlider() {
    const { hidePartnerModeSwitch } = this.props;
    const { searchFocused, partnerMode } = this.state;

    if (!hidePartnerModeSwitch && !searchFocused) {
      return (
        <div style={{ padding: 20, margin: 20, backgroundColor: '#F2F4F4', borderRadius: 10 }}>
          <Grid container direction="row" justify="center" alignItems="center" spacing={4}>
            <Grid xs={3} item>
              <Typography variant="subtitle2">{'List sellers near you'}</Typography>
            </Grid>
            <Grid xs={3} item>
              <Slider
                value={partnerMode}
                min={0}
                max={1}
                step={1}
                onChange={(event, newValue) => {
                  this.setState({ partnerMode: newValue });
                }}
              />
            </Grid>
            <Grid xs={3} item>
              <Typography variant="subtitle2">{'List sellers in your communities'}</Typography>
            </Grid>
          </Grid>
        </div>
      );
    }
  }

  renderMarketplaces() {
    const { marketplaceList, searchFocused } = this.state;

    if (marketplaceList.length && !searchFocused) {
      const fragments = [];

      marketplaceList.forEach((marketplace) => {
        fragments.push(
          <Grid key={marketplace.id} item xs={6} sm={4}>
            {this.renderMarketplace(marketplace)}
          </Grid>,
        );
      });

      return (
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={6}
          style={{ padding: 10 }}
        >
          {fragments}
        </Grid>
      );
    }
  }

  renderMarketplace(marketplace) {
    return (
      <div className="marketplace-item" onClick={() => this.onClickMarketplace(marketplace)}>
        <Avatar style={{ height: 100, width: 100 }} src={marketplace.image} />
        <Typography variant="h4">{marketplace.name}</Typography>
        <Typography variant="body2" color="textSecondary">
          {marketplace.description}
        </Typography>
      </div>
    );
  }

  renderWaiting() {
    if (this.state.showWaiting) {
      return <CircularProgress />;
    }
  }
}
