import FirebaseManager from '../firebase/firebase-manager';

export default class ImageStorage {
  static Load(fireBaseLocation, callback) {
    const firebaseManager = new FirebaseManager();

    firebaseManager.getStorageUrl(fireBaseLocation).then((uri) => callback(uri));
  }

  static Update(fireBaseLocation, file, callback) {
    const firebaseManager = new FirebaseManager();

    firebaseManager.storeImageFromFile(fireBaseLocation, file, (fileURL) => {
      if (callback) {
        if (fileURL) {
          callback({ uri: fileURL, isStatic: true });
        } else {
          callback(null);
        }
      }
    });
  }
}
