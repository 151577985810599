import React from 'react';
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import AuthHelper from '../../../api/helpers/auth';
import SendBirdUser from '../../../api/sendbird/sendbird-user';
import SearchHelper from '../../../api/helpers/search';
import ProfileStorage from '../../../api/storage/profile';
import PartnerStorage from '../../../api/storage/partner';
import UjamaAppBar from '../../common/material-wrap/app-bar';
import NoAddress from '../../common/elements/no-address';
import ROUTES from '../../../utils/consts';

const MARKETPLACE_DISTANCE_RADIUS = 100; // 60 mile radius

export default class MarketplaceViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userId: null,
      profile: {},
      sellers: [],
      channels: [],
      hasPendingRequest: false,
      myMarketplaceChannel: null,
      isListed: false,
      showWaiting: false,
    };

    this.partnerList = [];
  }

  componentDidMount() {
    AuthHelper.getProfileDetails().then((profile) => {
      this.setState({ userId: profile.id }, () => this.readData());
    });
  }

  readData() {
    return new Promise((resolve) => {
      this.setState({ showWaiting: true });
      this.readProfile().then(() => {
        if (!this.state.profile.homeCoords) {
          // Can't see marketplace without home address
          this.setState({ showWaiting: false });
          resolve();
        } else {
          this.readMarketplace()
            .then(() => this.readPartnerList())
            .then(() => this.setState({ showWaiting: false }, () => resolve()));
        }
      });
    });
  }

  readMarketplace() {
    const {
      marketplace,
      partnerMode,
      partnerList,
      isChapter,
      chapterId,
    } = this.props.location.state;
    const { profile } = this.state;

    return new Promise((resolve) => {
      const channels = [];
      const promises = [];

      SearchHelper.FindParents(
        {
          marketplaceId: marketplace.id,
          filterByDistance: !!partnerMode,
          partnerList: partnerMode ? partnerList : undefined,
          chapterId: isChapter ? chapterId : undefined,
          coords: profile.homeCoords,
          userHomeAddress: profile.homeAddress,
          distanceRadius: MARKETPLACE_DISTANCE_RADIUS,
        },
        (sellers) => {
          if (sellers && sellers.length) {
            sellers.forEach((seller) => {
              if (seller && seller.isMarketplaceSeller && seller.marketplaceChannelURL) {
                if (seller.id === this.userId) {
                  this.setState({ isListed: true });
                }

                promises.push(
                  new Promise((resolve) => {
                    setTimeout(() => {
                      SendBirdUser.GetChannel(seller.marketplaceChannelURL, (channel) => {
                        if (channel) {
                          SendBirdUser.SetChannelTime(channel);
                          channel.seller = seller;
                          channels.push(channel);
                        } else {
                          console.warn(
                            'Channel not found!',
                            seller.id,
                            seller.marketplaceChannelURL,
                          );
                        }
                        resolve();
                      });
                    }, Math.random() * 500);
                  }),
                );
              }
            });

            Promise.all(promises).then(() => {
              const sortedChannels = this.sortMarketplace(channels);

              // Our last item is a button for people to add themselves
              sortedChannels.push({ isAddItem: true, url: 'none' });

              this.setState(
                {
                  sellers: sellers,
                  channels: sortedChannels,
                },
                () => resolve(),
              );
            });
          } else {
            this.setState(
              {
                sellers: sellers,
                channels: channels,
              },
              () => resolve(),
            );
          }
        },
      );
    });
  }

  sortMarketplace(channels) {
    return channels.sort((a, b) => {
      const aCount = a.memberCount;
      const bCount = b.memberCount;

      if (aCount > bCount) {
        return -1;
      }
      if (bCount > aCount) {
        return 1;
      }
      return 0;
    });
  }

  readProfile() {
    return new Promise((resolve) => {
      ProfileStorage.Read(this.state.userId, (profile) => {
        if (!profile.isMarketplaceSeller) {
          // The user may have requested to join a market and then later turned off that they are a seller
          this.setState({ hasPendingRequest: false });
        } else if (this.state.hasPendingRequest && profile.marketplaceChannelURL) {
          SendBirdUser.GetChannel(profile.marketplaceChannelURL, (channel) => {
            channel.hasPendingRequest = true;
            channel.seller = profile;
            this.setState({
              myMarketplaceChannel: channel,
            });
          });
        }
        this.setState({ profile: profile }, () => resolve());
      });
    });
  }

  readPartnerList() {
    return new Promise((resolve) => {
      PartnerStorage.ReadAll((partnerList) => {
        this.partnerList = [];

        // We only want to reference active partners that have associated directories
        // (the others are just message boards)
        partnerList.forEach((partner) => {
          if (partner.enabled && partner.hasParentList) {
            this.partnerList.push(partner);
          }
        });

        resolve();
      });
    });
  }

  moreInfo = () => {
    window.open('https://ujama.co/marketplace-faq.pdf', '_blank');
  };

  onClickChannel = (channel) => {
    SendBirdUser.BrowseGroupChannel(
      this.props.history,
      channel,
      this.state.userId,
      channel.seller.id,
    );
  };

  onAddToMarketplace = () => {
    const { marketplace } = this.props.location.state;
    const location = {
      pathname: ROUTES.MARKETPLACE_SETTINGS,
      state: {
        marketplace,
      },
    };

    this.props.history.push(location);
  };

  render() {
    const { marketplace } = this.props.location.state;

    return (
      <div>
        <UjamaAppBar title={marketplace.name} rightAction={this.moreInfo} rightActionText="Info" />
        {this.renderWaiting()}
        {this.renderChannels()}
      </div>
    );
  }

  renderChannels() {
    const { channels, showWaiting, profile } = this.state;

    if (channels.length) {
      const fragments = [];

      channels.forEach((channel) => {
        fragments.push(this.renderChannel(channel));
      });

      return <List>{fragments}</List>;
    } else if (!showWaiting) {
      if (!profile.homeCoords) {
        return (
          <NoAddress
            message="Ujama needs your home address to find sellers in your area"
            history={this.props.history}
          />
        );
      }

      return this.renderNoChannels();
    }
  }

  renderChannel(channel) {
    if (channel.isAddItem) {
      return this.renderAdd();
    } else {
      return (
        <ListItem key={channel.url} divider button onClick={() => this.onClickChannel(channel)}>
          <ListItemAvatar>
            <Avatar src={channel.coverUrl} style={{ height: 80, width: 80 }} />
          </ListItemAvatar>
          <div>
            <ListItemText
              inset
              primaryTypographyProps={{ variant: 'h4', style: { whiteSpace: 'normal' } }}
              secondaryTypographyProps={{ style: { whiteSpace: 'normal' } }}
              primary={SendBirdUser.GetChatTitle(channel, this.state.userId)}
              secondary={channel.seller?.marketplaceDescription}
            />
            {this.renderPartnerAffiliations(channel.seller)}
          </div>
          <ListItemSecondaryAction>
            <ChevronRight />
          </ListItemSecondaryAction>
        </ListItem>
      );
    }
  }

  renderPartnerAffiliations(profile) {
    if (profile.partnerList) {
      const fragments = [];

      for (let key in profile.partnerList) {
        const partner = profile.partnerList[key];
        const partnerData = this.partnerList.find((partnerData) => partnerData.id === key);

        if (partnerData && partnerData.logo) {
          fragments.push(
            <Tooltip
              title={`${profile.marketplaceBusinessName} is a member of ${partnerData.name}`}
            >
              <Avatar src={partnerData.logo} style={{ marginRight: 10, height: 50, width: 50 }} />
            </Tooltip>,
          );
        }
      }

      if (fragments.length) {
        return <div style={{ display: 'flex', marginLeft: 65 }}>{fragments}</div>;
      }
    }
  }

  renderNoChannels() {
    const { marketplace, partnerMode } = this.props.location.state;

    return (
      <Card variant="outlined" style={{ margin: 40 }}>
        <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography variant="h4">{`No providers for ${marketplace.name} found ${
            partnerMode ? 'in your community' : 'near you'
          }`}</Typography>
          <Avatar src={marketplace.image} />
          <Typography variant="body2" color="textSecondary">
            {'Our Ujama community is growing. Please check back again.'}
          </Typography>
        </CardContent>

        <CardActions style={{ display: 'flex', justifyContent: 'center' }}>
          <Button size="small" color="primary" variant="outlined" onClick={this.onAddToMarketplace}>
            {`I provide ${marketplace.name}`}
          </Button>
        </CardActions>
      </Card>
    );
  }

  renderAdd() {
    const { marketplace } = this.props.location.state;

    return (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
        <Button size="small" color="primary" variant="outlined" onClick={this.onAddToMarketplace}>
          {`I provide ${marketplace.name}`}
        </Button>
      </div>
    );
  }

  renderWaiting() {
    if (this.state.showWaiting) {
      return <CircularProgress />;
    }
  }
}
