import FirebaseManager from '../firebase/firebase-manager';
import KidStorage from './kid';

const updateListeners = [];

export default class ProfileStorage {
  static Read(profileID, callback) {
    const firebaseManager = new FirebaseManager();

    firebaseManager.readOnce('profiles/' + profileID, (snap) => {
      if (snap) {
        let profile = snap && snap.exists() ? snap.val() : null;
        if (profile && !profile.id) {
          profile.id = snap.key;
        }
        ProfileStorage.FixType(profile);
        if (callback) {
          callback(profile);
        }
      }
    });
  }

  static ReadDuplicates(profileID, callback) {
    const firebaseManager = new FirebaseManager();

    firebaseManager.readOnce('profiles/' + profileID, (snap) => {
      let objs = [];

      if (snap) {
        snap.forEach((child) => {
          let profile = child.val();
          if (profile !== null) {
            ProfileStorage.FixType(profile);
            objs.push(profile);
          }
        });
      }

      if (callback) {
        callback(objs);
      }
    });
  }

  static Delete(profileID) {
    const firebaseManager = new FirebaseManager();

    if (!profileID || !profileID[0]) {
      console.error('Invalid profileID', profileID);
    } else {
      firebaseManager.deleteValue('profiles/' + profileID);
    }
  }

  static FixType(profile) {
    // Fixup the type field in case it wasn't defined (legacy bug)
    if (profile && !profile.type && profile.mode) {
      profile.type = profile.mode === 'google' ? 'google' : 'me';
    }
  }

  static Add(profileID, profile) {
    const firebaseManager = new FirebaseManager();

    // We should NEVER save pic -- it bloats our profiles
    if (profile.pic) {
      profile.pic = null;
    }

    return firebaseManager.create('profiles/' + profileID, profile);
  }

  static Update(profileID, profile) {
    const firebaseManager = new FirebaseManager();

    const profilePic = profile.pic;
    // We should NEVER save pic -- it bloats our profiles
    profile.pic = null;

    const ret = firebaseManager.update('profiles/' + profileID, profile).then(() => {
      if (FirebaseManager.getUserProfileID() === profileID) {
        profile.pic = profilePic;
        updateListeners.forEach((listener) => {
          listener.callback(profile);
        });
      }
    });

    return ret;
  }

  static RegisterUpdateListener(callback) {
    const id = 'id-' + new Date().getTime();

    updateListeners.push({
      id,
      callback,
    });

    return id;
  }

  static UnregisterUpdateListener(id) {
    const index = updateListeners.findIndex((listener) => listener.id === id);
    if (index !== -1) {
      updateListeners.slice(index, 1);
    } else {
      console.warn('Attempted to unregister listener that could not be found');
    }
  }

  static ReadList(callback) {
    const firebaseManager = new FirebaseManager();
    var parentList = [];

    firebaseManager.readOnce('profiles', (snap) => {
      if (snap) {
        snap.forEach((child) => {
          let profile = child.val();
          if (profile !== null && profile.name) {
            if (!profile.id) {
              profile.id = child.key;
            }
            ProfileStorage.FixType(profile);
            parentList.push(profile);
          }
        });
      }

      callback(parentList);
    });
  }

  static ReadTrustedPoolList(profileID, callback) {
    const firebaseManager = new FirebaseManager();

    firebaseManager.readOnce('profiles/' + profileID + '/trustedPool', (snap) => {
      let trustedPoolList = [];

      if (snap) {
        snap.forEach((child) => {
          let profile = child.val();
          if (profile !== null) {
            ProfileStorage.FixType(profile);
            trustedPoolList.push(profile);
          }
        });
      }

      if (callback) {
        callback(trustedPoolList);
      }
    });
  }

  // Read the list of trusted profiles and include the friends of family members and family members of friends
  static ReadExpandedTrustedPoolList(profileID, callback) {
    ProfileStorage.ReadTrustedPoolList(profileID, (trustedPoolList) => {
      const additionalList = [];
      var readCount = 0;
      trustedPoolList.forEach((friend) => {
        readCount++;
        ProfileStorage.ReadTrustedPoolList(friend.id, (indirectList) => {
          indirectList.forEach((indirectFriend) => {
            if (indirectFriend.id !== profileID) {
              const index = trustedPoolList.findIndex((directFriend) => {
                return directFriend.id === indirectFriend.id;
              });
              if (
                index === -1 &&
                (friend.relationshipType === 'family' ||
                  indirectFriend.relationshipType === 'family')
              ) {
                indirectFriend.relationshipType = 'friends';
                additionalList.push(indirectFriend);
              }
            }
          });
          if (--readCount === 0) {
            trustedPoolList = trustedPoolList.concat(additionalList);
            callback(trustedPoolList);
          }
        });
      });
      if (readCount === 0) {
        callback(trustedPoolList);
      }
    });
  }

  static ReadAllTrustedPoolProfiles(parentObj, callback) {
    const firebaseManager = new FirebaseManager();
    const trustedFriends = [];
    let readCount = 0;
    const processProfile = (snap, friend) => {
      if (snap) {
        const trustedFriend = snap.val();
        if (trustedFriend) {
          ProfileStorage.FixType(trustedFriend);
          trustedFriend.relationshipType = friend.relationshipType;
          trustedFriends.push(trustedFriend);
        }
      }
      if (--readCount === 0) {
        callback(trustedFriends);
      }
    };
    if (parentObj && parentObj.trustedPool) {
      for (let key in parentObj.trustedPool) {
        const friend = parentObj.trustedPool[key];
        readCount++;
        firebaseManager.readOnce('profiles/' + friend.id, (snap) => processProfile(snap, friend));
      }
    }
    if (readCount === 0) {
      callback(trustedFriends);
    }
  }

  static ReadAllExpandedTrustedPoolProfiles(parentObj, callback) {
    const friendsList = [];
    ProfileStorage.ReadExpandedTrustedPoolList(parentObj.id, (trustedPoolList) => {
      let readCount = 0;
      trustedPoolList.forEach((friend) => {
        readCount++;
        ProfileStorage.Read(friend.id, (friendObj) => {
          if (friendObj) {
            friendObj.relationshipType = friend.relationshipType;
            friendsList.push(friendObj);
          }

          if (--readCount === 0) {
            callback(friendsList);
          }
        });
      });
      if (readCount === 0) {
        callback(friendsList);
      }
    });
  }

  static GetRelationshipType(parentObj, friendID) {
    let relationshipType = 'none';

    if (parentObj.trustedPool && parentObj.trustedPool[friendID]) {
      const friend = parentObj.trustedPool[friendID];
      relationshipType = friend.relationshipType;
    }

    return relationshipType;
  }

  static AddKidsToFamily(parentID, kids) {
    return new Promise((resolve, reject) => {
      ProfileStorage.AddKidsToProfile(parentID, kids).then(() => {
        ProfileStorage.ReadTrustedPoolList(parentID, (trustedPool) => {
          let requests = trustedPool.map((member) => {
            return new Promise((resolve) => {
              if (member.relationshipType === 'family') {
                ProfileStorage.AddKidsToProfile(member.id, kids).then(() => resolve());
              } else {
                resolve();
              }
            });
          });
          Promise.all(requests).then(() => resolve());
        });
      });
    });
  }

  static AddKidsToProfile(parentID, kids) {
    return new Promise((resolve, reject) => {
      ProfileStorage.Read(parentID, (profile) => {
        if (profile) {
          if (!profile.kids) {
            profile.kids = [];
          }
          kids.forEach((kid) => {
            if (
              !profile.kids.find((existingKid) => {
                return kid.id === existingKid.id;
              })
            ) {
              profile.kids.push({ id: kid.id });
            }
          });
          ProfileStorage.Update(parentID, profile).then(() => resolve());
        } else {
          resolve();
        }
      });
    });
  }

  static RemoveKidsFromFamily(parentID, kids) {
    return new Promise((resolve, reject) => {
      ProfileStorage.RemoveKidsFromProfile(parentID, kids).then(() => {
        ProfileStorage.ReadTrustedPoolList(parentID, (trustedPool) => {
          let requests = trustedPool.map((member) => {
            return new Promise((resolve) => {
              if (member.relationshipType === 'family') {
                ProfileStorage.RemoveKidsFromProfile(member.id, kids).then(() => resolve());
              } else {
                resolve();
              }
            });
          });
          Promise.all(requests).then(() => resolve());
        });
      });
    });
  }

  static RemoveKidsFromProfile(parentID, kids) {
    return new Promise((resolve, reject) => {
      ProfileStorage.Read(parentID, (profile) => {
        if (profile && profile.kids) {
          kids.forEach((kid) => {
            const index = profile.kids.findIndex((existingKid) => {
              return existingKid.id === kid.id;
            });
            if (index !== -1) {
              profile.kids.splice(index, 1);
            }
          });
          ProfileStorage.Update(parentID, profile).then(() => resolve());
        } else {
          resolve();
        }
      });
    });
  }

  static ReadProfileImage(profileID, callback) {
    const firebaseManager = new FirebaseManager();

    firebaseManager
      .getStorageUrl(`profiles/${profileID}/profileImage`)
      .then((uri) => callback(uri));
  }

  static StoreProfileImageFromFile(profileID, file, callback) {
    const firebaseManager = new FirebaseManager();

    firebaseManager.storeImageFromFile(
      `profiles/${profileID}/profileImage`,
      file,
      (imageSource) => {
        if (callback) {
          callback(imageSource);
        }
      },
    );
  }

  static GetPendingTrustedFriends(profileID, callback) {
    const firebaseManager = new FirebaseManager();
    var inviteList = [];

    firebaseManager.findByFbId('invitations/' + profileID, (snap) => {
      if (snap && snap.exists()) {
        snap.forEach((child) => {
          let inviteObj = child.val();
          if (inviteObj !== null && inviteObj.status === 'pending') {
            inviteList.push({
              name: inviteObj.inviteeName,
              phone: inviteObj.inviteeMobileNumber,
              email: inviteObj.inviteeEmailId,
              id: 0,
            });
          }
        });
      }
      callback(inviteList);
    });
  }

  static RemoveTrustedFriend(profileID, friendID, friendPhone, callback) {
    const firebaseManager = new FirebaseManager();

    firebaseManager.deleteValue('invitations/' + profileID + '/' + friendPhone);

    firebaseManager.readOnce('notifications/' + friendPhone, (snap) => {
      snap.forEach((child) => {
        let notificationObj = child.val();
        if (notificationObj !== null && notificationObj.inviterId === profileID) {
          firebaseManager.deleteValue('notifications/' + friendPhone + '/' + child.key, () => {
            console.log('deleted friend notification');
          });
        }
      });
    });

    if (friendID) {
      ProfileStorage.UncopyKids(profileID, friendID)
        .then(() => ProfileStorage.UncopyKids(friendID, profileID))
        .then(() => {
          ProfileStorage.DeleteFriendFromProfile(profileID, friendID, () => {
            if (callback) {
              callback();
            }
          });
        });
    } else if (callback) {
      callback();
    }
  }

  static DeleteFriendFromProfile(sourceProfileId, friendProfileId, callback) {
    const firebaseManager = new FirebaseManager();
    firebaseManager.deleteValue(
      'profiles/' + sourceProfileId + '/trustedPool/' + friendProfileId,
      () => {
        firebaseManager.deleteValue(
          'profiles/' + friendProfileId + '/trustedPool/' + sourceProfileId,
          () => {
            if (callback) {
              callback();
            }
          },
        );
      },
    );
  }

  static DeleteFriendForProfile(sourceProfileId, friendProfileId, callback) {
    const firebaseManager = new FirebaseManager();
    firebaseManager.deleteValue(
      'profiles/' + sourceProfileId + '/trustedPool/' + friendProfileId,
      () => {
        firebaseManager.deleteValue(
          'profiles/' + friendProfileId + '/trustedPool/' + sourceProfileId,
          () => {
            if (callback) {
              callback();
            }
          },
        );
      },
    );
  }

  static AddTrustedFriend(profileId, trustedFriendId, relationshipType) {
    return new Promise((resolve, reject) => {
      const firebaseManager = new FirebaseManager();

      const trustedPoolObject = {
        id: trustedFriendId,
        relationshipType: relationshipType,
      };
      firebaseManager.update(
        'profiles/' + profileId + '/trustedPool/' + trustedFriendId,
        trustedPoolObject,
      );

      trustedPoolObject.id = profileId;
      firebaseManager.update(
        'profiles/' + trustedFriendId + '/trustedPool/' + profileId,
        trustedPoolObject,
      );

      if (relationshipType === 'family') {
        ProfileStorage.CopyKids(profileId, trustedFriendId)
          .then(() => ProfileStorage.CopyKids(trustedFriendId, profileId))
          .then(() => resolve());
      } else {
        resolve();
      }
    });
  }

  static CopyKids(sourceUserID, destinationUserID) {
    return new Promise((resolve, reject) => {
      KidStorage.ReadCreatedKids(sourceUserID, (kids) => {
        if (kids.length) {
          ProfileStorage.AddKidsToProfile(destinationUserID, kids).then(() => resolve());
        } else {
          resolve();
        }
      });
    });
  }

  static UncopyKids(sourceUserID, destinationUserID) {
    return new Promise((resolve, reject) => {
      KidStorage.ReadCreatedKids(sourceUserID, (kids) => {
        if (kids.length) {
          ProfileStorage.RemoveKidsFromProfile(destinationUserID, kids).then(() => resolve());
        } else {
          resolve();
        }
      });
    });
  }

  static AddMessage(profileID, message) {
    const firebaseManager = new FirebaseManager();
    return firebaseManager.saveData('profiles/' + profileID + '/messages/', message);
  }

  static DeleteMessage(profileID, messageKey) {
    const firebaseManager = new FirebaseManager();
    firebaseManager.deleteValue('profiles/' + profileID + '/messages/' + messageKey);
  }

  static AddMessageResponse(profileID, messageKey, response) {
    const firebaseManager = new FirebaseManager();
    return firebaseManager.saveDate(
      'profiles/' + profileID + '/messages/' + messageKey + '/responses/',
      response,
    );
  }

  static ReadPlaydate(profileID, playdateID, callback) {
    const firebaseManager = new FirebaseManager();

    firebaseManager.readOnce('profiles/' + profileID + '/playdates/' + playdateID, (snap) => {
      if (callback) {
        let playdate = snap.val();
        callback(playdate);
      }
    });
  }

  static UpdatePlaydate(profileId, playdateId, playdate) {
    console.log('UPDATE PLAYDATE--->', profileId, playdateId, playdate);
    const firebaseManager = new FirebaseManager();
    firebaseManager.update('profiles/' + profileId + '/playdates/' + playdateId, playdate);
  }

  static AddUnactivated(profile) {
    const firebaseManager = new FirebaseManager();
    firebaseManager.update('profiles/' + profile.id, profile);
  }

  // TODO - Need to make this more secure!
  // Move partnerList out of the profile and only change via cloud endpoint
  static AddPartner({ profileID, partnerID, associatedPartners, makePending, chapterId }) {
    return new Promise((resolve, reject) => {
      ProfileStorage.Read(profileID, (profile) => {
        if (profile) {
          // Mark the profile if a partner has referred it
          const today = new Date();

          if (!profile.partnerList) {
            profile.partnerList = {};
          }

          if (profile.pendingChapters && profile.pendingChapters[partnerID] && !makePending) {
            /*
             * This case is likely to happen when an admin is accepting someone's join
             * request to a community that has chapters.
             */
            profile.partnerList[partnerID] = {
              joinDate: today.toISOString(),
              currentSubscribedChapterId: `${profile.pendingChapters[partnerID]}`,
            };
            profile.pendingChapters[partnerID] = null;
          } else if (chapterId && makePending) {
            /*
             * 'chapterId' should be the nearest chapter to the user or the global chapter
             * id if there isn't a chapter near the user.
             * This case is likely to happen when the user is sending a Join Request
             * to a private community that has chapters
             */
            if (!profile.pendingChapters) {
              profile.pendingChapters = {};
            }
            profile.pendingChapters[partnerID] = chapterId;
            profile.partnerList[partnerID] = 'pending';
          } else if (chapterId && !makePending) {
            /*
             * This case is likely to happen when the user is joining a public community
             * that has chapters.
             */
            profile.partnerList[partnerID] = {
              joinDate: today.toISOString(),
              currentSubscribedChapterId: chapterId,
            };
          } else {
            /*
             * This case happens when the partner community doesn't have chapters.
             */
            profile.partnerList[partnerID] = makePending ? 'pending' : today.toISOString();
          }

          if (associatedPartners) {
            try {
              const associatedPartnersObj = JSON.parse(associatedPartners);
              for (let key in associatedPartnersObj) {
                if (!profile.partnerList[key]) {
                  profile.partnerList[key] = today.toISOString();
                }
              }
            } catch (error) {
              console.warn('Error processing associated partners', error, associatedPartners);
              reject(error);
            }
          }

          ProfileStorage.Update(profile.id, profile);
          resolve(profile);
        } else {
          reject(new Error('Profile not found.'));
        }
      });
    });
  }

  // TODO - Need to make this more secure!
  // Move partnerList out of the profile and only change via cloud endpoint
  static RemovePartner(profileID, partnerID) {
    return new Promise((resolve, reject) => {
      ProfileStorage.Read(profileID, (profile) => {
        if (profile) {
          if (profile.partnerList && profile.partnerList[partnerID]) {
            profile.partnerList[partnerID] = null;
          }
          if (profile.pendingChapters && profile.pendingChapters[partnerID]) {
            profile.pendingChapters[partnerID] = null;
          }
          ProfileStorage.Update(profile.id, profile);
          resolve(profile);
        } else {
          reject(new Error('Profile not found.'));
        }
      });
    });
  }

  static UpdateSelectedPartnerChapter(profileId, partnerId, partnerInfo) {
    const firebaseManager = new FirebaseManager();

    return firebaseManager.update(`profiles/${profileId}/partnerList/${partnerId}`, partnerInfo);
  }

  static AddMarket(profileID, marketID) {
    return new Promise((resolve, reject) => {
      ProfileStorage.Read(profileID, (profile) => {
        if (profile) {
          // Mark the profile if a partner has referred it
          const today = new Date();

          if (!profile.marketList) {
            profile.marketList = {};
          }
          profile.marketList[marketID] = today.toISOString();

          ProfileStorage.Update(profile.id, profile);
          resolve(profile);
        } else {
          reject(new Error('Profile not found.'));
        }
      });
    });
  }
}
