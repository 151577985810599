import FirebaseManager from '../firebase/firebase-manager';

export default class MarketplaceStorage {
  static Read(marketplaceID, callback) {
    const firebaseManager = new FirebaseManager();

    firebaseManager.readOnce('marketplace/' + marketplaceID, (snap) => {
      const marketplace = snap.exists() ? snap.val() : null;
      if (callback) {
        callback(marketplace);
      }
    });
  }

  static ReadList(callback) {
    const firebaseManager = new FirebaseManager();
    const marketplaceList = [];

    firebaseManager.readOnce('marketplace', (snap) => {
      snap.forEach((child) => {
        const marketplace = child.val();
        if (marketplace != null) {
          marketplaceList.push(marketplace);
        }
      });

      callback(marketplaceList);
    });
  }
}
