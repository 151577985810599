import download from 'downloadjs';
import { parse } from 'json2csv';
import OrganizationStorage from '../storage/organization';
import SchoolStorage from '../storage/school';

export function exportSchoolList(callback) {
  SchoolStorage.ReadList((schoolList) => {
    const exportList = [];

    schoolList.forEach((school) => {
      exportList.push({
        name: school.name,
        address: school.street + ', ' + school.city + ' ' + school.state + ' ' + school.zip,
        website: school.website,
        phone: school.phone,
      });
    });

    callback(exportList);
  });
}

export function exportOrganizationList(callback) {
  OrganizationStorage.ReadList((orgList) => {
    const exportList = [];

    orgList.forEach((org) => {
      var address = '';
      var phone = '';
      var website = '';

      if (org.locations) {
        address =
          org.locations[0].street +
          ', ' +
          org.locations[0].city +
          ' ' +
          org.locations[0].state +
          ' ' +
          org.locations[0].zip;
        phone = org.locations[0].phone ? org.locations[0].phone : '';
        website = org.locations[0].website ? org.locations[0].website : '';
      }

      exportList.push({
        name: org.name,
        address: address,
        website: website,
        phone: phone,
      });
    });

    callback(exportList);
  });
}

export function exportDataToCSV(data) {
  try {
    const csv = parse(data);
    download(csv, 'ujama-organizations.csv', 'text/csv');
  } catch (err) {
    console.error(err);
  }
}
