import _ from 'lodash';

export const isSerializable = (obj) => {
  if (_.isFunction(obj)) {
    return false;
  }
  if (
    _.isUndefined(obj) ||
    _.isNull(obj) ||
    _.isBoolean(obj) ||
    _.isNumber(obj) ||
    _.isString(obj)
  ) {
    return true;
  }

  if (!_.isPlainObject(obj) && !_.isArray(obj)) {
    return false;
  }

  for (var key in obj) {
    if (!isSerializable(obj[key])) {
      return false;
    }
  }

  return true;
};

const HistoryUtils = {
  isSerializable,
};

export default HistoryUtils;
