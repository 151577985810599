import React from 'react';
import { toast } from 'react-toastify';
import {
  Checkbox,
  FormControlLabel,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Menu,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import AuthHelper from '../../../api/helpers/auth';
import SendBirdUser from '../../../api/sendbird/sendbird-user';
import MarketplaceStorage from '../../../api/storage/marketplace';
import ProfileStorage from '../../../api/storage/profile';
import UjamaAppBar from '../../common/material-wrap/app-bar';
import { isValidURL, prependURL } from '../../../api/helpers/formatterHelper';

export default class MarketplaceSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      profile: {},

      marketplaceName: '',
      nameError: '',
      hyperlinkError: '',
      categoryMenuAnchor: null,
    };

    this.categories = [];
    this.chatGroupJustCreated = false;
  }

  componentDidMount() {
    AuthHelper.getProfileDetails().then((profile) => {
      this.readData(profile);
    });
  }

  readData(profile) {
    const { marketplace } = this.props.location.state;

    if (!profile.isMarketplaceSeller && marketplace) {
      profile.isMarketplaceSeller = true;
      profile.marketplaceID = marketplace.id;
    }
    this.setState({ profile });

    MarketplaceStorage.ReadList((marketplaceList) => {
      marketplaceList.forEach((marketplace) => {
        this.categories.push({
          text: marketplace.name,
          value: marketplace.id,
        });
        if (profile.marketplaceID && profile.marketplaceID === marketplace.id) {
          this.setState({ marketplaceName: marketplace.name });
        }
      });

      this.categories = this.categories.sort((a, b) => {
        if (a.text < b.text) {
          return -1;
        }
        if (b.text < a.text) {
          return 1;
        }
        return 0;
      });
    });
  }

  onClickTerms = () => {
    window.open('https://ujama.co/marketplace-faq.pdf', '_blank');
  };

  onClickCategory = (event) => {
    this.setState({ categoryMenuAnchor: event.currentTarget });
  };

  onSelectedCategory = (category) => {
    const { profile } = this.state;

    profile.marketplaceID = category.value;
    this.setState({
      categoryMenuAnchor: null,
      marketplaceName: category.text,
      profile,
    });
  };

  onSave = () => {
    const { profile } = this.state;

    if (this.validate()) {
      const profileObj = {
        isMarketplaceSeller: profile.isMarketplaceSeller,
        marketplaceID: profile.marketplaceID,
        marketplaceBusinessName: profile.marketplaceBusinessName,
        marketplaceDescription: profile.marketplaceDescription,
        marketplaceWebsite: profile.marketplaceWebsite
          ? prependURL(profile.marketplaceWebsite)
          : '',
        acceptedMarketplaceTerms: profile.acceptedMarketplaceTerms,
      };

      if (profile.isMarketplaceSeller) {
        if (!profile.marketplaceChannelURL) {
          SendBirdUser.CreateMarketplaceChannel(
            profile.marketplaceBusinessName,
            profile,
            (channel) => {
              if (channel) {
                this.chatGroupJustCreated = true;
                SendBirdUser.SendAdminMessage(
                  `Welcome to the ${profile.marketplaceBusinessName} Chat Group where you'll receive offers from ${profile.name}.`,
                  channel.url,
                );
                profileObj.marketplaceChannelURL = channel.url;
              } else {
                console.warn('Unable to create channel for profile marketplace', profile);
              }
              this.finishSave(profileObj);
            },
          );
        } else {
          SendBirdUser.GetChannel(profile.marketplaceChannelURL, (channel) => {
            if (!channel) {
              console.warn('Unable to find channel!', profile.marketplaceChannelURL);
            } else {
              // Update the chat group name in case the user changed it
              channel.updateChannel(profile.marketplaceBusinessName, null, null, () => {
                console.log('Channel name updated', channel.name);
              });
            }
            this.finishSave(profileObj);
          });
        }
      } else {
        this.finishSave(profileObj);
      }
    }
  };

  finishSave(profileObj) {
    const { profile } = this.state;

    ProfileStorage.Update(profile.id, profileObj).then(() => {
      AuthHelper.refreshProfileDetails(profile.id)
        .then(() => {
          this.props.history.goBack();
        })
        .catch((error) => {
          toast.error('Sorry, something went wrong. Please try again later.\n\n' + error);
          this.props.history.goBack();
        });

      if (this.chatGroupJustCreated) {
        confirmAlert({
          title: 'Congratulations!',
          message:
            "You are now listed as a seller in Ujama's Marketplace! Next, you should offer items for sale to your business' chat group.\n\nDo you want to offer items now?",
          buttons: [
            {
              label: "I'll do this later",
            },
            {
              label: 'Yes',
              onClick: () => {
                toast.info('About to take you to your chat group...');
                SendBirdUser.NavigateToChannel(profileObj.marketplaceChannelURL);
              },
            },
          ],
        });
      }
    });
  }

  validate() {
    const { profile } = this.state;
    let ret = false;

    do {
      if (profile.isMarketplaceSeller) {
        if (!profile.marketplaceID) {
          toast.warn('Please choose a Marketplace category.');
          break;
        }

        if (!profile.marketplaceBusinessName) {
          toast.warn('Please provide the name of your business.');
          break;
        }

        if (!profile.marketplaceDescription) {
          toast.warn('Please provide a description of your business.');
          break;
        }

        if (profile.marketplaceWebsite && !isValidURL(profile.marketplaceWebsite)) {
          toast.warn('Please provide a valid website link.');
          break;
        }

        if (!profile.acceptedMarketplaceTerms) {
          toast.warn('Please accept the terms of our marketplace agreement.');
          break;
        }
      }

      ret = true;
      // eslint-disable-next-line no-constant-condition
    } while (false); // Do ONCE!

    return ret;
  }

  render() {
    return (
      <div>
        <UjamaAppBar
          title={'Marketplace Settings'}
          rightAction={this.onSave}
          rightActionText="Save"
          type="cancel"
        />
        {this.renderScreen()}
      </div>
    );
  }

  renderScreen() {
    const { profile } = this.state;

    return (
      <Paper style={{ textAlign: 'left', padding: 40, margin: 20 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={profile.isMarketplaceSeller ? true : false}
              onChange={() => {
                profile.isMarketplaceSeller = !profile.isMarketplaceSeller;
                this.setState({ profile });
              }}
            />
          }
          label="List my business"
        />
        {this.renderSettings()}
        {this.renderCategoryMenu()}
      </Paper>
    );
  }

  renderSettings() {
    const { profile, marketplaceName } = this.state;

    if (profile.isMarketplaceSeller) {
      return (
        <div>
          <TextField
            style={{ width: '100%', marginBottom: 10 }}
            type="text"
            value={profile.marketplaceBusinessName}
            label="Business Name"
            onChange={(event) => {
              profile.marketplaceBusinessName = event.target.value;
              this.setState({ profile });
            }}
          />

          <ListItem
            button
            divider
            disableGutters={true}
            onClick={(event) => this.onClickCategory(event)}
          >
            <ListItemText
              primary={marketplaceName ? marketplaceName : 'Select a marketplace category...'}
            />
            <ListItemSecondaryAction onClick={(event) => this.onClickCategory(event)}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>{marketplaceName ? 'Change' : ''}</span>
                <ChevronRight />
              </div>
            </ListItemSecondaryAction>
          </ListItem>

          <div style={{ height: 10 }} />

          <TextField
            style={{ width: '100%', marginBottom: 10 }}
            type="text"
            value={profile.marketplaceWebsite}
            label="Your website (optional)"
            onChange={(event) => {
              profile.marketplaceWebsite = event.target.value;
              this.setState({ profile });
            }}
          />

          <TextField
            style={{ width: '100%', marginBottom: 10 }}
            type="text"
            multiline={true}
            rows={4}
            value={profile.marketplaceDescription}
            label="Describe your business"
            onChange={(event) => {
              profile.marketplaceDescription = event.target.value;
              this.setState({ profile });
            }}
          />

          <div style={{ height: 20 }} />

          <Typography variant="subtitle1">{'MARKETPLACE TERMS'}</Typography>
          <ListItem button divider disableGutters={true} onClick={this.onClickTerms}>
            <ListItemText primary="Read agreement" />
            <ListItemSecondaryAction>
              <ChevronRight />
            </ListItemSecondaryAction>
          </ListItem>

          <div style={{ height: 10 }} />

          <FormControlLabel
            control={
              <Checkbox
                checked={profile.acceptedMarketplaceTerms ? true : false}
                onChange={() => {
                  profile.acceptedMarketplaceTerms = !profile.acceptedMarketplaceTerms;
                  this.setState({ profile });
                }}
              />
            }
            label="I have read and accepted the marketplace terms"
          />
        </div>
      );
    }
  }

  renderCategoryMenu() {
    const { categoryMenuAnchor } = this.state;
    const fragments = [];

    this.categories.forEach((category) => {
      fragments.push(
        <MenuItem onClick={() => this.onSelectedCategory(category)}>{category.text}</MenuItem>,
      );
    });

    return (
      <Menu
        anchorEl={categoryMenuAnchor}
        keepMounted
        open={Boolean(categoryMenuAnchor)}
        onClose={() => this.setState({ categoryMenuAnchor: null })}
        PaperProps={{
          style: {
            maxHeight: 400,
          },
        }}
      >
        {fragments}
      </Menu>
    );
  }
}
