import { firebase } from './firebase';
class FirebaseManager {
  /**
   * Initializes references to firebase database and storage
   */
  constructor() {
    this.firebaseRef = firebase.database().ref();
    this.storageRoot = firebase.storage().ref();
  }

  static setMessageSubscriptionSettings(profileId, settings) {
    firebase
      .database()
      .ref('profiles/' + profileId + '/messageSubscriptions/')
      .update(settings);
  }

  static getProfileName(profileId) {
    firebase.database().ref('profiles/' + profileId + '/name/');
  }

  static getProfileID(profileId) {
    console.log(profileId);
    firebase.database().ref('profiles/' + profileId);
  }

  static getCreatedDate(profileId) {
    console.log(profileId);
    firebase.database().ref('profiles/' + profileId + 'created');
  }

  /**
   * Gets a record from a collection
   * @param {String} child Collection name
   */
  get(child) {
    return this.firebaseRef.child(child);
  }

  /**
   * Creates a new record under a collection
   * @param {String} child Collection name
   * @param {Object} params Data to be created as a record
   */
  create(child, params) {
    return this.firebaseRef.child(child).set(params);
  }

  /**
   * Deletes a record from a collection
   * @param {String} child Collection name
   * @param {Function} callback A callback function to listen on the status of the operation
   */
  deleteValue(child, callback) {
    this.firebaseRef.child(child).remove(() => {
      if (callback) {
        callback(true);
      }
    });
  }

  /**
   * Deletes a record from a collection
   * @param {String} child Collection name
   * @param {Function} callback A callback function to listen on the status of the operation
   */
  deleteValuePromise(child, callback) {
    return this.firebaseRef.child(child).remove();
  }

  /**
   * Reads data from a collection and listens on it for any changes
   * @param {String} child Collection name
   * @param {Function} callback A callback function to listen on the status of the operation
   */
  read(child, callback) {
    this.firebaseRef
      .child(child)
      .on('value')
      .then((snap) => {
        callback(snap);
      });
  }

  /**
   * Reads data from a collection once
   * @param {String} child Collection name
   * @param {Function} callback A callback function to listen on the status of the operation
   */

  readOnce(child, callback) {
    this.firebaseRef
      .child(child)
      .once('value')
      .then((snap) => {
        callback(snap);
      });
  }

  /**
   * Reads data from a collection once with a limit
   * @param {String} child Collection name
   * @param {Number} limitVal A limit to listen on the synced records
   * @param {Function} callback A callback function to listen on the status of the operation
   */
  readOnceLimit(child, limitVal, callback) {
    this.firebaseRef
      .child(child)
      .limitToLast(limitVal)
      .once('value', (snap) => {
        callback(snap);
      });
  }

  searchSub(child, searchField, query, pageSize, pageNum, callback) {
    this.firebaseRef
      .child(child)
      .orderByChild(searchField)
      .startAt(query)
      .endAt(query + '\uf8ff')
      .limitToFirst(pageSize * pageNum)
      .once('value', (snap) => {
        let data = [];
        let i = 0;
        snap.forEach((child) => {
          if (i >= pageSize * pageNum - pageSize) {
            data.push(child.val());
          }
          i++;
        });
        callback(data);
      });
  }

  /**
   * Reads data from a collection based on an ID of a record
   * @param {String} child Collection name
   * @param {String} id ID of the record to find
   * @param {Function} callback A callback function to listen on the status of the operation
   */
  findById(child, id, callback) {
    this.firebaseRef
      .child(child)
      .orderByChild('id')
      .equalTo(id)
      .once('value', (snap) => {
        // Run it only once...
        callback(snap);
      });
  }

  /**
   * Reads data from a collection based on FB ID
   * @param {String} child Collection name
   * @param {Function} callback A callback function to listen on the status of the operation
   */
  findByFbId(child, callback) {
    this.firebaseRef
      .child(child)
      .once('value')
      .then((snap) => {
        // Run it only once...
        callback(snap);
      });
  }

  /**
   * Updates an existing record
   * @param {String} child Collection name
   * @param {Object} params Data to be updated
   */
  update(child, params) {
    return this.firebaseRef.child(child).update(params);
  }

  /**
   * Creates a new record in the given collection
   * @param {String} child Collection name
   * @param {Object} params Data to be created
   */
  saveData(child, params) {
    return this.firebaseRef.child(child).push(params).getKey();
  }

  saveDataPromise(child, params) {
    return this.firebaseRef.child(child).push(params);
  }

  /**
   * Stores an image in the firebase storage from a file
   * @param {String} location Location where the image has to be stored
   * @param {String} url URL of the image file
   */
  storeImageFromFile(location, file, callback) {
    let fileType = file.type;
    if (!fileType.includes('image')) {
      fileType = `image/${file.type}`;
    }
    this.storageRoot
      .child(location)
      .put(file, { contentType: fileType })
      .then((snapshot) => {
        if (callback) {
          this.storageRoot
            .child(location)
            .getDownloadURL()
            .then((url) => callback(url));
        }
      });
  }

  storeFileFromString(location, content) {
    return new Promise((resolve, reject) => {
      const storageRef = this.storageRoot.child(location);
      const uploadTask = storageRef.putString(content, 'raw', { contentType: 'text/html' });

      uploadTask
        .then(() => {
          this.storageRoot
            .child(location)
            .getDownloadURL()
            .then((url) => resolve(url));
        })
        .catch((error) => reject(error));
    });
  }

  /**
   * Looks up and gets an image from the firebase storage
   * @param {String} location Location of the image
   */
  getStorageUrl(location) {
    return new Promise((resolve) => {
      const imageRef = this.storageRoot.child(location);

      imageRef
        .getDownloadURL()
        .then((url) => {
          resolve(url);
        })
        .catch((error) => {
          //console.log("FIREBASE IMAGE ERROR---->", location, error);
          resolve(undefined);
        });
    });
  }

  static getUserUid() {
    let uid = null;

    if (firebase.auth().currentUser !== null) {
      uid = firebase.auth().currentUser.uid;
    }

    return uid;
  }

  static getUserProfileID() {
    let id = null;

    if (firebase.auth().currentUser !== null) {
      id = firebase.auth().currentUser.providerData[0].uid;
      console.log('PROFILE ID--->', id);
    }

    return id;
  }
}

export default FirebaseManager;
