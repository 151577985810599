import firebaseLib from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/functions';

let gProvider = new firebaseLib.auth.GoogleAuthProvider();
let fProvider = new firebaseLib.auth.FacebookAuthProvider();

const firebaseCredentials = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const firebase = firebaseLib.initializeApp(firebaseCredentials);
firebaseLib.analytics();

// We use the secondary handle for phone authorizations
// Try/catch ensures we only initializeApp once!
let fbSecondary = null;
try {
  fbSecondary = firebaseLib.app('SECONDARY_APP');
} catch (error) {
  firebaseLib.initializeApp(firebaseCredentials, { name: 'SECONDARY_APP' });
  fbSecondary = firebaseLib.app('SECONDARY_APP');
}

export const googleProvider = gProvider;
export const facebookProvider = fProvider;
export const firebaseSecondary = fbSecondary;
