import moment from 'moment';

export function validatePhoneNumber(phone) {
  phone = unformatPhoneNumber(phone);
  if (phone.length === 10) {
    return true;
  }

  return false;
}

export function formatPhoneNumber(phone) {
  // Format to (123) 456-7890
  if (phone.length !== 10) {
    phone = unformatPhoneNumber(phone);
  }

  if (phone.length === 10) {
    // format and return phone number
    return phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  }

  return null; // can't format number
}

export function unformatPhoneNumber(phone) {
  // Unformat the phone number. These examples are just for a US-based number.
  // 202 456 1111, (202) 456 1111, 1-202-456-1111, 1-202-456-1111 x1234, 1-202-456-1111 ext1234
  // 1 (202) 456-1111, 1.202.456.1111, 1/202/456/1111, 12024561111, +1 202 456 1111
  phone = phone.replace(/[^0-9]/g, '');

  // Truncate starting 1 or +1
  if (phone.length > 10) {
    if (phone.startsWith('+1')) {
      phone = phone.substring(2, phone.length);
    } else if (phone.startsWith('1')) {
      phone = phone.substring(1, phone.length);
    }
  }
  return phone;
}

export function getFirstName(name) {
  if (name) {
    const fullName = name.split(' ');
    return fullName[0];
  }
  return name;
}

export function getLastName(name) {
  const firstName = getFirstName(name);
  return name.substring(firstName.length).trim();
}

export function makePossessiveString(name) {
  let ret = name + "'";
  if (name[name.length - 1] !== 's') {
    ret += 's';
  }
  return ret;
}

export function encodeEmailForFirebaseKey(path) {
  if (path) {
    return btoa(path.toLowerCase());
  }
  return undefined;
}

export function validateEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function generateKidNames(ride) {
  var kidNames = '';

  for (let i = 0; i < ride.kidList.length; i++) {
    if (i > 0) {
      if (i === ride.kidList.length - 1) {
        kidNames += ' and ';
      } else {
        kidNames += ', ';
      }
    }
    kidNames += ride.kidList[i].kidName;
  }

  return kidNames;
}

export function getCountryCode(phone) {
  let countryCode = null;
  let workingPhone = phone.replace(/[^0-9]/g, '');
  if (phone.length > 10) {
    countryCode = workingPhone.substring(0, workingPhone.length - 10);
    if (phone.startsWith('+')) {
      countryCode = countryCode.substring(1, countryCode.length);
    }
  }
  return countryCode;
}

export function getWhenString(timeString) {
  let ret = '';

  if (timeString && timeString[0]) {
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    const date = new Date(timeString);
    const daysDifference = (today.getTime() - date.getTime()) / (1000 * 3600 * 24);

    if (daysDifference > 5) {
      ret = moment(date).format('MM/DD/YYYY');
    } else if (today.getDate() === date.getDate()) {
      ret = moment(date).format('HH:mm');
    } else if (yesterday.getDate() === date.getDate()) {
      ret = 'Yesterday';
    } else {
      ret = moment(date).format('dddd');
    }
  }

  return ret;
}

export function isValidURL(str) {
  let ret = false;

  do {
    if (!str || !str[0]) {
      break;
    }

    const testString = str.toLowerCase();
    const regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    if (!regexp.test(testString)) {
      break;
    }

    ret = true;
    // eslint-disable-next-line no-constant-condition
  } while (false); // do ONCE!

  return ret;
}

export function prependURL(url) {
  const testURL = url.toLowerCase();
  if (!/^https?:\/\//i.test(testURL)) {
    url = 'http://' + url;
  }
  return url;
}

export function maybeTrimString(stringToTrim, searchText) {
  let ret = stringToTrim;

  if (stringToTrim) {
    const index = stringToTrim.indexOf(searchText);

    if (index > 80) {
      ret = '...' + stringToTrim.substring(index - 40);
    }
  }

  return ret;
}

export function removeArticles(str) {
  let ret = str;
  const words = str.split(' ');

  if (words.length > 1) {
    if (words[0] === 'A' || words[0] === 'The' || words[0] === 'An') {
      ret = words.splice(1).join(' ');
    }
  }

  return ret;
}
