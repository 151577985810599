import PubNub from 'pubnub';
import { toast } from 'react-toastify';
import AuthHelper from '../helpers/auth';

const profileChannel = (profileId) => `UjamaPushChannel-${profileId}`;

const messagePayload = (
  messageStr,
  destProfileId,
  senderProfileId,
  data,
  isIosDebugMode = false,
) => ({
  pn_apns: {
    aps: {
      alert: messageStr,
      badge: 1,
      sound: 'melody',
    },
    pn_push: [
      // required for APNs2 (iOS only)
      {
        push_type: 'alert',
        auth_method: 'token',
        targets: [
          {
            /**
             * 'development' or 'production'.
             * 'development' means when the mobile app is running on debug mode and
             * 'production' means when the mobile app is running on release mode.
             * Whether to point to the Staging or Production app is determined by which
             * ios bundle id to point to, which is read on the .env
             */
            environment: isIosDebugMode ? 'development' : 'production',
            topic: process.env.REACT_APP_IOS_BUNDLE_ID,
          },
        ],
        version: 'v2',
      },
    ],
  },
  pn_gcm: {
    notification: {
      body: messageStr,
    },
  },
  messageStr,
  data,
  _data: data,
  senderProfileId,
  destProfileId,
});

const silentPayload = (data, senderProfileId, destProfileId, isIosDebugMode = false) => ({
  pn_apns: {
    aps: {
      silence: true,
    },
    pn_push: [
      // required for APNs2 (only iOS)
      {
        push_type: 'alert',
        auth_method: 'token',
        targets: [
          {
            /**
             * 'development' or 'production'.
             * 'development' means when the mobile app is running on debug mode and
             * 'production' means when the mobile app is running on release mode.
             * Whether to point to the Staging or Production app is determined by which
             * ios bundle id to point to, which is read on the .env
             */
            environment: isIosDebugMode ? 'development' : 'production',
            topic: process.env.REACT_APP_IOS_BUNDLE_ID,
          },
        ],
        version: 'v2',
      },
    ],
  },
  pn_gcm: {
    notification: {
      silence: true,
    },
  },
  data,
  _data: data,
  senderProfileId,
  destProfileId,
});

const locationListenerList = [];
function addLocationListener(listener, channelName) {
  locationListenerList.push({
    channelName: channelName,
    listener: listener,
  });
}

function removeLocationListener(listener) {
  const index = locationListenerList.findIndex((entry) => entry.listener === listener);
  if (index === -1) {
    console.warn('Attempted to remove a listener that was not in the list!');
  } else {
    locationListenerList.splice(index, 1);
  }
}

export default class PushNotification {
  static pubnub = null;
  static senderProfile = {};

  static Initialize() {
    return new Promise((resolve, reject) => {
      if (!PushNotification.pubnub) {
        PushNotification.pubnub = new PubNub({
          publishKey: process.env.REACT_APP_PUBNUB_PUBLISH_KEY,
          subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY,
        });

        PushNotification.pubnub.addListener({
          status: (statusEvent) => {
            switch (statusEvent.category) {
              case 'PNConnectedCategory':
              case 'PNNetworkUpCategory':
                resolve(PushNotification.pubnub);
                break;
              case 'PNDisconnectedCategory':
              case 'PNNetworkDownCategory':
                reject(new Error('Received a network-down message'));
                break;
              default:
                return;
            }
          },
          message: (data) => {
            if (data.message && data.message.messageStr) {
              toast.dark(data.message.messageStr, {
                closeOnClick: true,
                autoClose: false,
                closeButton: true,
              });
            }
          },
        });

        AuthHelper.getProfileDetails().then((profile) => {
          PushNotification.pubnub.subscribe({
            channels: [profileChannel(profile.id)],
          });
          PushNotification.senderProfile = profile;
        });
      } else {
        resolve(PushNotification.pubnub);
      }
    });
  }

  static SendNotification(messageStr, destProfileId, data = null, isIosDebugMode = false) {
    PushNotification.Initialize()
      .then(() => {
        PushNotification.pubnub.publish(
          {
            channel: profileChannel(destProfileId),
            message: messagePayload(
              messageStr,
              destProfileId,
              PushNotification.senderProfile.id,
              data,
              isIosDebugMode,
            ),
          },
          (status) => {
            if (status.error) {
              console.warn('Failed to send notification', status);
            }
          },
        );
      })
      .catch((error) => {
        console.warn('Pubnub not initialized', error);
      });
  }

  static SendSilentNotification(data, senderProfileId, destProfileId, isIosDebugMode = false) {
    PushNotification.Initialize()
      .then(() => {
        PushNotification.pubnub.publish(
          {
            channel: profileChannel(destProfileId),
            message: silentPayload(data, senderProfileId, destProfileId, isIosDebugMode),
          },
          (status) => {
            if (status.error) {
              console.error('Failed to send silent notification', status);
            }
          },
        );
      })
      .catch((error) => {
        console.warn('Pubnub not initialized', error);
      });
  }

  static subscribeToProfileLocationChannel(profileID, listener) {
    PushNotification.Initialize().then(({ pubnub }) => {
      const channelName = profileID + '_location';
      PushNotification.pubnub.subscribe({
        channels: [channelName],
      });
      addLocationListener(listener, channelName);
    });
  }

  static unsubscribeFromProfileLocationChannel(profileID, listener) {
    PushNotification.Initialize().then(({ pubnub }) => {
      const channelName = profileID + '_location';
      PushNotification.pubnub.unsubscribe({
        channels: [channelName],
      });
      removeLocationListener(listener);
    });
  }
}
