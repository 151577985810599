// StateMachine is a class of statics to help manage state in between inter-component execution

export default class StateMachine {
  static name(component) {
    //console.info('StateMachine: name', component.constructor.name, component.state);
    return component.constructor.name;
  }

  static save(component) {
    console.info('StateMachine: save', component.constructor.name, component.state);
    sessionStorage.setItem(StateMachine.name(component), JSON.stringify(component.state));
  }

  static saveByName(name, component) {
    sessionStorage.setItem(name, JSON.stringify(component.state));
  }

  static readByName(name) {
    var savedState = null;

    const data = sessionStorage.getItem(name);

    if (data) {
      savedState = JSON.parse(data);
    }

    return savedState;
  }

  static addPropertiesByName(name, properties) {
    var savedState = StateMachine.readByName(name);

    if (savedState) {
      for (let key in properties) {
        savedState[key] = properties[key];
      }
      sessionStorage.setItem(name, JSON.stringify(savedState));
    } else {
      console.error('StateMachine: No stored state found that matches ' + name);
    }
  }

  static restoreByName(name, component) {
    const savedState = StateMachine.readByName(name);

    if (savedState) {
      component.setState(savedState);
      StateMachine.removeByName(name);
    }

    return savedState;
  }

  static read(component) {
    var savedState = null;

    const data = sessionStorage.getItem(StateMachine.name(component));

    if (data) {
      savedState = JSON.parse(data);
    }

    return savedState;
  }

  static addProperties(component, properties) {
    var savedState = StateMachine.read(component);

    if (savedState) {
      for (let key in properties) {
        savedState[key] = properties[key];
      }
      sessionStorage.setItem(StateMachine.name(component), JSON.stringify(savedState));
    } else {
      console.error(
        'StateMachine: No stored state found that matches ' + StateMachine.name(component),
      );
    }
  }

  static removeByName(name) {
    sessionStorage.removeItem(name);
  }

  static remove(component) {
    sessionStorage.removeItem(StateMachine.name(component));
  }

  static restore(component) {
    const savedState = StateMachine.read(component);

    if (savedState) {
      component.setState(savedState);
      StateMachine.remove(component);
    }

    return savedState;
  }
}

// Make this code available elsewhere
// module.exports = StateMachine;
