import FirebaseManager from '../firebase/firebase-manager';

export default class OrganizationStorage {
  static GetEmptyOrganization() {
    return {
      id: 0,
      name: '',
      classDetails: [],
      holidays: [],
      locations: [],
      sessions: [],
    };
  }
  static Delete(schoolId) {
    const firebaseManager = new FirebaseManager();
    firebaseManager.deleteValue('activityOrganization/' + schoolId);
  }
  static GetEmptyActivityClass() {
    return {
      id: 0,
      name: '',
      level: '',
      locationId: 0,
      startDate: '',
      endDate: '',
      recurrence: '',
      timing: [{ day: '', startTime: '', endTime: '' }],
    };
  }

  static Read(orgID, callback) {
    const firebaseManager = new FirebaseManager();

    firebaseManager.readOnce('activityOrganization/' + orgID, (snap) => {
      let organization = snap.exists() ? snap.val() : null;
      if (callback) {
        callback(organization);
      }
    });
  }

  static ReadVerifiedParents(orgId, callback) {
    const firebaseManager = new FirebaseManager();

    firebaseManager.readOnce('activityOrganization/' + orgId + '/verifiedList', (snap) => {
      let verifiedList = snap.exists() ? snap.val() : {};
      if (callback) {
        callback(verifiedList);
      }
    });
  }

  static IsParentVerified(orgId, userId, callback) {
    const firebaseManager = new FirebaseManager();

    firebaseManager.readOnce(
      'activityOrganization/' + orgId + '/verifiedList/' + userId,
      (snap) => {
        let verified = snap.exists();
        if (callback) {
          callback(verified);
        }
      },
    );
  }

  static UpdateVerifiedParent(orgId, parentId, verifiedData, callback) {
    OrganizationStorage.GetKeyFromID(orgId, (key) => {
      const firebaseManager = new FirebaseManager();
      firebaseManager
        .update('activityOrganization/' + key + '/verifiedList/' + parentId, verifiedData)
        .then(() => {
          if (callback) {
            callback();
          }
        });
    });
  }

  static DeleteVerifiedParent(orgId, parentId, callback) {
    OrganizationStorage.GetKeyFromID(orgId, (key) => {
      const firebaseManager = new FirebaseManager();
      firebaseManager.deleteValue(
        'activityOrganization/' + key + '/verifiedList/' + parentId,
        () => {
          if (callback) {
            callback();
          }
        },
      );
    });
  }

  static ReadList(callback) {
    const firebaseManager = new FirebaseManager();
    var orgList = [];

    firebaseManager.readOnce('activityOrganization', (snap) => {
      snap.forEach((child) => {
        let org = child.val();
        if (org != null) {
          orgList.push(org);
        }
      });

      callback(orgList);
    });
  }

  static ReadAll(callback) {
    const firebaseManager = new FirebaseManager();

    firebaseManager.readOnce('activityOrganization', (snap) => {
      let organizations = snap.exists() ? snap.val() : null;
      if (organizations != null) {
        if (callback) {
          callback(organizations);
        }
      }
    });
  }

  static Add(organization, callback) {
    const firebaseManager = new FirebaseManager();

    const orgId = firebaseManager.saveData('activityOrganization', organization);
    // We'll set the id to be the unique key created by Firebase
    organization.id = orgId;
    firebaseManager.update('activityOrganization/' + orgId, organization).then(() => {
      if (callback) {
        callback();
      }
    });
    return orgId;
  }

  static Update(orgId, organization, callback) {
    OrganizationStorage.GetKeyFromID(orgId, (key) => {
      const firebaseManager = new FirebaseManager();
      firebaseManager.update('activityOrganization/' + key, organization).then(() => {
        if (callback) {
          callback();
        }
      });
    });
  }

  static GetKeyFromID(orgId, callback) {
    const firebaseManager = new FirebaseManager();
    firebaseManager.readOnce('activityOrganization', (snap) => {
      snap.forEach((child) => {
        let organization = child.val();
        if (organization !== null && organization.id.toString() === orgId.toString()) {
          callback(child.key);
          return;
        }
      });
    });
  }

  static GetIdFromName(orgName, callback) {
    const firebaseManager = new FirebaseManager();

    firebaseManager.readOnce('activityOrganization', (snap) => {
      snap.forEach((child) => {
        let org = child.val();
        if (org !== null && org.name === orgName) {
          callback(org.id);
          return;
        }
      });
    });
  }

  static GetNameFromId(orgID, callback) {
    const firebaseManager = new FirebaseManager();

    firebaseManager.readOnce('activityOrganization/' + orgID, (snap) => {
      if (snap.exists()) {
        let org = snap.val();
        callback(org.name);
      } else {
        callback(null);
      }
    });
  }

  static HasParentRequestedVerified(orgId, userId, callback) {
    const firebaseManager = new FirebaseManager();

    firebaseManager.readOnce(
      'activityOrganization/' + orgId + '/verifyRequests/' + userId,
      (snap) => {
        let requested = snap.exists() ? true : false;
        if (callback) {
          callback(requested);
        }
      },
    );
  }
}
