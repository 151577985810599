import React from 'react';
import { toast } from 'react-toastify';
import { CircularProgress, Typography } from '@material-ui/core';
import moment from 'moment';
import SendBirdUser from '../../../api/sendbird/sendbird-user';
import SearchChatResult from './search-chat-result';

class ChatSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchText: '',
      groupChannels: [],
      loading: false,
      filteredSearchResults: [],
    };
  }

  componentDidMount() {
    this.getUserChannels();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.searchText !== this.props.searchText) {
      this.filterMessages();
    }
  }

  getUserChannels() {
    this.setState({ loading: true });

    this.getGroupChannels().then(() => {
      this.setState({
        loading: false,
      });
    });
  }

  getGroupChannels() {
    return new Promise((resolve, reject) => {
      SendBirdUser.GetSubscribedChannels(this.props.profile.id, (channels) => {
        this.setState(
          {
            groupChannels: channels,
          },
          () => {
            this.getChannelListMessages(this.state.groupChannels, resolve);
          },
        );
      });
    });
  }

  getChannelListMessages(channelList, callback) {
    const promises = channelList.map((channel) => {
      return new Promise((resolve) => {
        const messageQuery = channel.createPreviousMessageListQuery();
        channel.messageList = [];
        this.getChannelMessages(channel, messageQuery).then(() => resolve());
      });
    });

    Promise.all(promises).then(() => callback());
  }

  getChannelMessages(channel, messageQuery) {
    return new Promise((resolve, reject) => {
      if (messageQuery.hasMore) {
        // TODO: Need to delay fetches so we don't trigger SendBird exception of too many requests
        setTimeout(() => {
          messageQuery.load(100, false, (response, error) => {
            if (error) {
              reject(error);
            } else {
              if (response && response.length) {
                channel.messageList = [...channel.messageList, ...response];
              }
              this.getChannelMessages(channel, messageQuery).then(() => resolve());
            }
          });
        }, Math.random() * 1000);
      } else {
        resolve();
      }
    });
  }

  isLatest(messageList, currentMessage) {
    let ret = true;

    if (currentMessage.customType) {
      for (let i = 0; i < messageList.length && ret; i++) {
        let message = messageList[i];

        if (
          message.customType === currentMessage.customType &&
          currentMessage.url === message.url &&
          currentMessage.messageId !== message.messageId
        ) {
          ret = currentMessage.createdAt > message.createdAt;
        }
      }
    }
    return ret;
  }

  filterMessages() {
    const { searchText } = this.props;
    const { groupChannels } = this.state;
    const filteredResults = [];

    if (searchText !== '' && searchText.length > 2) {
      this.setState({ loading: true });
      groupChannels.forEach((channel) => {
        const result = this.filterChannelMessages(channel, searchText);
        if (result.length) {
          filteredResults.push(...result);
        }
      });
    }
    this.setState({ filteredSearchResults: filteredResults, loading: false });
  }

  filterChannelMessages(channel, searchText) {
    const { profile } = this.props;
    const searchResults = [];

    if (searchText && channel.lastMessage && channel.messageList) {
      const regex = new RegExp(searchText, 'i');
      const channelTitle = SendBirdUser.GetChatTitle(channel, profile.id);
      const imageSrc = SendBirdUser.GetChatImageSource(channel, profile.id);

      if (regex.test(channelTitle)) {
        const lastMessageContent = channel.lastMessage.isFileMessage()
          ? channel.lastMessage.name
          : channel.lastMessage.message;
        const senderString = channel.lastMessage.isAdminMessage()
          ? 'Ujama'
          : channel.lastMessage.sender.nickname;
        if (this.isLatest(channel.messageList, channel.lastMessage)) {
          const messageDate = new Date(channel.lastMessage.createdAt);
          searchResults.push({
            key: channel.url + channel.lastMessage.messageId + '-last',
            messageId: channel.lastMessage.messageId,
            messageContent: lastMessageContent,
            senderString: senderString,
            customType: channel.customType,
            name: channelTitle,
            url: channel.url,
            coverUrl: imageSrc,
            members: channel.members,
            channelDate:
              moment(messageDate).format('MM/DD/YYYY') + ' ' + moment(messageDate).format('HH:mm'),
            channelType: channel.channelType,
            isSenderStringMatched: false,
          });
        }
      }

      channel.messageList.forEach((message, index) => {
        const messageContent = message.isFileMessage() ? message.name : message.message;
        const senderString = message.isAdminMessage() ? 'Ujama' : message.sender.nickname;
        const isSenderStringMatched = regex.test(senderString);
        if (regex.test(messageContent) || isSenderStringMatched) {
          if (this.isLatest(channel.messageList, message)) {
            const messageDate = new Date(message.createdAt);
            searchResults.push({
              key: channel.url + message.messageId + '-' + index,
              messageId: message.messageId,
              messageContent: messageContent,
              senderString: senderString,
              customType: channel.customType,
              name: channelTitle,
              url: channel.url,
              coverUrl: imageSrc,
              members: channel.members,
              channelDate:
                moment(messageDate).format('MM/DD/YYYY') +
                ' ' +
                moment(messageDate).format('HH:mm'),
              channelType: channel.channelType,
              isSenderStringMatched: isSenderStringMatched,
            });
          }
        }
      });
    }
    return searchResults;
  }

  getChannel(channelURL) {
    return this.state.groupChannels.find((channel) => channel.url === channelURL);
  }

  handleChannelNavigation = (channelURL, scrollToMessageId = null) => {
    const { profile, history } = this.props;
    const channel = this.getChannel(channelURL);

    if (channel) {
      SendBirdUser.NavigateToChannel(history, channelURL, null, false, scrollToMessageId);
    } else {
      this.showErrorMessage();
    }
  };

  showErrorMessage() {
    toast.error("Oops, we're having trouble accessing the Internet.");
  }

  render() {
    const { loading } = this.state;

    if (loading) {
      return this.renderWaiting();
    }

    return this.renderResults();
  }

  renderResults() {
    const { filteredSearchResults } = this.state;

    if (filteredSearchResults.length === 0) {
      return this.renderEmptyResults();
    }

    const fragments = filteredSearchResults.map((result, index) => {
      return this.renderItem(result, index);
    });

    return <div>{fragments}</div>;
  }

  renderItem(item, index) {
    const { profile, searchText } = this.props;

    return (
      <SearchChatResult
        index={index}
        userID={profile.id}
        customType={item.customType}
        name={item.name}
        url={item.url}
        messageText={item.messageContent}
        senderNickName={item.senderString}
        coverUrl={item.coverUrl || ''}
        members={item.members}
        channelDate={item.channelDate}
        searchText={searchText}
        onClick={() => this.handleChannelNavigation(item.url, item.messageId)}
      />
    );
  }

  renderEmptyResults() {
    const message =
      this.props.searchText.length > 2 ? 'No matching results' : 'Type at least three characters';

    return (
      <div style={styles.centering}>
        <Typography variant="h2" color="textSecondary">
          {message}
        </Typography>
      </div>
    );
  }

  renderWaiting() {
    return (
      <div style={styles.centering}>
        <CircularProgress />
      </div>
    );
  }
}

const styles = {
  centering: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 40,
  },
};

export default ChatSearch;
