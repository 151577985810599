import React from 'react';
import { Button, Typography } from '@material-ui/core';

import ROUTES from '../../../utils/consts';

import { ReactComponent as KidsPlaceHolder } from '../../../assets/images/kids-placeholder.svg';

export default class NoAddress extends React.Component {
  render() {
    const { message, history } = this.props;

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 10,
        }}
      >
        <Typography variant="h2">{'Enter your home address'}</Typography>
        <KidsPlaceHolder style={{ margin: 10 }} />
        {message && <Typography variant="subtitle2">{message}</Typography>}
        <Button
          color="primary"
          variant="outlined"
          style={{ margin: 20 }}
          onClick={() => {
            const location = {
              pathname: ROUTES.MY_PROFILE,
            };
            history.push(location);
          }}
        >
          {'Add your home address in My Profile'}
        </Button>
        <span style={{ textDecorationLine: 'underline' }}>
          <a
            href="http://ujama.co/privacy-commitment.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            {"Ujama's Privacy Commitment"}
          </a>
        </span>
      </div>
    );
  }
}
