import React from 'react';

export function TabPanel(props) {
  const { children, current, index } = props;

  return (
    <div role="tabpanel" hidden={current !== index}>
      {current === index && children}
    </div>
  );
}

export default TabPanel;
