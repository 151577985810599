import React, { Component } from 'react';
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import Highlighter from 'react-highlight-words';

import { getWhenString, maybeTrimString } from '../../../api/helpers/formatterHelper';

export default class SearchChatResult extends Component {
  render() {
    const {
      index,
      name,
      channelDate,
      messageText,
      searchText,
      senderNickName,
      coverUrl,
      onClick,
    } = this.props;

    const messageTextToDisplay = maybeTrimString(messageText, searchText);

    return (
      <ListItem button divider key={index} onClick={onClick}>
        <ListItemAvatar>
          <Avatar src={coverUrl} />
        </ListItemAvatar>
        <ListItemText
          inset
          primary={
            <div style={{ width: '90%' }}>
              <Highlighter
                highlightStyle={styles.textHighlight}
                searchWords={[searchText]}
                textToHighlight={name}
              />
            </div>
          }
          secondary={
            <div style={{ width: '90%' }}>
              <Highlighter
                highlightStyle={styles.textHighlight}
                searchWords={[searchText]}
                textToHighlight={`${senderNickName}: ${messageTextToDisplay}`}
              />
            </div>
          }
        />
        <ListItemSecondaryAction>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {getWhenString(channelDate)}
            <ChevronRight />
          </div>
        </ListItemSecondaryAction>
      </ListItem>
    );
  }
}

const styles = {
  textHighlight: {
    color: '#68CDDC',
    fontWeight: '800',
  },
};
