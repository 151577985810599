import { connect } from 'getstream';
import ROUTES from '../../utils/consts';
import APIUtils from '../APIUtils';

// We issue tokens that last a maximum of 24 hours
const MAX_TOKEN_TIME = 86400000;

const MAX_FEED_LENGTH = 5000;

class StreamUser {
  static streamData = null;
  static whenTokenAcquired = 0;

  static GetToken(profile, profileImageURI) {
    return new Promise((resolve, reject) => {
      const now = new Date().getTime();

      if (StreamUser.streamData && now - StreamUser.whenTokenAcquired < MAX_TOKEN_TIME) {
        resolve(StreamUser.streamData);
      } else {
        const url =
          ROUTES.URL_GET_STREAM_TOKEN +
          '?id=' +
          profile.id +
          '&name=' +
          encodeURIComponent(profile.name) +
          '&profileImage=' +
          encodeURIComponent(profileImageURI);

        APIUtils.getCloudWithTimeOut(url)
          .then((data) => {
            StreamUser.streamData = data;
            StreamUser.whenTokenAcquired = now;
            resolve(StreamUser.streamData);
          })
          .catch((error) => {
            console.log('Error getting Stream token', error);
            reject(error);
          });
      }
    });
  }

  static FollowSchool(profileID, schoolId) {
    return StreamUser.FollowEntity(profileID, 'schools', schoolId);
  }

  static UnfollowSchool(profileID, schoolId) {
    return StreamUser.UnfollowEntity(profileID, 'schools', schoolId);
  }

  static FollowActivity(profileID, orgId) {
    return StreamUser.FollowEntity(profileID, 'activities', orgId);
  }

  static UnfollowActivity(profileID, orgId) {
    return StreamUser.UnfollowEntity(profileID, 'activities', orgId);
  }

  // It is safe to call FollowEntity on an entity that is already followed
  // "Re-following a feed that is already followed has no effect."
  // https://getstream.io/activity-feeds/docs/node/following/
  static FollowEntity(profileID, feedGroup, feedId) {
    return new Promise((resolve, reject) => {
      const client = connect(
        StreamUser.streamData.apiKey,
        StreamUser.streamData.userToken,
        StreamUser.streamData.appId,
      );
      if (client) {
        client
          .feed('Ujama_Notification', profileID)
          .follow(feedGroup, feedId)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      } else {
        reject(new Error('Could not initialize Stream client'));
      }
    });
  }

  static UnfollowEntity(profileID, feedGroup, feedId) {
    return new Promise((resolve, reject) => {
      const client = connect(
        StreamUser.streamData.apiKey,
        StreamUser.streamData.userToken,
        StreamUser.streamData.appId,
      );
      if (client) {
        client
          .feed('Ujama_Notification', profileID)
          .unfollow(feedGroup, feedId)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      } else {
        reject(new Error('Could not initialize Stream client'));
      }
    });
  }

  static GetFollowedFeeds(profileID, resultsIn = []) {
    return new Promise((resolve, reject) => {
      const client = connect(
        StreamUser.streamData.apiKey,
        StreamUser.streamData.userToken,
        StreamUser.streamData.appId,
      );
      if (client) {
        client
          .feed('Ujama_Notification', profileID)
          .following({ offset: resultsIn.length, limit: 100 })
          .then((response) => {
            if (response.results && response.results.length) {
              const results = response.results ? resultsIn.concat(response.results) : resultsIn;

              StreamUser.GetFollowedFeeds(profileID, results)
                .then((resultsOut) => resolve(resultsOut))
                .catch((error) => reject(error));
            } else {
              resolve(resultsIn);
            }
          })
          .catch((error) => reject(error));
      } else {
        reject(new Error('GetFollowedFeeds could not initialize Stream client'));
      }
    });
  }

  // It is safe to call FollowPartner on a partner that is already followed
  // "Re-following a feed that is already followed has no effect."
  // https://getstream.io/activity-feeds/docs/node/following/
  static FollowPartner(profileID, partner) {
    return new Promise((resolve, reject) => {
      console.log('FOLLOWING PARTNER FEEDS---->', profileID, partner.name);

      const payload = {
        profileID,
        partner,
      };

      APIUtils.postCloud(ROUTES.URL_STREAM_FOLLOW_PARTNER, payload)
        .then((response) => resolve(response))
        .catch((error) => {
          console.warn('Error following partner feed', partner.id, error);
          reject(error);
        });
    });
  }

  static UnfollowPartner(profileID, partner) {
    return new Promise((resolve, reject) => {
      console.log('UNFOLLOWING PARTNER FEEDS---->', profileID, partner.name);

      const payload = {
        profileID,
        partner,
      };

      APIUtils.postCloud(ROUTES.URL_STREAM_UNFOLLOW_PARTNER, payload)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  static GetNotificationFeed(profileID, resultsIn = [], id_lt = undefined) {
    return new Promise((resolve, reject) => {
      const client = connect(
        StreamUser.streamData.apiKey,
        StreamUser.streamData.userToken,
        StreamUser.streamData.appId,
      );
      if (client) {
        client
          .feed('Ujama_Notification', profileID)
          .get({
            limit: 100,
            id_lt: id_lt,
            reactions: {
              recent: true,
              counts: true,
            },
          })
          .then((response) => {
            const results = response.results ? resultsIn.concat(response.results) : resultsIn;
            if (response.next && results.length < MAX_FEED_LENGTH) {
              const lastResult = response.results[response.results.length - 1];
              StreamUser.GetNotificationFeed(profileID, results, lastResult.id)
                .then((results) => resolve(results))
                .catch((error) => reject(error));
            } else {
              resolve(results);
            }
          })
          .catch((error) => reject(error));
      } else {
        reject(new Error('Could not initialize Stream client'));
      }
    });
  }

  static SubscribeToNotificationFeedChanges(profile, callback) {
    return new Promise((resolve, reject) => {
      StreamUser.GetToken(profile, profile.pic)
        .then(() => {
          const client = connect(
            StreamUser.streamData.apiKey,
            StreamUser.streamData.userToken,
            StreamUser.streamData.appId,
          );
          if (client) {
            client
              .feed('Ujama_Notification', profile.id)
              .subscribe(callback)
              .then((subscription) => resolve(subscription))
              .catch((error) => {
                console.warn('Unable to subscribe to notifications', error);
                reject(error);
              });
          } else {
            reject(new Error('Could not initialize Stream client'));
          }
        })
        .catch((error) => reject(error));
    });
  }

  static GetPostTotals(feedGroup, feedId, userID) {
    return new Promise((resolve, reject) => {
      StreamUser.GetNotificationFeed(userID).then((feedNotifications) => {
        let postTotal = 0;
        let announcementTotal = 0;

        feedNotifications.forEach((result) => {
          if (result.activities && !result.is_read) {
            result.activities.forEach((activity) => {
              if (activity.verb === 'post') {
                if (activity.origin === `${feedGroup}:${feedId}`) {
                  postTotal++;
                }
                if (activity.origin === `${feedGroup}:${feedId}-announcements-sys--`) {
                  announcementTotal++;
                }
              }
            });
          }
        });

        resolve({ postTotal, announcementTotal });
      });
    });
  }

  static GetTopicsFeed(feedGroup, feedId, topics, userID) {
    return new Promise((resolve, reject) => {
      StreamUser.GetNotificationFeed(userID)
        .then((feedNotifications) => {
          const topicList = [];

          for (let key in topics) {
            const topic = topics[key];

            topic.newPostCount = 0;

            feedNotifications.forEach((result) => {
              if (result.activities && !result.is_read) {
                result.activities.forEach((activity) => {
                  if (
                    activity.verb === 'post' &&
                    activity.origin === `${feedGroup}:${feedId}-topic-${key}`
                  ) {
                    topic.newPostCount++;
                  }
                });
              }
            });

            topicList.push(topic);
          }
          resolve(topicList);
        })
        .catch((error) => reject(error));
    });
  }

  static DeleteActivity(feedGroup, feedId, activityId) {
    return new Promise((resolve, reject) => {
      const payload = {
        feedGroup: feedGroup,
        feedId: feedId,
        activityId: activityId,
      };

      APIUtils.postCloud(ROUTES.URL_STREAM_DELETE_ACTIVITY, payload)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  static EditActivity(feedGroup, feedId, activityId, update) {
    return new Promise((resolve, reject) => {
      const payload = {
        feedGroup: feedGroup,
        feedId: feedId,
        activityId: activityId,
        update: update,
      };

      APIUtils.postCloud(ROUTES.URL_STREAM_EDIT_ACTIVITY, payload)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  static Reset() {
    console.log('RESET STREAM TOKEN---->');
    StreamUser.streamData = null;
    StreamUser.whenTokenAcquired = 0;
  }
}

export default StreamUser;
