/* global grecaptcha */
import React from 'react';
import firebase from 'firebase/app';

import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';

import { toast } from 'react-toastify';

import VersionCheck from '../../../api/helpers/version-check';
import { formatPhoneNumber } from '../../../api/helpers/formatterHelper';
import { firebaseSecondary } from '../../../api/firebase/firebase';
import { ROUTES } from '../../../utils/consts';
import APIUtils from '../../../api/APIUtils';

export default class DeleteProfileModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalVisible: false,
      cancelVisible: true,
      handler: null,
      page: 'sendCode',
      countryCode: '',
      phone: '',
      verifyCode: '',
      codeRequested: false,
      codeConfirmed: false,
      deletePressed: false,
      showWaiting: false,
    };
  }

  setupRecaptchaVerifier() {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('send-code-button', {
      size: 'invisible',
      callback: (response) => {
        this.onSendCode();
      },
    });
  }

  launch(countryCode, phone, handler) {
    this.setState({
      modalVisible: true,
      cancelVisible: true,
      codeRequested: false,
      codeConfirmed: false,
      deletePressed: false,
      page: 'sendCode',
      failedReason: '',
      handler,
      countryCode,
      phone,
    });

    setTimeout(this.setupRecaptchaVerifier, 200);
  }

  onChange = () => {
    this.state.handler();
    this.setState({ modalVisible: false });
  };

  onSendCode = () => {
    const { phone, countryCode } = this.state;

    toast.info(`Sending code to +${countryCode} ${formatPhoneNumber(phone)}...`);
    this.setState({ showWaiting: true, codeRequested: true });

    firebaseSecondary
      .auth()
      .signInWithPhoneNumber(`+ ${countryCode} ${phone}`, window.recaptchaVerifier)
      .then((confirmResult) => {
        this.confirmResult = confirmResult;
        this.setState({ page: 'verifyCode', showWaiting: false });
      })
      .catch((error) => {
        toast.error("Sorry that didn't work! Please try again.\n\nError: " + error.message);

        window.recaptchaVerifier.render().then((widgetId) => {
          grecaptcha.reset(widgetId);
        });

        this.setState({ showWaiting: false, codeRequested: false });
      });
  };

  handleVerifyCode = (event) => {
    const target = event.target;

    if (target.value.length <= 6) {
      this.setState({
        verifyCode: target.value,
      });
    }
  };

  onConfirmCode = () => {
    const { verifyCode } = this.state;

    this.setState({ showWaiting: true, codeConfirmed: true });
    this.confirmResult
      .confirm(verifyCode)
      .then(() => {
        toast.success('Code confirmed!');
        this.setState({ page: 'confirm', showWaiting: false });
      })
      .catch((error) => {
        toast.error(`Sorry that didn't work. Code confirm error: ${error.message}`);
        this.setState({ showWaiting: false, codeConfirmed: false });
      });
  };

  onConfirmDelete = () => {
    this.setState({ showWaiting: true, deletePressed: true });

    APIUtils.postCloudWithTimeOut(ROUTES.URL_DELETE_MY_ACCOUNT, {})
      .then((responseJson) => {
        VersionCheck.read((versionObj) => {
          this.feedbackURL = versionObj.feedbackURL;
          this.setState({ page: 'complete', cancelVisible: false, showWaiting: false });
        });
      })
      .catch((error) => {
        console.warn('Error deleting account', error);
        if (error.response) {
          this.setState({ page: 'failed', failedReason: error.response.data.error });
        } else {
          toast.error(
            `Sorry we encountered an error while deleting the account. Please try again later.\n\n${error}`,
          );
        }
        this.setState({ showWaiting: false, deletePressed: false });
      });
  };

  render() {
    const { modalVisible, cancelVisible } = this.state;

    return (
      <Dialog open={modalVisible}>
        <DialogTitle>
          <span style={{ fontSize: 24, fontWeight: '600' }}>{'Delete Account'}</span>
        </DialogTitle>
        <DialogContent style={{ minWidth: 600 }}>{this.renderPage()}</DialogContent>
        {this.renderWaiting()}
        <DialogActions>
          {cancelVisible && (
            <Button onClick={() => this.setState({ modalVisible: false })} color="primary">
              {'Cancel'}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }

  renderWaiting() {
    if (this.state.showWaiting) {
      return (
        <div style={{ flex: 1, alignItems: 'center' }}>
          <CircularProgress />
        </div>
      );
    }
  }

  renderPage() {
    const pageMap = [
      { name: 'sendCode', renderer: this.renderSendCode },
      { name: 'verifyCode', renderer: this.renderVerifyCode },
      { name: 'confirm', renderer: this.renderConfirm },
      { name: 'complete', renderer: this.renderComplete },
      { name: 'failed', renderer: this.renderFailed },
    ];
    const page = pageMap.find((page) => this.state.page === page.name);

    if (page) {
      return page.renderer();
    }

    return null;
  }

  renderSendCode = () => {
    const { phone, countryCode, codeRequested } = this.state;

    return (
      <Card>
        <CardContent>
          <Typography>
            {
              'Ujama will send a delete verification code (via SMS text message) to this phone number:'
            }
          </Typography>
          <div style={{ height: 10 }} />
          <Typography color="textSecondary">{`+${countryCode} ${formatPhoneNumber(
            phone,
          )}`}</Typography>
          <div style={{ height: 20 }} />
          <Button
            id="send-code-button"
            variant="contained"
            color="primary"
            disabled={codeRequested}
            onClick={this.onSendCode}
          >
            {'Send Code'}
          </Button>
        </CardContent>
      </Card>
    );
  };

  renderVerifyCode = () => {
    const { countryCode, phone, verifyCode, codeConfirmed } = this.state;

    return (
      <Card>
        <CardContent>
          <Typography>
            {`Please enter the verification code we just sent to +${countryCode} ${formatPhoneNumber(
              phone,
            )}:`}
          </Typography>
          <div style={{ height: 10 }} />
          <TextField
            autoFocus={true}
            type="text"
            value={verifyCode}
            label="Enter the 6-digit code"
            style={{ width: '50%', minWidth: 200 }}
            onChange={this.handleVerifyCode}
          />
          <div style={{ height: 20 }} />
          <Button
            variant="contained"
            color="primary"
            onClick={this.onConfirmCode}
            disabled={verifyCode.length < 6 || codeConfirmed}
          >
            {'Confirm Code'}
          </Button>
        </CardContent>
      </Card>
    );
  };

  renderConfirm = () => {
    const { deletePressed } = this.state;

    return (
      <Card>
        <CardContent>
          <Typography>
            {
              'When you click the "Delete Account" button below, your Ujama account will be deleted. Please note the following:'
            }
          </Typography>
          <div style={{ height: 10 }} />
          <List dense={true}>
            <ListItem>
              <ListItemIcon>
                <WarningIcon color="secondary" />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ style: { whiteSpace: 'normal' } }}
                primary="All data associated with your Ujama account will be removed."
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <WarningIcon color="secondary" />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ style: { whiteSpace: 'normal' } }}
                primary="You will no longer have access to or receive messages from any chat groups or communities that you are a member of."
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <WarningIcon color="secondary" />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ style: { whiteSpace: 'normal' } }}
                primary="Your name will be removed from any Ujama community and neighborhood directories."
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <WarningIcon color="secondary" />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ style: { whiteSpace: 'normal' } }}
                primary="This operation cannot be undone."
              />
            </ListItem>
          </List>
          <div style={{ height: 20 }} />
          <Button
            variant="contained"
            color="secondary"
            onClick={this.onConfirmDelete}
            disabled={deletePressed}
          >
            {'Delete Account'}
          </Button>
        </CardContent>
      </Card>
    );
  };

  renderComplete = () => {
    return (
      <Card>
        <CardContent>
          <Typography>{'Your account has been deleted.'}</Typography>
          <div style={{ height: 20 }} />
          <Button variant="contained" color="primary" onClick={() => (window.location = '/')}>
            {'Go to the Ujama Login Page'}
          </Button>
        </CardContent>
        <CardContent>
          <a style={{ textDecorationLine: 'underline' }} href={this.feedbackURL}>
            Please give us your feedback
          </a>
        </CardContent>
      </Card>
    );
  };

  renderFailed = () => {
    return (
      <Card>
        <CardContent>
          <Typography variant="h4" color="secondary">
            {'Ujama was unable to delete your account.'}
          </Typography>
          <div style={{ height: 20 }} />
          <Typography>{this.state.failedReason}</Typography>
        </CardContent>
      </Card>
    );
  };
}
