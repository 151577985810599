import React from 'react';
import { withRouter } from 'react-router';
import { EntypoChevronSmallDown } from 'react-entypo';

import {
  Avatar,
  Paper,
  ClickAwayListener,
  Popper,
  Grow,
  MenuList,
  MenuItem,
  Divider,
  IconButton,
} from '@material-ui/core';
import { VpnLock, Refresh } from '@material-ui/icons';
import { ReactComponent as MyProfile } from '../../../assets/icons/profile.svg';
import { ReactComponent as CommunityPortal } from '../../../assets/icons/community.svg';
import { ReactComponent as GiveFeedback } from '../../../assets/icons/feedback.svg';
import { ReactComponent as Logout } from '../../../assets/icons/logout.svg';
import AuthHelper from '../../../api/helpers/auth';
import ProfileStorage from '../../../api/storage/profile';
import VersionCheck from '../../../api/helpers/version-check';
import ROUTES from '../../../utils/consts';

//import './navbar.css';
import ProfileDefault from '../../../assets/images/profile-default.png';

import URLHelper from '../../../api/helpers/url-helper';

class Right extends React.Component {
  constructor(props) {
    super(props);
    this.anchor = React.createRef();
    this.state = {
      pic: ProfileDefault,
      profile: {},
      open: false,
      anchor: null,
    };
  }

  componentDidMount() {
    this.readData();

    this.updateListener = ProfileStorage.RegisterUpdateListener((profile) => {
      this.setState({ profile, pic: (profile && profile.pic) || ProfileDefault });
    });
  }

  componentWillUnmount() {
    ProfileStorage.UnregisterUpdateListener(this.updateListener);
  }

  readData() {
    AuthHelper.getProfileDetails().then((profile) => {
      if (profile) {
        this.setState({
          profile,
          pic: (profile && profile.pic) || ProfileDefault,
        });
      }
    });
  }

  handleToggleMenu = (event) => {
    this.setState((prevState) => ({
      open: !prevState.open,
      anchor: document.getElementById('navigation-dropdown-anchor'),
    }));
  };

  handleClickAway = () => {
    this.setState({
      open: false,
      anchor: null,
    });
  };

  render() {
    const { history } = this.props;
    return (
      <>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <div>
            <IconButton
              style={styles.rightButton}
              title="Refresh to get the latest data"
              onClick={() => {
                AuthHelper.refreshProfileDetails(this.state.profile.id).then(() =>
                  window.location.reload(),
                );
              }}
            >
              <Refresh />
            </IconButton>
            <a
              href="http://ujama.co/privacy-commitment.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconButton style={styles.rightButton} title="Learn how Ujama protects your privacy">
                <VpnLock />
              </IconButton>
            </a>
          </div>
          <div
            id="navigation-dropdown"
            style={{ display: 'flex', alignItems: 'center' }}
            onClick={this.handleToggleMenu}
          >
            <Avatar
              src={this.state.pic}
              id="navigation-dropdown-anchor"
              style={{ height: 36, width: 36, marginLeft: 10, marginRight: 4 }}
            />
            <span>Me</span> <EntypoChevronSmallDown valign="middle" />
          </div>
        </div>
        <Popper
          open={this.state.open}
          anchorEl={this.state.anchor}
          role="navigation"
          transition
          placement="bottom-end"
          popperOptions={{
            modifiers: {
              offset: {
                offset: '81,7',
              },
            },
          }}
          style={{
            position: 'absolute',
            right: 0,
            width: 200,
            zIndex: 9999,
          }}
        >
          {({ TransitionProps, placement }) => (
            <Grow {...TransitionProps}>
              <Paper elevation={4}>
                <ClickAwayListener onClickAway={this.handleClickAway}>
                  <div className="account-options">
                    <MenuList style={{ padding: 0 }}>
                      <MenuItem
                        onClick={() => {
                          this.handleClickAway();
                          history.push(ROUTES.MY_PROFILE);
                        }}
                        key="menuitem_profile"
                        style={styles.menuItem}
                      >
                        <MyProfile /> My Profile
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          this.handleClickAway();
                          this.props.history.push(ROUTES.COMMUNITY_PORTAL);
                        }}
                        key="menuitem_community_portal"
                        style={styles.menuItem}
                      >
                        <CommunityPortal /> Community Portal
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          this.handleClickAway();
                          this.onGiveFeedback();
                        }}
                        key="menuitem_give_feedback"
                        style={styles.menuItem}
                      >
                        <GiveFeedback /> Give Feedback
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        onClick={() => {
                          this.handleClickAway();
                          this.initiateLogout();
                        }}
                        key="menuitem_logout"
                        style={styles.menuItem}
                      >
                        <Logout /> Logout
                      </MenuItem>
                    </MenuList>
                  </div>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
    );
  }

  initiateLogout() {
    URLHelper.remove();
    AuthHelper.handleLogout();
  }

  onGiveFeedback() {
    const feedbackWindow = window.open('', 'Ujama Feedback', 'height=600, width=600');
    VersionCheck.read((versionObj) => {
      feedbackWindow.location = versionObj.feedbackURL;
    });
  }
}

const styles = {
  menuItem: {
    textAlign: 'left',
    fontSize: 14,
  },
  rightButton: {
    width: 20,
    height: 20,
  },
};

export default withRouter(Right);
