// Base URL for the Cloud Functions
const BASE_URL_CF = process.env.REACT_APP_API_BASE_URL || '';
export const IS_DEV =
  typeof process.env.REACT_APP_API_BASE_URL === 'string' &&
  process.env.REACT_APP_ENV_NAME !== 'production';
export const FETCH_TIMEOUT = 20000;

export const ROUTES = {
  LOGIN: '/login',
  LOGIN_REDIRECT: '/login-redirect',
  CREATE_PROFILE: '/create-profile',
  MY_PROFILE: '/my-profile',
  DASHBOARD: '/',
  PARENT: '/parent',
  SCHOOL: '/school',
  ACTIVITY: '/activity',
  KID: '/kid',
  KID_VIEWER: '/kid-viewer',
  FIND_SCHOOLS: '/find-schools',
  FIND_PARENTS: '/find-parents',
  VERIFY_PARENTS: '/verify-parents',
  CALENDAR_LOGS: '/calendar-logs',
  FIND_ACTIVITIES: '/find-activities',
  PHONE_AUTH: '/phone-auth',
  MESSAGE_CENTER: '/messages',
  NEW_CHAT: '/messages/new-chat',
  NEW_GROUP_CHAT: '/messages/new-group-chat',
  CHAT: '/messages/chat',
  CONTACT: '/contact',
  FIND_CONTACTS: '/find-contacts',
  CONTACT_PORTAL: '/contact-portal',
  CUSTOM_NOTIFICATION_TEMPLATE: '/custom-notification-template',
  REDIRECT_LINK: '/mobile',
  UNSUB_MONTHLY: '/unsubscribe-monthly',
  UNSUB_UNREAD: '/unsubscribe-unread',
  UNSUB_UNREADINACTIVE: '/unsubscribe-unread-inactive',
  DUPLICATE_PROFILE: '/duplicate-profile',
  INACTIVE_PROFILE_FIX_THANKS: '/thanks-for-merging',
  SHOW_MAP: '/show-map',
  BRANCHIO_ROUTER: '/branchio-router',

  PARTNER_LANDING: '/partner-landing',
  SCHOOL_LANDING: '/school-landing',
  ACTIVITY_LANDING: '/activity-landing',
  NEIGHBORHOOD_LANDING: '/neighborhood-landing',
  SHOW_ALL_COMMUNTIES: '/show-all-communities',

  MARKETPLACE: '/marketplace',
  MARKETPLACE_VIEWER: '/marketplace-viewer',
  MARKETPLACE_SETTINGS: '/marketplace-settings',

  PARTNER_VIEWER: '/partner-viewer',
  CHAPTER_LIST: 'chapter-list',
  CHAPTER_EDIT: 'chapter-edit',

  SOCIAL_POST: '/social-post',

  VIDEO_CHAT: '/video-chat',

  PAY: '/messages/chat/checkout/pay',
  PAY_OFFLINE: '/messages/chat/pay-offline',
  INVOICE: '/messages/chat/invoice',
  ORDER: '/messages/chat/order',

  ADD_KID: '/add-kid',
  ADD_MEETUP: '/add-meetup',
  PLAYDATE_CHAT: '/add-playdate',
  ASK_FOR_RIDE: '/ask-for-ride',
  ASK_FOR_BABYSITTER: '/ask-for-babysitter',
  OFFER_RIDE: '/offer-ride',

  ADDRESS: '/address',
  DUAL_ENTRY: '/dual-entry',
  GRADE: '/add-grade',
  RIDE_DETAIL: '/ride-detail',
  KID_SELECT: '/kid-select',
  PHOTO_GALLERY: '/photo-gallery',
  COMMUNITY_PORTAL: '/community-portal',
  HOLIDAYS: '/community-portal/holidays',
  ALTERNATIVE_TIMINGS_LIST: '/community-portal/alt-list',
  PERIODS: '/community-portal/periods',
  PERIODS_SELECT: '/periods-select',
  BELLS: '/community-portal/bells',
  SPECIAL_DATES: '/community-portal/special-days',

  HOLIDAY_EDIT: '/community-portal/holidays/edit',
  ALT_EDIT: '/community-portal/alt-list/edit',
  BELL_EDIT: '/community-portal/bells/edit',
  SPECIAL_EDIT: '/community-portal/special-days/edit',

  ACTIVITY_LOCATION_LIST: '/community-portal/activity-location-list',
  SESSIONS_LIST: '/community-portal/sessions-list',
  ACTIVITY_CLASS_LIST: '/community-portal/activity-class-list',

  LOCATION_EDIT: '/community-portal/location-edit',
  SESSION_EDIT: '/community-portal/session-edit',
  CLASS_EDIT: '/community-portal/activity-class-edit',

  MESSAGE_SUBSCRIPTIONS: '/subscribe',

  FIND_SCHOOLS_LIST: '/find-schools-list',
  SCHOOL_LIST: '/community-portal/list-schools',
  ORGANIZATION_LIST: '/community-portal/list-organizations',
  MARKET_LIST: '/community-portal/list-markets',
  EDIT_MARKET: '/community-portal/market-edit/',
  SCHOOL_PROFILE: '/community-portal/school-profile/',
  ADD_SCHOOL: '/community-portal/add-school/',
  QUICK_ADD_SCHOOL: '/community-portal/quick-add-school/',
  ACTIVITY_TYPES_LIST: '/community-portal/activity-types-list',
  ACTIVITY_PROFILE: '/community-portal/activity/',
  ADD_ACTIVITY: '/community-portal/add-activity',
  ORGANIZATION_PROFILE: '/community-portal/organization-profile/',
  ADD_ORGANIZATION: '/community-portal/add-organization/',
  KARMA_POINTS: '/community-portal/karma-points-list',
  ADD_KARMA_PACKAGE: '/community-portal/add-karma-package',
  EDIT_KARMA_PACKAGE: '/community-portal/edit-karma-package/',
  NOTIFICATION_LIST: '/community-portal/notification-list',
  NOTIFICATION_EDIT: '/community-portal/notification-edit/',
  KIDS_LIST: '/community-portal/kids-list',
  EDIT_KID: '/community-portal/edit-kid/',
  INVITATION_LIST: '/community-portal/invitation-list',
  EDIT_INVITATION: '/community-portal/edit-invitation/',
  PROFILE_LIST: '/community-portal/profile-list',
  EDIT_PROFILE: '/community-portal/edit-profile/',
  CARPOOL_LIST: '/community-portal/carpool-list',
  EDIT_CARPOOL: '/community-portal/edit-carpool/',
  CHAT_WORK_FLOW_LIST: '/community-portal/chat-work-flow-list',
  EDIT_CHAT_WORK_FLOW: '/community-portal/edit-chat-work-flow/',
  PARENTS_LIST: '/community-portal/parents-list',
  FIND_ORGANIZATIONS: '/find-organizations',
  MAIL_MANAGER: '/community-portal/mail-manager/',
  ANALYTICS: '/community-portal/analytics/',
  REQUESTS_PANEL: '/community-portal/requests-panel/',
  MANAGE_PROMOTIONS: '/community-portal/promotion-list/',
  EDIT_PROMOTION: '/community-portal/promotion-edit/',
  MANAGE_PARTNERS: '/community-portal/partner-list/',
  EDIT_PARTNER: '/community-portal/partner-edit/',
  ADMIN_SEARCH_PROFILES: '/community-portal/admin-search-profiles/',
  ADMIN_SHOW_MEMBERSHIP_LISTS: '/community-portal/show-membership-lists/',
  ADMIN_SENDBIRD_MIGRATION: '/community-portal/sendbird-migration/',
  ADMIN_DUPLICATE_SCHOOL: '/fix-duplicates',
  ADMIN_DUPLICATE_ORG: '/fix-duplicate-org',
  ADMIN_DUPLICATE_PROFILE: '/merge-profiles',
  EMAIL_DUPLICATE_LINK: '/duplicate-profile',
  DUPLICATE_PROFILE_LIST: '/duplicate-profile-list',
  HOLIDAY_PAGE: '/happy-holidays',

  AVAILABILITY_POLL: '/availability-poll',
  SIGNUP_FORM: '/signup-form',
  EVENT_CREATOR: '/event-creator',
  WEBINAR_CREATOR: '/webinar-creator',
  COLLECT_MONEY_CREATOR: '/collect-money-creator',
  GENERIC_REQUEST: '/generic-request',
  FOR_SALE_CREATOR: '/for-sale-creator',

  /*
    THESE ARE URLs to different CloudFunctions
  */
  //Search Endpoints
  SCHOOL_SEARCH: BASE_URL_CF + 'searchSchoolsSecure',
  ORG_SEARCH: BASE_URL_CF + 'searchOrganizationsSecure',
  PARENT_SEARCH: BASE_URL_CF + 'searchParentsSecure',

  //Find filtering end points
  SEND_EMAIL: BASE_URL_CF + 'sendEmailMessage',
  FIND_SCHOOLS_API: BASE_URL_CF + 'findSchoolsSecure',
  FIND_ORGS_API: BASE_URL_CF + 'findOrgsSecure',
  FIND_PARENTS_API: BASE_URL_CF + 'findParentsSecure',
  URL_FIND_MARKETS: BASE_URL_CF + 'findMarkets',
  FIND_MEETUPS_API: BASE_URL_CF + 'findMeetups',
  URL_FIND_NEAREST_CHAPTER: BASE_URL_CF + 'findNearestPartnerChapterForProfile',

  //Request Endpoint
  URL_VERIFICATION_REQUEST: BASE_URL_CF + 'addVerificationRequest',
  URL_TRIGGER_SIGNUP: BASE_URL_CF + 'parentSignupTriggerPost',
  GET_PROFILE_FOR_CONTACT: BASE_URL_CF + 'getProfileForContact',
  URL_ADD_UNACTIVATED_ACCOUNT_POST: BASE_URL_CF + 'addUnactivatedAccountPost',
  URL_SEND_TEXT_MESSAGE: BASE_URL_CF + 'sendTextMessage',

  URL_CHECK_PROFILE: BASE_URL_CF + 'checkProfile',
  URL_SAVE_INACTIVE_MESSAGE_SUBSCRIPTION: BASE_URL_CF + 'saveInactiveMessageSubscription',
  URL_MERGE_SENDBIRD_USERS: BASE_URL_CF + 'mergeSendBirdUsers',

  // Chat Widgets
  URL_RESPOND_TO_PLAYDATE: BASE_URL_CF + 'respondToPlaydate',
  URL_CANCEL_PLAYDATE: BASE_URL_CF + 'cancelPlaydate',
  URL_ACCEPT_RIDE_REQUEST: BASE_URL_CF + 'acceptRideRequest',
  URL_ACCEPT_BABYSITTER_REQUEST: BASE_URL_CF + 'acceptBabysitterRequest',
  URL_ACCEPT_GENERIC_REQUEST: BASE_URL_CF + 'acceptGenericRequest',
  URL_ACCEPT_RIDE_OFFER: BASE_URL_CF + 'acceptRideOffer',

  // Stripe
  URL_GET_STRIPE_CONNECTED_ACCOUNT_ID: BASE_URL_CF + 'getStripeConnectedAccountID',
  URL_GET_STRIPE_CHECKOUT_SESSION_ID: BASE_URL_CF + 'getStripeCheckoutSessionID',
  URL_SUBMIT_STRIPE_CHARGE: BASE_URL_CF + 'submitStripeCharge',

  // Stream
  URL_GET_STREAM_TOKEN: BASE_URL_CF + 'getStreamToken',
  URL_STREAM_FOLLOW_PARTNER: BASE_URL_CF + 'streamFollowPartner',
  URL_STREAM_UNFOLLOW_PARTNER: BASE_URL_CF + 'streamUnfollowPartner',
  URL_STREAM_DELETE_ACTIVITY: BASE_URL_CF + 'streamDeleteActivity',
  URL_STREAM_EDIT_ACTIVITY: BASE_URL_CF + 'streamEditActivity',

  URL_TRANSFER_UPENDO_POINTS: BASE_URL_CF + 'transferUpendoPoints',

  URL_GET_TWILIO_VIDEO_TOKEN: BASE_URL_CF + 'getTwilioVideoToken',

  // URL for GetSschedule
  URL_GET_SCHEDULE: BASE_URL_CF + 'getScheduleSecurely',

  // Delete account
  URL_DELETE_MY_ACCOUNT: BASE_URL_CF + 'deleteMyAccount',
};

export const CHAT_INDEX = {
  NEW_KID: 1,
  MORE_ACTIVITY: 15,
  ACTIVITY_CHAT: 18,
  ALL_GRADES: 21,
  DIRECT_SCHOOL: 22,
  DIRECT_ACTIVITY: 23,
  PLAYDATE_START: 14,
  BABYSITTING_START: 14,
  RIDE_START: 14,
  PLAYDATE_NEWCHAT_START: 19,
  RIDE_NEWCHAT_START: 23,
  BABYSITTER_NEWCHAT_START: 19,
};

export const VISIBILITY = {
  NONE: 0,
  AFFILIATED: 1,
  PUBLIC: 2,
};

export const SLACK_TIME = 1000 * 60 * 3;

export const STRIPE_GO_TO_CHECKOUT_URL = window.location.origin + '/stripe-go-to-checkout';
export const STRIPE_SUCCESS_URL = window.location.origin + '/stripe-payments-success';
export const STRIPE_FAILURE_URL = window.location.origin + '/stripe-payments-failure';

export default ROUTES;
