import React from 'react';
import logo from '../../assets/icons/ujama.svg';
import mail from '../../assets/icons/mail.svg';

export default function Footer(props) {
  return (
    <div style={props.isMobile ? styles.footerMobile : styles.footer} id="footer">
      <div style={styles.wrap}>
        <img src={logo} alt="Ujama logo" style={styles.icon} /> &copy; {new Date().getFullYear()}{' '}
        Ujama Inc.
        <br />
        <img src={mail} alt="Mail address" style={styles.icon} /> 19925 Stevens Creek Blvd, Suite
        100, Cupertino CA 95014
        <br />
        <a target="_blank" rel="noopener noreferrer" href="http://ujama.co/privacy.pdf">
          Privacy
        </a>{' '}
        and{' '}
        <a target="_blank" rel="noopener noreferrer" href="http://ujama.co/terms.pdf">
          Terms
        </a>
      </div>
    </div>
  );
}

const styles = {
  wrap: {
    maxWidth: '90%',
    margin: '0 5%',
    color: '#B1B1B1',
    lineHeight: '32px',
  },
  footer: {
    padding: '30px 0 50px',
    background: '#f2f2f2',
  },
  footerMobile: {
    padding: '30px 14px 100px',
    background: '#f2f2f2',
  },
  icon: {
    width: '20px',
    verticalAlign: 'middle',
    margin: 4,
  },
};
