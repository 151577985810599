import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { IconButton, Dialog, DialogContent, Tooltip, Typography, Divider } from '@material-ui/core';
import { Close, FilterNone, Share } from '@material-ui/icons';

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  EmailIcon,
} from 'react-share';

export default class ShareModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalVisible: false,
      url: '',
      title: '',
    };
  }

  shareLink(url, title) {
    this.setState({ isModalVisible: true, url, title });
  }

  onCancel = () => {
    this.setState({ isModalVisible: false });
  };

  onCopyToClipboard = () => {
    navigator.clipboard.writeText(this.state.url);
    toast.success('The invitation link has been copied to the clipboard');
  };

  render() {
    const { isModalVisible, url, title } = this.state;

    return (
      <Dialog open={isModalVisible}>
        <DialogContent>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
              <Share color="primary" style={{ marginRight: 10 }} />
              <Typography variant="h2" color="primary">
                {'Share'}
              </Typography>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Tooltip title="Close">
                <IconButton size="small" onClick={this.onCancel}>
                  <Close fontSize="small" />
                </IconButton>
              </Tooltip>
            </div>
          </div>

          <Divider style={{ marginTop: 10, marginBottom: 10 }} />

          {title && (
            <Typography variant="subtitle1" style={{ textAlign: 'center' }}>
              {title}
            </Typography>
          )}

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexWrap: 'wrap',
            }}
            onClick={this.onCancel}
          >
            <Tooltip title="Email">
              <EmailShareButton url={url} style={styles.button}>
                <EmailIcon size={40} round />
              </EmailShareButton>
            </Tooltip>
            <Tooltip title="Facebook">
              <FacebookShareButton url={url} style={styles.button}>
                <FacebookIcon size={40} round />
              </FacebookShareButton>
            </Tooltip>
            <Tooltip title="LinkedIn">
              <LinkedinShareButton url={url} style={styles.button}>
                <LinkedinIcon size={40} round />
              </LinkedinShareButton>
            </Tooltip>
            <Tooltip title="Twitter">
              <TwitterShareButton url={url} style={styles.button}>
                <TwitterIcon size={40} round />
              </TwitterShareButton>
            </Tooltip>
            <Tooltip title="Copy to the clipboard">
              <IconButton size="small" onClick={this.onCopyToClipboard}>
                <FilterNone />
              </IconButton>
            </Tooltip>
          </div>
          <div style={{ height: 20 }} />
        </DialogContent>
      </Dialog>
    );
  }
}

const styles = {
  button: {
    margin: 10,
  },
};
