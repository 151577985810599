import FirebaseManager from '../firebase/firebase-manager';

export default class SchoolStorage {
  static GetEmptySchool() {
    return {
      id: 0,
      name: '',
      street: '',
      city: '',
      state: '',
      zip: '',
      country: '',
      grades: [],
      holidays: [],
      startDate: '',
      endDate: '',
    };
  }

  static ReadList(callback) {
    const firebaseManager = new FirebaseManager();
    var schoolArray = [];

    firebaseManager.readOnce('schools', (snap) => {
      snap.forEach((child) => {
        let school = child.val();
        if (school != null) {
          schoolArray.push(school);
        }
      });

      callback(schoolArray);
    });
  }

  static Read(schoolID, callback) {
    const firebaseManager = new FirebaseManager();

    firebaseManager.readOnce('schools/' + schoolID, (snap) => {
      let school = snap.exists() ? snap.val() : null;
      if (callback) {
        callback(school);
      }
    });
  }

  static ReadVerifiedParents(schoolID, callback) {
    const firebaseManager = new FirebaseManager();

    firebaseManager.readOnce('schools/' + schoolID + '/verifiedList', (snap) => {
      let verifiedList = snap.exists() ? snap.val() : {};
      if (callback) {
        callback(verifiedList);
      }
    });
  }

  static schoolSearch(field, query, pageSize, pageNum, callback) {
    const firebaseManager = new FirebaseManager();
    firebaseManager.searchSub('/schools', field, query, pageSize, pageNum, (arr) => {
      callback(arr);
    });
  }

  static Add(school, callback) {
    const firebaseManager = new FirebaseManager();

    const schoolId = firebaseManager.saveData('schools', school);
    // We'll set the id to be the unique key created by Firebase
    school.id = schoolId;
    if (callback) {
      firebaseManager.update('schools/' + schoolId, school).then(() => {
        if (callback) {
          callback();
        }
      });
    }
    return schoolId;
  }

  static Update(schoolId, school, callback) {
    SchoolStorage.GetKeyFromID(schoolId, (key) => {
      const firebaseManager = new FirebaseManager();

      firebaseManager.update('schools/' + key, school).then(() => {
        if (callback) {
          callback();
        }
      });
    });
  }

  static UpdateVerifiedParent(schoolId, parentId, verifiedData, callback) {
    SchoolStorage.GetKeyFromID(schoolId, (key) => {
      const firebaseManager = new FirebaseManager();
      firebaseManager
        .update('schools/' + key + '/verifiedList/' + parentId, verifiedData)
        .then(() => {
          if (callback) {
            callback();
          }
        });
    });
  }

  static DeleteVerifiedParent(schoolId, parentId, callback) {
    SchoolStorage.GetKeyFromID(schoolId, (key) => {
      const firebaseManager = new FirebaseManager();
      firebaseManager.deleteValue('schools/' + key + '/verifiedList/' + parentId, () => {
        if (callback) {
          callback();
        }
      });
    });
  }

  static Delete(schoolId, callback) {
    const firebaseManager = new FirebaseManager();

    SchoolStorage.GetKeyFromID(schoolId, (key) => {
      firebaseManager.deleteValue('schools/' + key, callback);
    });
  }
  static GetKeyFromID(schoolId, callback) {
    const firebaseManager = new FirebaseManager();

    firebaseManager.readOnce('schools', (snap) => {
      snap.forEach((child) => {
        let school = child.val();
        if (school != null && school.id === schoolId) {
          callback(child.key);
          return;
        }
      });
    });
  }

  static GetNameFromID(schoolID, callback) {
    // Return an empty string if the school ID is 0
    if (schoolID === 0) {
      callback('');
      return;
    }

    SchoolStorage.Read(schoolID, (school) => {
      callback(school.name);
    });
  }

  static GetIDFromName(schoolName, callback) {
    // Return an id of zero if the string is empty
    if (!schoolName[0]) {
      callback(0);
      return;
    }

    const firebaseManager = new FirebaseManager();

    firebaseManager.readOnce('schools', (snap) => {
      snap.forEach((child) => {
        let school = child.val();
        if (school !== null && school.name === schoolName) {
          callback(school.id);
          return;
        }
      });
    });
  }

  static GetGrades(schoolID, callback) {
    SchoolStorage.Read(schoolID, (school) => {
      callback(school.grades);
    });
  }

  static AddGradeIfMissing(schoolID, gradeIn) {
    SchoolStorage.Read(schoolID, (school) => {
      const index = school.grades.find((grade) => {
        return grade.name === gradeIn;
      });
      if (!index && school.grades.length) {
        // We need to add it -- we'll just use the timings from the first one
        const newGrade = { name: gradeIn, timings: school.grades[0].timings };
        school.grades.push(newGrade);
        SchoolStorage.Update(schoolID, school);
      }
    });
  }

  static IsParentVerified(schoolId, userId, callback) {
    const firebaseManager = new FirebaseManager();

    firebaseManager.readOnce('schools/' + schoolId + '/verifiedList/' + userId, (snap) => {
      let verified = snap.exists();
      if (callback) {
        callback(verified);
      }
    });
  }

  static HasParentRequestedVerified(schoolID, userId, callback) {
    const firebaseManager = new FirebaseManager();

    firebaseManager.readOnce('schools/' + schoolID + '/verifyRequests/' + userId, (snap) => {
      let requested = snap.exists() ? true : false;
      if (callback) {
        callback(requested);
      }
    });
  }
}
