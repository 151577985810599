import { ROUTES, FETCH_TIMEOUT } from '../../utils/consts';
import APIUtils from '../APIUtils';
import ProfileHelper from './profile';

// How may profiles FindParents fetches at a time
const FETCH_PAGE_SIZE = 250;

export default class SearchHelper {
  static SchoolSearch(query, searchBy, pageSize, offset, callback) {
    ProfileHelper.GetFirebaseIdToken((token) => {
      fetch(
        ROUTES.SCHOOL_SEARCH +
          '?search=' +
          query +
          '&id=' +
          searchBy +
          '&limit=' +
          pageSize +
          '&offset=' +
          offset,
        {
          method: 'GET',
          headers: new Headers({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          }),
        },
      )
        .then((response) => response.json())
        .then((schools) => {
          callback(schools);
        })
        .catch((error) => {
          console.error(error);
        });
    });
  }

  static OrgSearch(query, searchBy, pageSize, offset, callback) {
    ProfileHelper.GetFirebaseIdToken((token) => {
      fetch(
        ROUTES.ORG_SEARCH +
          '?search=' +
          query +
          '&id=' +
          searchBy +
          '&limit=' +
          pageSize +
          '&offset=' +
          offset,
        {
          method: 'GET',
          headers: new Headers({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          }),
        },
      )
        .then((response) => response.json())
        .then((orgs) => {
          callback(orgs);
        })
        .catch((error) => {
          console.error(error);
        });
    });
  }

  static ParentSearch(query, searchBy, pageSize, offset, callback) {
    ProfileHelper.GetFirebaseIdToken((token) => {
      fetch(
        ROUTES.PARENT_SEARCH +
          '?search=' +
          query +
          '&id=' +
          searchBy +
          '&limit=' +
          pageSize +
          '&offset=' +
          offset,
        {
          method: 'GET',
          headers: new Headers({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          }),
        },
      )
        .then((response) => response.json())
        .then((orgs) => {
          callback(orgs);
        })
        .catch((error) => {
          console.error(error);
        });
    });
  }

  static FindSchools(homeCoords, callback) {
    ProfileHelper.GetFirebaseIdToken((token) => {
      fetch(
        ROUTES.FIND_SCHOOLS_API + '?lat=' + homeCoords.latitude + '&lng=' + homeCoords.longitude,
        {
          method: 'GET',
          headers: new Headers({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          }),
        },
      )
        .then((response) => response.json())
        .then((schools) => {
          callback(schools);
        })
        .catch((error) => {
          console.error(error);
        });
    });
  }

  static FindOrgs(homeCoords, callback) {
    ProfileHelper.GetFirebaseIdToken((token) => {
      fetch(ROUTES.FIND_ORGS_API + '?lat=' + homeCoords.latitude + '&lng=' + homeCoords.longitude, {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        }),
      })
        .then((response) => response.json())
        .then((schools) => {
          callback(schools);
        })
        .catch((error) => {
          console.error(error);
        });
    });
  }

  static FindParents(filters, callback) {
    ProfileHelper.GetFirebaseIdToken((token) => {
      const {
        schoolId,
        activityId,
        partnerId,
        chapterId,
        partnerList,
        excludePendingMembers,
        excludeExistingMembers,
        sortBy,
        marketplaceId,
        filterByDistance,
        distanceRadius,
        coords,
        userHomeAddress,
        grade,
        homeschool,
      } = filters;
      let url = ROUTES.FIND_PARENTS_API + '?lat=' + coords.latitude + '&lng=' + coords.longitude;
      if (schoolId) {
        url += '&schoolId=' + schoolId;
      }
      if (activityId) {
        url += '&activityId=' + activityId;
      }
      if (partnerId) {
        url += '&partnerId=' + partnerId;
      }
      if (chapterId) {
        url += '&chapterId=' + chapterId;
      }
      if (partnerList) {
        partnerList.forEach((partner) => {
          if (partner.enabled && partner.hasParentList) {
            url += '&partnerList[]=' + partner.id;
          }
        });
      }
      if (excludePendingMembers) {
        url += '&excludePendingMembers=true';
      }
      if (excludeExistingMembers) {
        url += '&excludeExistingMembers=true';
      }
      if (userHomeAddress) {
        url += '&userHomeAddress=' + encodeURIComponent(userHomeAddress);
      }
      if (sortBy) {
        url += '&sortBy=' + sortBy;
      }
      if (filterByDistance) {
        url += '&filterByDistance=' + filterByDistance;
      }
      if (distanceRadius) {
        url += '&distanceRadius=' + distanceRadius.toString(10);
      }
      if (marketplaceId) {
        url += '&marketplaceId=' + marketplaceId;
      }
      if (grade) {
        url += '&grade=' + grade;
      }
      if (homeschool) {
        url += '&homeschool=' + homeschool;
      }

      fetchParents(url, [], callback);
    });
  }

  static FindMeetups(filter, callback) {
    let url = ROUTES.FIND_MEETUPS_API;

    if (filter.coords) {
      url += '?lat=' + filter.coords.latitude + '&lng=' + filter.coords.longitude;
    }
    if (filter.distanceRadius) {
      url += '&distanceRadius=' + filter.distanceRadius;
    }
    if (filter.partnerId) {
      url += '&partnerId=' + filter.partnerId;
    }

    APIUtils.getCloudWithTimeOut(url, FETCH_TIMEOUT)
      .then((data) => {
        callback(data);
      })
      .catch((error) => {
        callback(null);
        console.warn(error);
      });
  }
}

function fetchParents(url, parentListIn, callback) {
  const urlWithPaging = url + `&skip=${parentListIn.length}&limit=${FETCH_PAGE_SIZE}`;

  APIUtils.getCloudWithTimeOut(urlWithPaging, FETCH_TIMEOUT)
    .then((response) => {
      const parentList = parentListIn.concat(response.parents);
      if (response.remainderCount) {
        fetchParents(url, parentList, callback);
      } else {
        callback(parentList);
      }
    })
    .catch((error) => {
      callback(null);
      console.warn(error);
    });
}
