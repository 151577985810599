import React from 'react';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { toast } from 'react-toastify';
import {
  Avatar,
  Card,
  CardHeader,
  CardContent,
  CircularProgress,
  Divider,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  TextField,
  Slider,
  Typography,
  Grid,
  List,
  Badge,
  IconButton,
} from '@material-ui/core';
import { ChevronRight, Warning } from '@material-ui/icons';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import UjamaAppBar from '../../common/material-wrap/app-bar';
import AuthHelper from '../../../api/helpers/auth';
import ProfileHelper from '../../../api/helpers/profile';
import KidStorage from '../../../api/storage/kid';
import KarmaPointsStorage from '../../../api/storage/karma-points';
import MarketplaceStorage from '../../../api/storage/marketplace';
import StateMachine from '../../../api/helpers/state-machine';

import ProfileDefault from '../../../assets/images/profile-default.png';
import KidProfileDefault from '../../../assets/images/blank-kid-profile.png';

import ROUTES, { VISIBILITY } from '../../../utils/consts';
import AddressModal from '../../common/pages/address-modal';
import DeleteProfileModal from '../../common/pages/delete-profile-modal';
import PhoneAuthModal from './phone-auth-modal';
import './my-profile.css';

class UserProfile extends React.Component {
  constructor(props) {
    super(props);

    this.addressModalRef = React.createRef();
    this.phoneModalRef = React.createRef();
    this.imageUpload = React.createRef();
    this.deleteProfileModalRef = React.createRef();

    this.state = {
      id: '',
      name: '',
      email: '',
      countryCode: '',
      phone: '',
      homeAddress: '',
      workAddress: '',
      pic: '',
      file: null,
      headline: '',
      visibility: '',
      karmaPointsBalance: 0,
      floatingLabelStyle: styles.nameFloatingLabelStyle,
      emailLabelStyle: styles.listLeft,
      phoneLabelStyle: styles.listLeft,
      addressLabelStyle: styles.listLeft,
      kidsData: {
        kids: [],
        loading: true,
      },
      loading: true,
      marketplaceCategory: '',
    };
  }

  componentDidMount() {
    const savedState = StateMachine.restore(this);

    if (!savedState) {
      this.readData();
    } else if (savedState.refreshMarketplaceSettings) {
      this.readMarketplaceSettings();
    }
  }

  readData() {
    const { history } = this.props;
    if (
      typeof this.props.location === 'undefined' ||
      typeof this.props.location.state === 'undefined'
    ) {
      AuthHelper.getProfileDetails()
        .then((profile) => {
          if (profile) {
            this.setState({
              profile,
              id: profile.id,
              name: profile.name || '',
              email: profile.email || '',
              countryCode: profile.countryCode || '',
              phone: profile.phone || '',
              homeAddress: profile.homeAddress || '',
              workAddress: profile.workAddress || '',
              pic: profile.pic || ProfileDefault,
              headline: profile.headline || '',
              visibility:
                typeof profile.visibility !== 'undefined' ? profile.visibility : VISIBILITY.PUBLIC,
              file: null,
              loading: false,
            });

            this.readMarketplaceSettings();

            KarmaPointsStorage.ReadBalance(profile.id, (balance) => {
              this.setState({ karmaPointsBalance: balance });
            });

            KidStorage.ReadAllKids(profile.id, (kids) => {
              KidStorage.LoadProfileImagesForList(kids, (kids) => {
                this.setState({
                  kidsData: {
                    kids: kids || [],
                    loading: false,
                  },
                });
              });
            });
          } else if (!profile) {
            history.push('/login');
          } else {
            this.setState({ loading: false });
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          toast.error(
            'Sorry! Unable to get profile details at the moment! Please try again or contact our support team!',
          );
        });
    } else {
      this.setState(this.props.location.state);
    }
  }

  readMarketplaceSettings(profile) {
    AuthHelper.getProfileDetails().then((profile) => {
      if (profile.isMarketplaceSeller) {
        MarketplaceStorage.ReadList((marketplaceList) => {
          for (let i = 0; i < marketplaceList.length; i++) {
            const marketplace = marketplaceList[i];
            if (marketplace.id === profile.marketplaceID) {
              this.setState({ marketplaceCategory: marketplace.name });
              break;
            }
          }
        });
      } else {
        this.setState({ marketplaceCategory: '' });
      }
    });
  }

  onBack = () => {
    if (this.hasChanged()) {
      confirmAlert({
        title: 'Confirm',
        message: 'Your changes have not been saved. Are you sure you want to cancel?',
        buttons: [
          {
            label: 'No',
          },
          {
            label: 'Yes',
            onClick: () => this.props.history.goBack(),
          },
        ],
      });
    } else {
      this.props.history.goBack();
    }
  };

  hasChanged() {
    const { profile } = this.state;

    return (
      profile.name !== this.state.name ||
      profile.email !== this.state.email ||
      profile.phone !== this.state.phone ||
      profile.headline !== this.state.headline ||
      profile.homeAddress !== this.state.homeAddress ||
      profile.workAddress !== this.state.workAddress ||
      profile.visibility !== this.state.visibility
    );
  }

  onSave = () => {
    const { history } = this.props;
    if (!this.state.loading && this.validate()) {
      let profile = {
        name: this.state.name,
        email: this.state.email,
        phone: this.state.phone,
        headline: this.state.headline,
        homeAddress: this.state.homeAddress,
        workAddress: this.state.workAddress,
        visibility: this.state.visibility,
        homeCoords: {
          longitude: null,
          latitude: null,
        },
        pic: this.state.pic,
        file: this.state.file,
      };

      if (this.state.name && this.state.email && this.state.homeAddress) {
        this.setState({ loading: true });

        geocodeByAddress(this.state.homeAddress)
          .then((results) => getLatLng(results[0]))
          .then((latLng) => {
            profile.homeCoords.longitude = latLng.lng;
            profile.homeCoords.latitude = latLng.lat;

            ProfileHelper.updateProfileDetails(this.state.id, profile);

            setTimeout(() => {
              this.setState({ loading: false });
              toast.success('Profile details updated successfully');
              history.goBack();
            }, 3000);
          })
          .catch((error) => {
            toast.error(
              'Sorry! Your Profile details could not be updated. Please ensure the data you have entered is valid. If you are still unable to update your profile, please write to us.',
            );
            this.setState({ loading: false });
            console.error('Error', error);
          });
      }
    }
  };

  handleChange(event) {
    const target = event.target;

    this.setState({
      [target.name]: target.value,
    });
  }

  onKidAddPressed() {
    let location = {
      pathname: `${ROUTES.ADD_KID}`,
      state: {},
    };
    this.props.history.push(location);
  }

  onPhoneClicked = () => {
    this.phoneModalRef.current.launch((countryCode, phone) => {
      this.setState({ countryCode, phone });
    });
  };

  onLocationClicked = (key) => {
    this.addressModalRef.current.launch((address) => {
      this.setState({ [key]: address.fullAddress });
    });
  };

  handlePhotoUpload(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    if (file.size < 3614969) {
      reader.onloadend = () => {
        this.setState({
          file: file,
          pic: reader.result,
        });
      };

      reader.readAsDataURL(file);
    } else {
      toast.warn('File size is too big');
    }
  }

  validate() {
    var ret = false;

    this.resetLabelColors();

    do {
      if (!this.state.name[0]) {
        toast.warn('Please provide your name.');
        this.setState({
          nameFloatingLabelStyle: styles.nameFloatingLabelStyleRed,
        });
        break;
      }

      if (!this.state.email[0]) {
        toast.warn('Please provide your email address.');
        this.setState({ emailLabelStyle: styles.listLeftRed });
        break;
      }

      if (!this.state.phone[0]) {
        toast.warn('Please provide your cell phone number.');
        this.setState({ phoneLabelStyle: styles.listLeftRed });
        break;
      }

      if (!this.state.homeAddress[0]) {
        toast.warn('Please provide your home address.');
        this.setState({ addressLabelStyle: styles.listLeftRed });
        break;
      }

      ret = true;
      // eslint-disable-next-line no-constant-condition
    } while (false); // do ONCE

    return ret;
  }

  resetLabelColors() {
    this.setState({
      nameFloatingLabelStyle: styles.nameFloatingLabelStyle,
      emailLabelStyle: styles.listLeft,
      phoneLabelStyle: styles.listLeft,
      addressLabelStyle: styles.listLeft,
    });
  }

  onVisibilityChange(e, value) {
    this.setState({
      visibility: value,
    });
  }

  onMarketplace = () => {
    StateMachine.save(this);
    StateMachine.addProperties(this, {
      refreshMarketplaceSettings: true,
    });
    this.props.history.push({ pathname: ROUTES.MARKETPLACE_SETTINGS, state: {} });
  };

  onDeleteProfile = () => {
    this.deleteProfileModalRef.current.launch(this.state.countryCode, this.state.phone, () => {
      // Exit!
      window.location('/');
    });
  };

  render() {
    const { history } = this.props;
    return (
      <div>
        <UjamaAppBar
          title={this.state.name || 'Your Profile'}
          type="cancel"
          back={this.onBack}
          rightAction={this.onSave}
          rightActionText="Save"
        />
        {this.state.loading ? <CircularProgress /> : this.renderContent()}
        <div style={{ height: 5 }} />
        <AddressModal ref={this.addressModalRef} />
        <PhoneAuthModal ref={this.phoneModalRef} />
        <DeleteProfileModal ref={this.deleteProfileModalRef} />
      </div>
    );
  }

  renderPhotoSection() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
        }}
      >
        {this.renderProfileImage()}
        {this.renderKarmaPoints()}
      </div>
    );
  }

  renderContent() {
    return (
      <div>
        {this.renderPhotoSection()}
        {this.renderScreen()}
      </div>
    );
  }

  renderKarmaPoints() {
    return (
      <div
        style={{
          height: 200,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Avatar
          onClick={() =>
            alert('Get more value from your Upendo Points by downloading our mobile app!')
          }
          style={{
            objectFit: 'cover',
            marginTop: 25,
            cursor: 'pointer',
            width: 150,
            height: 150,
            fontSize: 75,
          }}
          color={'white'}
        >
          {this.state.karmaPointsBalance}
        </Avatar>
        <span style={{ fontSize: 12, fontWeight: 200, paddingTop: 10 }}>
          {'Your Upendo Points'}
        </span>
      </div>
    );
  }

  renderProfileImage() {
    return (
      <div
        style={{
          height: 200,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <input
          type="file"
          ref={this.imageUpload}
          style={{ display: 'none' }}
          onChange={(e) => this.handlePhotoUpload(e)}
        />
        <Avatar
          onClick={() => this.imageUpload.current.click()}
          style={{ objectFit: 'cover', marginTop: 25, cursor: 'pointer', width: 150, height: 150 }}
          src={this.state.pic || ProfileDefault}
        />
        <span style={{ fontSize: 12, fontWeight: 200, paddingTop: 10 }}>
          {'Click photo to update'}
        </span>
      </div>
    );
  }

  renderScreen() {
    return (
      <div style={{ padding: 10, textAlign: 'left' }}>
        {this.renderContactDetails()}
        {this.renderHeadline()}
        {this.renderKidsSection()}
        {this.renderMarketplaceSection()}
        {this.renderPrivacySlider()}
        {this.renderDeleteProfile()}
      </div>
    );
  }

  renderKidsSection() {
    if (this.state.kidsData.loading) {
      return <CircularProgress />;
    } else {
      const kids = this.state.kidsData.kids;
      const kidsMessage = kids.length === 1 ? ' kid' : ' kids';

      return (
        <div>
          <Card style={styles.card}>
            <CardHeader
              style={styles.cardHeader}
              titleTypographyProps={{ color: 'textSecondary', variant: 'h4' }}
              title={'Your Kids (' + kids.length + kidsMessage + ')'}
            />
            <List style={styles.horizontalList}>{this.renderKidsProfiles(kids)}</List>
            <Divider />
            <ListItem button divider onClick={() => this.onKidAddPressed()}>
              <ListItemText primary={'Add kid'} />
              <ListItemSecondaryAction>
                <ChevronRight />
              </ListItemSecondaryAction>
            </ListItem>
          </Card>
          <div style={{ height: 5 }} />
        </div>
      );
    }
  }

  renderKidsProfiles(kids) {
    if (kids.length) {
      const { history } = this.props;
      const fragment = [];

      kids.forEach((kid) => {
        fragment.push(
          <div key={'kidProfile' + kid.id}>
            <ListItem
              className="clickable"
              button
              onClick={() => history.push({ pathname: `${ROUTES.KID}/${kid.id}` })}
              style={{ height: 180, width: 180 }}
            >
              <Grid container direction="column" alignItems="center" justify="center">
                <Grid item xs={12}>
                  <Avatar
                    src={kid.pic || KidProfileDefault}
                    style={{
                      objectFit: 'cover',
                      width: 150,
                      height: 150,
                      display: 'block',
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography style={styles.profileLabel}>{kid.name}</Typography>
                  {this.renderKidBadge(kid)}
                </Grid>
              </Grid>
            </ListItem>
          </div>,
        );
      });

      return fragment;
    } else {
      return null;
    }
  }

  renderKidBadge(kid) {
    if (KidStorage.KidHasIncompleteInformation(kid)) {
      return (
        <Badge
          badgeContent={
            <IconButton tooltip="Kid has incomplete information">
              <Warning />
            </IconButton>
          }
          style={{ top: 15, right: 25, position: 'absolute' }}
          //badgeStyle={{backgroundColor: "transparent"}}
        />
      );
    }
  }

  renderMarketplaceSection() {
    const { marketplaceCategory } = this.state;

    return (
      <Card style={styles.card}>
        <CardHeader
          style={styles.cardHeader}
          titleTypographyProps={{ color: 'textSecondary', variant: 'h4' }}
          title="Ujama Marketplace"
        />
        <ListItem button divider onClick={this.onMarketplace}>
          <ListItemText primary={marketplaceCategory ? marketplaceCategory : 'Add your business'} />
          <ListItemSecondaryAction onClick={this.onMarketplace}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>{marketplaceCategory ? 'Update' : ''}</span>
              <ChevronRight />
            </div>
          </ListItemSecondaryAction>
        </ListItem>
      </Card>
    );
  }

  renderPrivacySlider() {
    return (
      <div>
        <Card>
          <CardHeader
            style={styles.cardHeader}
            titleTypographyProps={{ color: 'textSecondary', variant: 'h4' }}
            title="Your visibility in Ujama directories"
          />
          <div style={{ paddingLeft: '30px', paddingRight: '30px' }}>
            <Slider
              min={VISIBILITY.NONE}
              max={VISIBILITY.PUBLIC}
              step={1}
              value={this.state.visibility}
              onChange={this.onVisibilityChange.bind(this)}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ width: 100, fontSize: 12, textAlign: 'left' }}>{'None'}</div>
              <div style={{ width: 100, fontSize: 12, textAlign: 'center' }}>
                {'Only affiliated schools and organizations'}
              </div>
              <div style={{ width: 100, fontSize: 12, textAlign: 'right' }}>{'Public'}</div>
            </div>
          </div>
        </Card>
        <div style={{ height: 5 }} />
      </div>
    );
  }

  renderDeleteProfile() {
    return (
      <div style={{ marginTop: 20 }}>
        <Divider />
        <ListItem button onClick={this.onDeleteProfile}>
          <ListItemText primary="Delete Account" primaryTypographyProps={{ color: 'secondary' }} />
          <ListItemSecondaryAction onClick={this.onDeleteProfile}>
            <ChevronRight />
          </ListItemSecondaryAction>
        </ListItem>
      </div>
    );
  }

  renderContactDetails() {
    return (
      <Card>
        <CardHeader
          style={styles.cardHeader}
          titleTypographyProps={{
            color: 'textSecondary',
            variant: 'h4',
          }}
          title="Your Contact Details"
        />
        <ListItem divider style={styles.listLeft}>
          <TextField
            name={'name'}
            type="text"
            value={this.state.name}
            label="Name"
            style={{ width: '50%', minWidth: 200 }}
            onChange={this.handleChange.bind(this)}
          />
        </ListItem>

        <ListItem
          divider
          style={this.state.emailLabelStyle}
          onClick={() => toast("Can't change email yet")}
        >
          <ListItemText primary={'Email*'} secondary={this.state.email} />
        </ListItem>

        <ListItem
          button
          divider
          style={this.state.phoneLabelStyle}
          onClick={this.onPhoneClicked.bind(this)}
        >
          <ListItemText
            primary={'Cell phone*'}
            secondary={this.state.phone ? `+${this.state.countryCode} ${this.state.phone}` : ''}
          />
          <ListItemSecondaryAction onClick={this.onPhoneClicked.bind(this)}>
            <ChevronRight />
          </ListItemSecondaryAction>
        </ListItem>

        <ListItem
          button
          divider
          style={this.state.addressLabelStyle}
          onClick={this.onLocationClicked.bind(this, 'homeAddress')}
        >
          <ListItemText primary={'Home address*'} secondary={this.state.homeAddress} />
          <ListItemSecondaryAction onClick={this.onLocationClicked.bind(this, 'homeAddress')}>
            <ChevronRight />
          </ListItemSecondaryAction>
        </ListItem>

        <ListItem
          button
          divider
          style={styles.listLeft}
          onClick={this.onLocationClicked.bind(this, 'workAddress')}
        >
          <ListItemText primary={'Work address*'} secondary={this.state.workAddress} />
          <ListItemSecondaryAction onClick={this.onLocationClicked.bind(this, 'workAddress')}>
            <ChevronRight />
          </ListItemSecondaryAction>
        </ListItem>
        <CardContent>
          <p style={{ fontSize: 12 }}>
            {'*This data is always private and only shared with people you add to your village. '}
            <a
              href="http://ujama.co/privacy-commitment.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              {'Learn more'}
            </a>
          </p>
        </CardContent>
      </Card>
    );
  }

  renderHeadline() {
    return (
      <ListItem>
        <TextField
          name={'headline'}
          style={{ width: '100%' }}
          type="text"
          multiline={true}
          rows={4}
          value={this.state.headline}
          placeholder="Write a message for people viewing your profile"
          label="Headline"
          onChange={this.handleChange.bind(this)}
        />
      </ListItem>
    );
  }
}

const styles = {
  cardHeader: {
    textAlign: 'left',
    fontWeight: 100,
  },
  nameFloatingLabelStyle: {
    width: '80%',
    marginLeft: 25,
    marginBottom: 10,
  },
  nameFloatingLabelStyleRed: {
    color: 'red',
  },
  listRight: {
    color: '#d3d3d3',
    width: '100%',
    textAlign: 'right',
    margin: '0px',
    top: '12px',
    paddingRight: '10px',
    verticalAlign: 'super',
  },
  listLeft: {
    textAlign: 'left',
    width: '100%',
    whiteSpace: 'nowrap',
  },
  listLeftRed: {
    color: 'red',
    textAlign: 'left',
    width: '100%',
  },
  appBarMenuItem: {
    paddingTop: 5,
    display: 'flex',
    width: '88px',
    height: '37px',
  },
  sliderText: {
    width: 100,
    fontSize: 12,
  },
  horizontalList: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: 0,
    overflowX: 'auto',
    overflowY: 'hidden',
  },
};

export default UserProfile;
