import FirebaseManager from '../firebase/firebase-manager';
import ImageStorage from './image';
import ProfileStorage from './profile';
import SchoolStorage from './school';

export default class KidStorage {
  static Read(kidID, callback) {
    const firebaseManager = new FirebaseManager();

    firebaseManager.readOnce('kid/' + kidID, (snap) => {
      let kid = snap.exists() ? snap.val() : null;
      if (kid) {
        kid.id = kidID;
      }
      if (callback) {
        callback(kid);
      }
    });
  }

  // We establish a new kid record with just the name and parent's id
  static Add(parentID, name) {
    const firebaseManager = new FirebaseManager();

    var newKid = { name: name, parents: { [parentID]: parentID } };
    var kidID = firebaseManager.saveData('kid/', newKid);

    ProfileStorage.AddKidsToFamily(parentID, [{ id: kidID }]);

    return kidID;
  }

  static AddPromise(parentID, name) {
    const firebaseManager = new FirebaseManager();

    return new Promise((resolve) => {
      var newKid = { name: name, parents: { [parentID]: parentID } };
      var ref = firebaseManager.saveDataPromise('kid/', newKid);
      var kidID = ref.key;
      ref.then(() => {
        resolve(kidID);
      });
    });
  }

  static UpdatePromise(kidID, kid) {
    const firebaseManager = new FirebaseManager();

    return firebaseManager.update('kid/' + kidID, kid);
  }

  static Delete(kidID, callback) {
    KidStorage.Read(kidID, (kid) => {
      const parentID = KidStorage.GetKidCreator(kid);
      if (parentID) {
        ProfileStorage.RemoveKidsFromFamily(parentID, [{ id: kidID }]).then(() => {
          KidStorage.deleteInternal(kidID, callback);
        });
      } else {
        KidStorage.deleteInternal(kidID, callback);
      }
    });
  }

  static GetKidCreator(kid) {
    var parentID = 0;

    if (kid.parents) {
      for (parentID in kid.parents) {
        break; // We only expect one
      }
    }

    return parentID;
  }

  static deleteInternal(kidID, callback) {
    const firebaseManager = new FirebaseManager();
    firebaseManager.deleteValue('kid/' + kidID, callback);
  }

  static Update(kidID, kid) {
    const firebaseManager = new FirebaseManager();

    return firebaseManager.update('kid/' + kidID, kid);
  }

  static UpdateName(kidId, name, callback) {
    KidStorage.Update(kidId, { name: name }, callback);
  }

  static UpdateLastName(kidId, lastName, callback) {
    KidStorage.Update(kidId, lastName, callback);
  }

  static UpdatePhone(kidId, phone, callback) {
    KidStorage.Update(kidId, { phone: phone }, callback);
  }

  static UpdateSchool(kidId, schoolRef, callback) {
    KidStorage.Update(kidId, { schoolRef: schoolRef }, callback);
  }

  static UpdateGrade(kidId, grade, callback) {
    KidStorage.Update(kidId, { grade: grade }, callback);
  }

  static UpdatePeriods(kidId, periods, callback) {
    KidStorage.Update(kidId, { periods: periods }, callback);
  }

  static UpdateSpecialInstructions(kidId, specialInstuctions, callback) {
    KidStorage.Update(kidId, { specialInstuctions: specialInstuctions }, callback);
  }

  static UpdateKidSchool(kidID, school1, school2) {
    const firebaseManager = new FirebaseManager();
    firebaseManager.readOnce('kid/' + kidID, (snap) => {
      const kids = {};
      snap.forEach((child) => {
        const kid = child.val();
        kids[child.key] = child.val();
        if (kids[child.key] === school2) {
          KidStorage.UpdateSchool(kidID, school1.id);
        }
      });
    });
    SchoolStorage.Delete(school2);
    console.log('SCHOOL DELETED ', school2);
  }

  static ReadList(userID, callback) {
    KidStorage.ReadAllKids(userID, callback);
  }

  static ReadAllKids(userID, callback) {
    ProfileStorage.Read(userID, (profile) => {
      KidStorage.ReadAllKidsForProfile(profile, callback);
    });
  }

  static ReadAllKidsForProfile(profile, callback) {
    const kids = [];
    if (profile && profile.kids) {
      let promises = profile.kids.map((kid) => {
        return new Promise((resolve) => {
          KidStorage.Read(kid.id, (kidObj) => {
            if (kidObj) {
              kids.push(kidObj);
            }
            resolve();
          });
        });
      });
      Promise.all(promises).then(() => {
        callback(kids);
      });
    } else {
      callback(kids);
    }
  }

  static NumberOfKids(userID, callback) {
    ProfileStorage.Read(userID, (profile) => {
      if (profile && profile.kids) {
        callback(profile.kids.length);
      } else {
        callback(0);
      }
    });
  }

  // Return the list of kids that this user created
  // (Don't include the ones created by family members)
  static ReadCreatedKids(userID, callback) {
    KidStorage.ReadAllKids(userID, (kids) => {
      const createdKids = [];

      kids.forEach((kid) => {
        let parentID = KidStorage.GetKidCreator(kid);
        if (parentID === userID) {
          createdKids.push(kid);
        }
      });

      callback(createdKids);
    });
  }

  static ReadAllTrustedKids(userID, callback) {
    const firebaseManager = new FirebaseManager();
    var kids = [];

    firebaseManager.readOnce('profiles/' + userID, (snap) => {
      if (!snap) {
        callback(kids);
      } else {
        const parentObj = snap.val();

        ProfileStorage.ReadAllExpandedTrustedPoolProfiles(parentObj, (trustedFriends) => {
          // Sort the trusted pool list to put family connections first
          trustedFriends.sort((a, b) => {
            if (a.relationshipType === 'family') {
              return -1;
            }
            if (b.relationshipType === 'family') {
              return 1;
            }
            return 0;
          });

          firebaseManager.readOnce('kid', (snap) => {
            snap.forEach((child) => {
              let kidObj = child.val();
              kidObj.id = child.key;
              if (kidObj != null && kidObj.parents != null) {
                if (Object.keys(kidObj.parents).includes(userID)) {
                  // The current user is the parent of this child
                  kidObj.parentType = 'family';
                  kidObj.homeAddress = parentObj.homeAddress;
                  kidObj.homeCoords = parentObj.homeCoords;
                  kids.push(kidObj);
                } else {
                  var kidPushed = false;
                  for (let i = 0; i < trustedFriends.length && !kidPushed; i++) {
                    const friendObj = trustedFriends[i];
                    if (Object.keys(kidObj.parents).includes(friendObj.id)) {
                      // The friend is the parent of this child
                      kidObj.parentType = friendObj.relationshipType;
                      kidObj.homeAddress = friendObj.homeAddress;
                      kidObj.homeCoords = friendObj.homeCoords;
                      kids.push(kidObj);
                      kidPushed = true;
                    }
                  }
                }
              }
            });

            callback(kids);
          });
        });
      }
    });
  }

  static ReadMasterList(callback) {
    const firebaseManager = new FirebaseManager();
    var kidsList = [];

    firebaseManager.readOnce('kid', (snap) => {
      if (snap) {
        snap.forEach((child) => {
          let kidObj = child.val();
          if (kidObj) {
            kidObj.id = child.key;
            kidsList.push(kidObj);
          }
        });
      }
      callback(kidsList);
    });
  }

  static KidHasIncompleteInformation(kid) {
    var ret = true;

    do {
      if (kid.schoolRef && !kid.grade) {
        break;
      }

      if (kid.activity) {
        var foundActivityWithNoTiming = false;
        for (let key in kid.activity) {
          let activity = kid.activity[key];
          if (KidStorage.ActivityHasIncompleteInformation(activity)) {
            foundActivityWithNoTiming = true;
            break;
          }
        }
        if (foundActivityWithNoTiming) {
          break;
        }
      }

      ret = false;
      // eslint-disable-next-line no-constant-condition
    } while (false); // do ONCE!

    return ret;
  }

  static ActivityHasIncompleteInformation(activity) {
    return !activity.timing || !activity.location;
  }

  // ====================== Parents =========================

  static AddParentsToSpecificKid(kidId) {
    return new Promise((resolve, reject) => {
      KidStorage.Read(kidId, async (kid) => {
        try {
          // Assuming it's a newly created kid, 'kidMainParentId' should be the current user's id.
          const kidMainParentId = Object.keys(kid.parents)[0] || '';
          const familyMembersIds = await KidStorage.GetFamilyMembersOfAUser(kidMainParentId);
          await KidStorage.AssignParentsToKid(kidId, familyMembersIds);
          resolve();
        } catch (error) {
          console.log('error on AddParentsToSpecificKid', error);
          reject();
        }
      });
    });
  }

  static GetFamilyMembersOfAUser(profileId) {
    return new Promise((resolve, reject) => {
      ProfileStorage.ReadTrustedPoolList(profileId, (trustedPoolList) => {
        const familyMembersIds = [profileId];
        trustedPoolList.forEach((item) => {
          if (item.relationshipType === 'family') {
            familyMembersIds.push(item.id);
          }
        });
        resolve(familyMembersIds);
      });
    });
  }

  static AssignParentsToKid(kidId, parentsIds) {
    return new Promise((resolve, reject) => {
      const parentsObject = {};
      parentsIds.forEach((parentId) => {
        parentsObject[parentId] = parentId;
      });
      KidStorage.AddParentsObjToKid(kidId, parentsObject)
        .then(() => resolve())
        .catch((error) => {
          console.log('error on AssignParentsToKid', error);
          reject();
        });
    });
  }

  static AddParentsObjToKid(kidId, parentsObj) {
    return new Promise((resolve, reject) => {
      const firebaseManager = new FirebaseManager();
      firebaseManager
        .update('kid/' + kidId + '/parents', parentsObj)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          console.warn('Error on AddParentsObjToKid', error);
          reject();
        });
    });
  }

  static AddNewParentToKidList(kidList, parentId) {
    return new Promise((resolve, reject) => {
      const promises = kidList.map((kid) => KidStorage.AddNewParentToSpecificKid(kid, parentId));
      Promise.all(promises)
        .then(() => resolve())
        .catch((error) => {
          console.log('error on AddNewParentToKidList', error);
          reject();
        });
    });
  }

  static AddNewParentToSpecificKid(kid, parentId) {
    return new Promise((resolve, reject) => {
      const firebaseManager = new FirebaseManager();
      const parentsObj = {
        ...kid.parents,
        [parentId]: parentId,
      };
      firebaseManager.create('kid/' + kid.id + '/parents', parentsObj, (error) => {
        if (error) {
          console.log('error on AddNewParentToSpecificKid', error);
          reject();
        } else {
          resolve();
        }
      });
    });
  }

  static RemoveParentFromKidList(kidList, parentId) {
    return new Promise((resolve, reject) => {
      const promises = kidList.map((kid) => KidStorage.RemoveParentFromSpecificKid(kid, parentId));
      Promise.all(promises)
        .then(() => resolve())
        .catch((error) => {
          console.log('error on RemoveParentFromKidList', error);
          reject();
        });
    });
  }

  static RemoveParentFromSpecificKid(kid, parentId) {
    return new Promise((resolve, reject) => {
      const firebaseManager = new FirebaseManager();
      firebaseManager.deleteValue('kid/' + kid.id + '/parents/' + parentId, (error) => {
        if (error) {
          console.log('error on RemoveParentFromSpecificKid', error);
          reject();
        } else {
          resolve();
        }
      });
    });
  }

  // ====================== Images =========================

  static UpdateProfileImage(kidID, file, callback) {
    ImageStorage.Update('profiles/' + kidID + '', file, callback);
  }

  static UpdatePhotoKey(kidID, file, callback) {
    ImageStorage.Update('profiles/' + kidID + 'key', file, callback);
  }

  static LoadProfileImage(kidID, callback) {
    ImageStorage.Load('profiles/' + kidID + '', callback);
  }

  static LoadPhotoKey(kidID, callback) {
    ImageStorage.Load('profiles/' + kidID + 'key', callback);
  }

  static LoadProfileImagesForList(kidList, callback) {
    var kidCount = 0;
    if (kidList.length) {
      kidList.forEach((kid) => {
        KidStorage.LoadProfileImage(kid.id, (uri) => {
          kid.pic = uri;
          if (++kidCount === kidList.length) {
            callback(kidList);
          }
        });
      });
    } else {
      callback(kidList);
    }
  }

  // ====================== Activities =========================

  static GetEmptyActivity() {
    return {
      name: '',
      className: '',
      level: '',
      classId: 0,
      location: '',
      orgId: 0,
      timing: [{ day: '', startTime: '', endTime: '' }],
    };
  }

  static ReadActivity(kidID, activityID, callback) {
    const firebaseManager = new FirebaseManager();

    firebaseManager.readOnce('kid/' + kidID + '/activity/' + activityID, (snap) => {
      let activity = snap.exists() ? snap.val() : null;
      callback(activity);
    });
  }

  static AddActivity(kidID, name, orgId) {
    const firebaseManager = new FirebaseManager();

    var newActivity = { name: name, orgId: orgId };
    return firebaseManager.saveData('kid/' + kidID + '/activity', newActivity);
  }

  static ReadActivityList(kidID, callback) {
    const firebaseManager = new FirebaseManager();

    firebaseManager.readOnce('kid/' + kidID + '/activity', (snap) => {
      var activityList = [];

      snap.forEach((child) => {
        let activity = child.val();
        if (activity != null) {
          activityList.push({
            id: child.key,
            name: activity.name,
            orgId: activity.orgId,
            classId: activity.classId,
          });
        }
      });

      callback(activityList);
    });
  }

  static ReadAllActivities(kidID, callback) {
    const firebaseManager = new FirebaseManager();

    firebaseManager.readOnce('kid/' + kidID + '/activity', (snap) => {
      var activities = [];

      snap.forEach((child) => {
        let activity = child.val();
        if (activity != null) {
          activities.push({ id: child.key, ...activity });
        }
      });

      callback(activities);
    });
  }

  static DeleteActivity(kidID, activityID) {
    const firebaseManager = new FirebaseManager();

    firebaseManager.deleteValue('kid/' + kidID + '/activity/' + activityID);
  }

  static UpdateActivity(kidID, activityID, activity, callback) {
    const firebaseManager = new FirebaseManager();

    firebaseManager.update('kid/' + kidID + '/activity/' + activityID, activity).then(() => {
      if (callback) {
        callback();
      }
    });
  }

  static UpdateActivityName(kidID, activityID, name, callback) {
    KidStorage.UpdateActivity(kidID, activityID, { name: name }, callback);
  }

  static UpdateActivityOrgId(kidID, activityID, orgId, callback) {
    KidStorage.UpdateActivity(kidID, activityID, { orgId: orgId }, callback);
  }

  static UpdateActivityClassId(kidID, activityID, classId, callback) {
    KidStorage.UpdateActivity(kidID, activityID, { classId: classId }, callback);
  }

  static UpdateActivityLocation(kidID, activityID, location, callback) {
    KidStorage.UpdateActivity(kidID, activityID, { location: location }, callback);
  }

  static UpdateActivityTiming(kidID, activityID, timing, callback) {
    KidStorage.UpdateActivity(kidID, activityID, { timing: timing }, callback);
  }

  static UpdateActivityStartDate(kidID, activityID, startDate, callback) {
    KidStorage.UpdateActivity(kidID, activityID, { startDate: startDate }, callback);
  }

  // ====================== Overrides =========================

  static ReadOverride(kidID, overrideID, callback) {
    const firebaseManager = new FirebaseManager();

    firebaseManager.readOnce('kid/' + kidID + '/overrides/' + overrideID, (snap) => {
      let override = snap.exists() ? snap.val() : null;
      callback(override);
    });
  }

  static AddOverride(kidID, override) {
    const firebaseManager = new FirebaseManager();

    return firebaseManager.saveData('kid/' + kidID + '/overrides', override);
  }

  static ReadAllOverrides(kidID, callback) {
    const firebaseManager = new FirebaseManager();

    firebaseManager.readOnce('kid/' + kidID + '/overrides', (snap) => {
      var overrideList = [];

      snap.forEach((child) => {
        let override = child.val();
        if (override != null) {
          overrideList.push({ id: child.key, ...override });
        }
      });

      callback(overrideList);
    });
  }

  static UpdateOverride(kidID, overrideID, override) {
    const firebaseManager = new FirebaseManager();

    return firebaseManager.update('kid/' + kidID + '/overrides/' + overrideID, override);
  }

  static RemoveOverride(kidID, overrideID) {
    const firebaseManager = new FirebaseManager();

    return new Promise((resolve) =>
      firebaseManager.deleteValue('kid/' + kidID + '/overrides/' + overrideID, () => resolve()),
    );
  }

  // ====================== One-off rides =========================

  static ReadOneOffRide(kidID, rideID, callback) {
    const firebaseManager = new FirebaseManager();

    firebaseManager.readOnce('kid/' + kidID + '/oneOffRides/' + rideID, (snap) => {
      let oneOffRide = snap.exists() ? snap.val() : null;
      callback(oneOffRide);
    });
  }

  static AddOneOffRide(kidID, ride) {
    const firebaseManager = new FirebaseManager();

    return firebaseManager.saveData('kid/' + kidID + '/oneOffRides', ride);
  }

  static ReadAllOneOffRides(kidID, callback) {
    const firebaseManager = new FirebaseManager();

    firebaseManager.readOnce('kid/' + kidID + '/oneOffRides', (snap) => {
      var rideList = [];

      snap.forEach((child) => {
        let ride = child.val();
        if (ride != null) {
          rideList.push({ id: child.key, ...ride });
        }
      });

      callback(rideList);
    });
  }

  static UpdateOneOffRide(kidID, rideID, ride) {
    const firebaseManager = new FirebaseManager();

    return firebaseManager.update('kid/' + kidID + '/oneOffRides/' + rideID, ride);
  }

  // ====================== Offered rides =========================

  static ReadOfferedRide(kidID, rideID, callback) {
    const firebaseManager = new FirebaseManager();

    firebaseManager.readOnce('kid/' + kidID + '/offeredRides/' + rideID, (snap) => {
      let offeredRide = snap.exists() ? snap.val() : null;
      callback(offeredRide);
    });
  }

  static AddOfferedRide(kidID, ride) {
    const firebaseManager = new FirebaseManager();

    return firebaseManager.saveData('kid/' + kidID + '/offeredRides', ride);
  }

  static ReadAllOfferedRides(kidID, callback) {
    const firebaseManager = new FirebaseManager();

    firebaseManager.readOnce('kid/' + kidID + '/offeredRides', (snap) => {
      var rideList = [];

      snap.forEach((child) => {
        let ride = child.val();
        if (ride != null) {
          rideList.push({ id: child.key, ...ride });
        }
      });

      callback(rideList);
    });
  }

  static UpdateOfferedRide(kidID, rideID, ride) {
    const firebaseManager = new FirebaseManager();

    return firebaseManager.update('kid/' + kidID + '/offeredRides/' + rideID, ride);
  }

  static readParent(parentList, parentId, readTrustedPool) {
    return new Promise((resolve) => {
      ProfileStorage.Read(parentId, (profile) => {
        if (
          !parentList.find((parent) => {
            return parent.id === profile.id;
          })
        ) {
          parentList.push(profile);

          if (readTrustedPool) {
            const promises = [];
            for (let friendId in profile.trustedPool) {
              let friend = profile.trustedPool[friendId];

              if (
                friend.relationshipType === 'family' &&
                !parentList.find((parent) => {
                  return parent.id === friendId;
                })
              ) {
                promises.push(KidStorage.readParent(parentList, friendId, false));
              }
            }
            Promise.all(promises).then(() => resolve());
          } else {
            resolve();
          }
        } else {
          resolve();
        }
      });
    });
  }

  static ReadParents(kid, callback) {
    const parentList = [];
    const promises = [];

    for (let parentId in kid.parents) {
      promises.push(KidStorage.readParent(parentList, parentId, true));
    }
    Promise.all(promises).then(() => callback(parentList));
  }

  static hasKidInOrg(kidsList, orgID) {
    let ret = false;
    kidsList.forEach((kid) => {
      if (kid.activity) {
        Object.keys(kid.activity).forEach((key) => {
          if (kid.activity[key].orgId === orgID) {
            ret = true;
          }
        });
      }
    });
    return ret;
  }

  static hasKidinSchool(kidsList, schoolID) {
    let ret = false;
    kidsList.forEach((kid) => {
      if (kid.schoolRef === schoolID) {
        ret = true;
      }
    });
    return ret;
  }
}
