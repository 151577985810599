/* global grecaptcha */
import React from 'react';
import firebase from 'firebase/app';
// Use firebaseSecondary so that the phone check doesn't mess with the current token!
import { toast } from 'react-toastify';
import { AppBar, Toolbar, Typography, Button, Paper, TextField } from '@material-ui/core';

import { firebaseSecondary } from '../../../api/firebase/firebase';
import {
  validatePhoneNumber,
  unformatPhoneNumber,
  formatPhoneNumber,
} from '../../../api/helpers/formatterHelper';
import './my-profile.css';

class PhoneAuth extends React.Component {
  constructor(props) {
    super(props);

    this.phoneNumberField = React.createRef();
    this.codeField = React.createRef();

    const { id, returnPath } = this.props.location?.state || {};

    this.state = {
      id: id || '',
      user: null,
      phone: '',
      countryCode: '1',
      code: '',
      phoneNumberIsValid: false,
      requestingConfirmationCode: false,
      confirmationCodeSent: false,
      returnPath: returnPath || '/',
    };
  }

  componentDidMount() {
    this.phoneNumberField.current.focus();

    this.setupRecaptchaVerifier();
  }

  setupRecaptchaVerifier() {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('confirm-button', {
      size: 'invisible',
      callback: (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        this.sendConfirmationCode();
      },
    });
  }

  render() {
    const { history, embedded } = this.props;
    return (
      <div>
        {!embedded && (
          <AppBar position="static">
            <Toolbar>
              <section>
                <div style={styles.appBarMenuItem}>
                  <Button color="primary" onClick={() => history.goBack()}>
                    Cancel
                  </Button>
                </div>
              </section>
              <section>
                <Typography variant="h1">
                  {this.state.name || 'Phone Number Verification'}
                </Typography>
              </section>
              <section></section>
            </Toolbar>
          </AppBar>
        )}
        <div style={{ height: 5 }} />
        {this.renderNumbers()}
        {this.renderCodeEntry()}
      </div>
    );
  }

  renderNumbers() {
    if (!this.state.confirmationCodeSent) {
      return (
        <div>
          <Paper
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              margin: 20,
              padding: 10,
            }}
          >
            <div style={{ width: '100%', marginBottom: 10 }}>
              <TextField
                name={'countryCode'}
                style={{ width: 100, marginLeft: 10 }}
                type="text"
                value={this.state.countryCode}
                label="Country Code"
                disabled={this.state.confirmationCodeSent}
                onChange={this.handleChange.bind(this)}
              />

              <TextField
                autoFocus={true}
                name={'phone'}
                ref={this.phoneNumberField}
                style={{ width: 200, marginLeft: 10 }}
                type="text"
                value={this.state.phone}
                label="Phone Number"
                disabled={this.state.confirmationCodeSent}
                onChange={this.handleChange.bind(this)}
              />
            </div>

            <div style={{ height: 10 }} />

            <Button
              id="confirm-button"
              style={{ marginBottom: 10 }}
              color="primary"
              variant="contained"
              disabled={!this.state.phoneNumberIsValid || this.state.requestingConfirmationCode}
              onClick={() => this.sendConfirmationCode()}
            >
              Get Confirmation Code
            </Button>
          </Paper>
          <div style={{ height: 10 }} />
        </div>
      );
    } else {
      return null;
    }
  }

  renderCodeEntry() {
    if (this.state.confirmationCodeSent) {
      return (
        <div>
          <Paper
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              margin: 20,
              padding: 10,
            }}
          >
            <div style={{ width: '100%', marginBottom: 10 }}>
              <span>
                {'Please enter the code we just sent to +' +
                  this.state.countryCode +
                  ' ' +
                  this.state.phone}
              </span>
            </div>
            <div style={{ width: '100%', marginBottom: 10 }}>
              <TextField
                name={'code'}
                ref={this.codeField}
                style={{ width: 200, marginLeft: 10, marginBottom: 10 }}
                type="text"
                value={this.state.code}
                label="Confirmation Code"
                onChange={this.handleChange.bind(this)}
              />
            </div>

            <Button
              style={{ marginLeft: 20, marginBottom: 10 }}
              color="primary"
              variant="contained"
              onClick={() => this.confirmCode()}
            >
              Confirm
            </Button>
          </Paper>
          <div style={{ height: 10 }} />
        </div>
      );
    } else {
      return null;
    }
  }

  handleChange(event) {
    const target = event.target;

    if (target.name === 'phone') {
      const phoneNumber = unformatPhoneNumber(target.value);
      const phoneNumberIsValid = validatePhoneNumber(phoneNumber);
      this.setState({ phoneNumberIsValid: phoneNumberIsValid });
      if (phoneNumberIsValid) {
        target.value = formatPhoneNumber(phoneNumber);
      }
    }

    this.setState({
      [target.name]: target.value,
    });
  }

  sendConfirmationCode() {
    const { phone, countryCode } = this.state;

    toast.info(`Sending code to +${countryCode} ${phone}...`);
    this.setState({ requestingConfirmationCode: true });
    firebaseSecondary
      .auth()
      .signInWithPhoneNumber('+' + countryCode + ' ' + phone, window.recaptchaVerifier)
      .then((confirmResult) => {
        this.setState(
          {
            requestingConfirmationCode: false,
            confirmResult: confirmResult,
            confirmationCodeSent: true,
            showCaptcha: false,
          },
          () => this.codeField.current.focus(),
        );
      })
      .catch((error) => {
        toast.error("Sorry that didn't work! Please try again.\n\nError: " + error.message);

        window.recaptchaVerifier.render().then((widgetId) => {
          grecaptcha.reset(widgetId);
        });
      });
  }

  confirmCode() {
    const { code, confirmResult } = this.state;

    if (confirmResult && code.length) {
      confirmResult
        .confirm(code)
        .then((user) => {
          this.savePhoneNumber();
        })
        .catch((error) =>
          toast.error(`Sorry that didn't work. Code confirm error: ${error.message}`),
        );
    }
  }

  savePhoneNumber() {
    const { history, onChange } = this.props;
    const { countryCode, phone, returnPath } = this.state;

    if (onChange) {
      onChange(countryCode, phone);
    } else {
      const location = {
        pathname: returnPath,
        state: {
          updatePhone: {
            phone,
            countryCode,
          },
        },
      };
      toast.success('Congratulations! Your phone number was successfully updated.');
      history.push(location);
    }
  }
}

const styles = {
  appBarMenuItem: {
    paddingTop: 5,
    display: 'flex',
    width: '88px',
    height: '37px',
  },
};

export default PhoneAuth;
